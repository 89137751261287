import { Outlet, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamViewTabs from "./TeamViewTabs";
import TeamOverview from "./TeamOverview";
import MembersDetails from "./MembersDetails";

function TeamDetails() {
  const { selectedTeam } = useOutletContext();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, [selectedTeam]);

  return (
    <div className="w-full relative overflow-hidden">
      <Outlet context={{ selectedTeam }} />
      <div className="mx-3 mt-2 w-[calc(100%_-_21.2rem)] fixed">
        <TeamViewTabs
          tabs={["Overview", "Members"]}
          activeTab={activeTab}
          onSwitch={(index) => setActiveTab(index)}
        />
      </div>
      {activeTab == 0 ? (
        <TeamOverview selectedTeam={selectedTeam} />
      ) : (
        <MembersDetails selectedTeam={selectedTeam} />
      )}
    </div>
  );
}

export default TeamDetails;
