import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { hexToRgb } from "utils";

Chart.register(...registerables);

const emptyDoughnutPlugin = {
  id: "emptyDoughnut",
  afterDraw(chart, args, options) {
    const { datasets } = chart.data;
    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      hasData |= dataset.data.length > 0;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = options.width || 2;
      ctx.strokeStyle = options.color || "rgba(255, 128, 0, 0.5)";
      ctx.arc(
        centerX,
        centerY,
        r - (options.radiusDecrease || 0),
        0,
        2 * Math.PI,
      );
      ctx.stroke();
    }
  },
};

// Register the plugin
// Chart.register(emptyDoughnutPlugin);

const DoughnutChart = ({
  colors = [
    { color: "#AE154B", opacity: 0.9 },
    { color: "#AE154B", opacity: 0.2 },
    {
      color: "#AE154B",
      opacity: 0.5,
    },
  ],
  labels = ["Red", "Yellow", "Green"],
  values = [50, 100, 300],
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values.find((val) => val) ? values : [],
        backgroundColor: colors.map((colorObj) => {
          const { color, opacity } = colorObj;
          return hexToRgb(color, opacity);
        }),
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 1,
    borderWidth: 0,
    maintainAspectRatio: false,
    cutout: 50,
    plugins: {
      legend: {
        display: false,
      },
      // emptyDoughnut: {
      //   color: "rgba(201, 131, 138, 1)",
      //   width: 2,
      //   radiusDecrease: 20,
      // },
    },
    scales: {
      y: {
        display: false,
      },

      x: {
        display: false,
      },
    },
  };

  return (
    <div className="h-full w-full">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
