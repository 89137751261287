import {
  fetchMemberActivities,
  fetchMemberActivitiesForProduct,
  fetchProducts,
  fetchSalesTeam,
} from "api";
import { useQuery } from "react-query";

export const useTeamsQuery = (options) =>
  useQuery("teams", () => fetchSalesTeam(), {
    ...options,
    select: (data) => data.data,
  });

export const useProductsDataQuery = (teamId, options) =>
  useQuery(["products-data", teamId], () => fetchProducts(), {
    ...options,
    select: (data) => data.data,
  });

export const useMemberActivitiesQuery = (actorId, options) =>
  useQuery(
    ["member-activities", actorId],
    () => fetchMemberActivities(actorId),
    {
      enabled: !!actorId,
      ...options,
      select: (data) => data.data,
    },
  );

export const useMemberActivitiesForProductQuery = (
  actorId,
  productId,
  options,
) =>
  useQuery(
    ["member-activities", actorId, productId],
    () => fetchMemberActivitiesForProduct(actorId, productId),
    {
      enabled: !!(actorId && productId),
      ...options,
      select: (data) => data.data,
    },
  );
