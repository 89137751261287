import Form from "components/Form";
import Input from "components/formControl/input";
import Textarea from "components/formControl/textarea";
import Modal from "components/Modal";
import useRouteModal from "hooks/useRouteModal";
import {
  useAddRole,
  useRole,
  useUpdateRole,
} from "pages/Settings/Roles/roleQueries";
import { useParams } from "react-router-dom";

export default function AddRole() {
  const { roleId } = useParams();
  const { open, closeModal } = useRouteModal();

  const { mutate: addRoleMutation } = useAddRole({
    onSuccess: closeModal,
  });

  const { mutate: updateRoleMutation } = useUpdateRole({
    onSuccess: closeModal,
  });

  const { data: role } = useRole(roleId);

  async function handleSubmit(formValues) {
    if (formValues.name && formValues.description) {
      if (!roleId) addRoleMutation(formValues);
      updateRoleMutation({ ...formValues, roleId });
    }
  }

  return (
    <Modal
      size={"lg"}
      open={open}
      onClose={closeModal}
      title={`${roleId ? "Edit" : "Add"} Role`}
    >
      <Form
        withWrapper={false}
        buttonText={roleId ? "Save" : "Add"}
        onSubmit={handleSubmit}
      >
        <div className="space-y-6 mt-5 mb-7">
          <Input
            label="Name"
            name="name"
            placeholder={"Role Name"}
            defaultValue={role?.name}
          />
          <Textarea
            label="Description"
            name="description"
            placeholder={"Role description.."}
            defaultValue={role?.description}
          />
        </div>
      </Form>
    </Modal>
  );
}
