import { useState } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useProductDealsQuery } from "pages/Products/productQueries";
import useExportJSON2Excel from "hooks/useExportJSONAsXLSX";
import { useSearchParams } from "react-router-dom";
import {
  useProduct,
  useProductDealsInPhases,
} from "pages/Products/ProductDetails/Deals/dealQueries";

export function formatTime(milliseconds) {
  if (!milliseconds) return "";

  const totalSeconds = Math.round(milliseconds / 1000);

  const days = Math.floor(totalSeconds / 86400);
  const remainingSeconds = totalSeconds % 86400;

  const hoursFormatted = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const secondsFormatted = remainingSeconds % 60;

  const formattedDays = days.toString().padStart(2, "0");
  const formattedHours = hoursFormatted.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = secondsFormatted.toString().padStart(2, "0");

  return `${formattedDays} : ${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
}

const getTotal = (rows) => {
  const totalTime = rows.reduce((total, row) => total + +row.time, 0);
  const totalAvarage = rows.reduce((total, row) => total + +row.avarage, 0);
  const totalBest = rows.reduce((total, row) => total + +row.best, 0);
  const totalWorst = rows.reduce((total, row) => total + +row.worst, 0);
  return {
    time: totalTime,
    avarage: totalAvarage,
    best: totalBest,
    worst: totalWorst,
  };
};

const createRows = (data, phases) => {
  const stages = phases?.map((phase) => {
    return {
      id: phase.id,
      name: phase?.name,
      time: parseInt(phase?.time) * 24 * 60 * 60 * 1000,
    };
  });
  const stageRows = stages?.map((stage) => {
    let stageTats = [];
    for (const deal of data) {
      const tatData = deal.tats?.find((tat) => {
        return stage.id === tat.phaseId && tat.entered;
      });
      if (tatData) {
        const tat = tatData.left
          ? new Date(tatData.left) - new Date(tatData.entered)
          : new Date() - new Date(tatData.entered);
        stageTats.push(tat);
      }
    }
    if (!stageTats.length) return null;
    const avarage =
      stageTats.reduce((total, tat) => total + tat, 0) / stageTats.length;
    const sortedStageTats = stageTats.sort((a, b) => a - b);
    const best = sortedStageTats[0];
    const worst = sortedStageTats[sortedStageTats.length - 1];
    return { ...stage, avarage, best, worst };
  });
  return stageRows;
};

function formatDateStr(dateStr) {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).\d{3}Z$/;
  const matchResult = dateStr.match(regex);
  const [, year, month, day, hour, minute, second] = matchResult;
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

const TatReport = () => {
  const exportJSON2Excel = useExportJSON2Excel();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(null);

  const [searchParams] = useSearchParams();

  const paramsObject = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const { data: product } = useProduct(paramsObject.productId);

  const { data: phasesData } = useProductDealsInPhases(paramsObject);

  const phases = phasesData
    ?.filter((phase) => phase.name !== "Won" && phase.name !== "Lost")
    .sort((a, b) => a.order - b.order);

  const { data: productDeals = [] } = useProductDealsQuery(
    paramsObject.productId,
    {
      onSuccess: (data) => {
        setRows([]);
        setTotal(null);
        if (!data.length) return;
        const rowsData = createRows(data, phases);
        const filtredRowsData = rowsData?.filter((row) => !!row);
        if (filtredRowsData?.length) {
          setRows(filtredRowsData);
          setTotal(getTotal(filtredRowsData));
        }
      },
    },
  );

  const getDataForExporting = () => {
    return productDeals.map((deal, index) => {
      const stages = phases.map((phase) => {
        return { id: phase.id, name: phase.name };
      });
      const status = deal.status == 2 ? "Open" : "Closed";
      const phaseApprovalDates = {};
      const phaseTATs = {};
      let totalTAT = 0;
      for (const phase of stages) {
        const phaseName = phase.name;
        const phaseNameWithTAT = phaseName + " (TAT)";
        phaseApprovalDates[phaseName] = "N/A";
        phaseTATs[phaseNameWithTAT] = "N/A";
        for (const tatObj of deal.tats) {
          if (tatObj.phaseId == phase.id) {
            if (tatObj.left) {
              const TAT = new Date(tatObj.left) - new Date(tatObj.entered);
              totalTAT += TAT;
              phaseTATs[phaseNameWithTAT] = formatTime(TAT);
              phaseApprovalDates[phaseName] = formatDateStr(tatObj.left);
            } else {
              const TAT = new Date() - new Date(tatObj.entered);
              totalTAT += TAT;
              phaseTATs[phaseNameWithTAT] = formatTime(TAT);
            }
          }
        }
      }
      const dealStartDateFormatted = formatDateStr(deal.tats[0].entered);
      const employeeFullName =
        deal.assignee.firstName + " " + deal.assignee.lastName;
      return {
        SN: index + 1,
        "Deal Name": deal.name,
        "Deal Date": dealStartDateFormatted,
        "Employee Name": employeeFullName,
        Status: status,
        ...phaseApprovalDates,
        ...phaseTATs,
        TAT: formatTime(totalTAT),
      };
    });
  };

  const handleClick = () => {
    const data = getDataForExporting();
    const timeStr = formatDateStr(new Date().toISOString());
    const phaseNames = phases?.map((phase) => phase.name);
    const phaseTATs = phaseNames?.map((phaseName) => phaseName + " (TAT)");
    return exportJSON2Excel({
      data,
      dataTitle: "TAT REPORT",
      sheetName: product?.name,
      fileName: product?.name + " - TAT Report - " + timeStr,
      headers: [
        [
          "SN",
          "Deal Name",
          "Deal Date",
          "Employee Name",
          "Status",
          ...phaseNames,
          ...phaseTATs,
          "TAT",
        ],
      ],
    });
  };

  return (
    <div className="bg-white py-4 p-4 rounded-xl mb-8">
      <div className="flex items-center justify-between pb-3 border-b-[1px]">
        <div className="">
          <h1 className="text-xs text-gray-700 uppercase font-semibold pb-1">
            tat summary
          </h1>
          <p className={"text-[0.825rem] text-gray-500"}>Deals TAT Summary</p>
        </div>
        <button
          onClick={handleClick}
          className="bg-primary text-sm flex items-center rounded-lg px-3 py-1.5 hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-primary/50 focus:outline-none active:outline-none"
        >
          <IoCloudDownloadOutline
            className={"h-[1.1rem] w-[1.1rem] text-white"}
          />
          <span className={"px-1 text-sm text-white"}>Full Report</span>
        </button>
      </div>
      <table className="table-auto w-full text-left divide-y-[16px] divide-transparent opacity-80">
        <tr className="">
          <td className="text-start">
            <p className="uppercase text-[0.75rem] text-gray-600 font-medium pl-2 py-2">
              Stage
            </p>
          </td>
          <th className="text-center">
            <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
              Expected (dd:hh:mm:ss)
            </p>
          </th>
          <th className="text-center">
            <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
              Average (dd:hh:mm:ss)
            </p>
          </th>
          <th className="text-center">
            <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
              Best (dd:hh:mm:ss)
            </p>
          </th>
          <th className="text-center">
            <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
              Worst (dd:hh:mm:ss)
            </p>
          </th>
        </tr>
        <tbody className="divide-y-[20px] divide-transparent">
          {rows?.map((row) => (
            <tr key={row.id} className="odd:bg-slate-50 hover:bg-primary-50">
              <td className="text-start">
                <p className="text-sm pl-2 py-2 capitalize">{row.name}</p>
              </td>
              <td className="text-center">
                <p className="text-sm">{formatTime(row.time)}</p>
              </td>
              <td className="text-center">
                <p className="text-sm">{formatTime(row.avarage)}</p>
              </td>
              <td className="text-center">
                <p className="text-sm">{formatTime(row.best)}</p>
              </td>
              <td className="text-center">
                <p className="text-sm">{formatTime(row.worst)}</p>
              </td>
            </tr>
          ))}
          {total ? (
            <tr key={"total"} className="odd:bg-slate-100 hover:bg-primary-50">
              <td className="text-start">
                <p className="text-[0.825rem] font-medium uppercase pl-2 py-4">
                  total
                </p>
              </td>
              <td className="text-center">
                <p className="text-sm font-medium">{formatTime(total?.time)}</p>
              </td>
              <td className="text-center">
                <p className="text-sm font-medium">
                  {formatTime(total?.avarage)}
                </p>
              </td>
              <td className="text-center">
                <p className="text-sm font-medium">{formatTime(total?.best)}</p>
              </td>
              <td className="text-center">
                <p className="text-sm font-medium">
                  {formatTime(total?.worst)}
                </p>
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TatReport;
