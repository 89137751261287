import { Outlet, useParams } from "react-router-dom";

import useRouteModal from "hooks/useRouteModal";
import SlideOver from "components/SlideOver";
import SlideOverHeader from "components/SlideOverHeader";
import { useRole } from "pages/Settings/Roles/roleQueries";
import { useState } from "react";
import { groupByKeyValue } from "utils";
import { ButtonLink } from "components/form-control";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const RoleDetails = () => {
  const { roleId } = useParams();
  const { open: showSlideOver, closeModal: hideSlideOver } = useRouteModal();

  const [groupedPermissions, setGroupedPermissions] = useState({});

  const { data: role, isLoading } = useRole(roleId, {
    onSuccess: (role) => {
      setGroupedPermissions(groupByKeyValue("moduleName", role?.permit));
    },
  });

  return (
    <SlideOver open={showSlideOver} onClose={hideSlideOver}>
      <Outlet />
      <div className={"w-[30rem] bg-white h-full overflow-y-auto"}>
        <SlideOverHeader
          title={"Role Details"}
          onClose={hideSlideOver}
          className={"border-b-2"}
        />

        <div className={"p-5 pb-14 space-y-10"}>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <div className={"space-y-2 pb-2"}>
                <div className={"items-between w-full"}>
                  <div className={"flex items-center space-x-2"}>
                    <h1
                      className={
                        "text-lg text-gray-800  capitalize font-semibold"
                      }
                    >
                      {role?.name ?? "N/A"}
                    </h1>
                  </div>
                </div>

                <p className={"text-sm text-gray-600 leading-relaxed "}>
                  {role?.description ?? "N/A"}
                </p>
              </div>

              <div className={"flex justify-between items-end"}>
                <p className={"font-semibold text-gray-700 text-sm"}>
                  Permissions
                </p>
                <ButtonLink
                  to={"permissions"}
                  className={
                    "flex center text bg-primary-200/50 text-primary border border-primary-300 h-8 w-8 p-0  space-x-1.5"
                  }
                >
                  <PencilSquareIcon className={"h-5 w-5"} />
                </ButtonLink>
              </div>

              <div className={"divide-y  divide-gray-200"}>
                {Object.keys(groupedPermissions).map((groupName, index) => (
                  <PermissionsGroup
                    groupName={groupName}
                    permissions={groupedPermissions[groupName]}
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </SlideOver>
  );
};

const PermissionsGroup = ({ groupName, permissions }) => {
  return (
    <div className={"space-y-1.5 py-4"}>
      <h4 className={"text-xs text-gray-400 font-inter capitalize"}>
        {groupName}
      </h4>
      <div className={"text-sm space-y-1.5"}>
        {permissions?.map((permission, index) => (
          <div className={"items-between text-sm"} key={index}>
            <p className={"capitalize"}>{permission?.displayName}</p>
            <p className={"text-gray-600 capitalize py-1"}>
              {permission?.genericName}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoleDetails;
