import React from "react";
import { shortenLargeNumber } from "utils";

function MemberCard({
  member,
  dealsClosedCount = 37,
  dealsClosedValue = "389,5000",
}) {
  const { firstName, lastName } = member;
  return (
    <div className="flex flex-col justify-center items-center bg-white p-4 shadow-md rounded-lg space-y-2">
      <div className="rounded-full w-[100px] h-[100px] border relative bg-primary/20">
        <span className="absolute center h-full w-full text-2xl font-bold">
          {/* {member.name.split(' ').map(n => n[0]).join('')}*/}
          {firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()}
        </span>
      </div>
      <div className="flex space-x-2">
        <span className="font-medium text-gray-800">{firstName}</span>
        <span className="font-medium text-gray-800">{lastName}</span>
      </div>
      <div className="flex space-x-2">
        <span className="text-sm text-gray-500 uppercase">Deals:</span>
        <span className="text-sm text-gray-800">{dealsClosedCount ?? 0}</span>
      </div>
      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-500 uppercase">Deals Value:</span>
        <span className="text-sm text-gray-800">
          TZS {dealsClosedValue ? shortenLargeNumber(dealsClosedValue) : "0"}
        </span>
      </div>
    </div>
  );
}

export default MemberCard;
