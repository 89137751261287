import { shortenLargeNumber } from "utils";
import Badge from "components/badge/badge";
import { DealCard } from "pages/Products/ProductDetails/Deals/DealCard";

export default function Phase({ phase }) {
  const totalAmount = phase.deals.reduce((sum, deal) => sum + +deal.value, 0);

  return (
    <div className="flex-shrink-0 w-[300px] bg-gray-200 rounded-md h-[95%] shadow-sm flex flex-col relative overflow-hidden">
      <div className="flex flex-col bg-gray-300">
        <div className="flex items-center justify-between px-5 pt-3 pb-1">
          <div className="w-full text-left">
            <h5 className="text-[0.825rem] font-semibold">{phase.name}</h5>
          </div>
          {phase.time && (
            <Badge
              text={`${phase.time} days`}
              type="error"
              className={"flex flex-row ring-0 bg-primary/10 text-primary-900"}
            />
          )}
        </div>
        <div className="flex items-center space-x-1 text-gray-500 text-xs px-5 pb-2">
          <span className="">{`TZS ${shortenLargeNumber(totalAmount)}`}</span>

          {phase.deals.length ? (
            <>
              <span className="bg-gray-500 rounded-full p-[0.1rem]"></span>
              <span className="text-gray-500">
                {phase.deals.length > 1
                  ? `${phase.deals.length} deals`
                  : "1 deal"}
              </span>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={"h-full pt-2 px-4 overflow-y-auto"}>
        {phase.deals.length > 0 ? (
          phase.deals.map((deal) => (
            <DealCard key={deal.id} deal={deal} phaseTime={phase.time} />
          ))
        ) : (
          <div className={"flex flex-col h-full"}>
            <p className={"text-sm text-gray-500"}>No deals!</p>
          </div>
        )}
      </div>
    </div>
  );
}
