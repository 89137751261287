import { shortenLargeNumber } from "utils";

const PerformanceCardBar = ({ label, value, target }) => {
  const percentage = +target ? ((+value * 100) / +target).toFixed() : 0;
  const width = `${percentage < 100 ? percentage : 100}%`;
  return (
    <div className="flex-column space-y-2">
      <div className="flex items-center text-[0.8rem]">
        <span className="uppercase text-gray-500">{`${label}:`}</span>
        <span className=" text-gray-700 px-2">{`${shortenLargeNumber(
          +value,
        )} / ${shortenLargeNumber(+target)}`}</span>
      </div>
      <div className="h-8 bg-primary/10 rounded-md">
        <div className="">
          <div
            style={{ width: width }}
            className={`h-8 bg-primary/60 rounded-md flex items-center ${
              percentage < 26 ? "justify-start" : "justify-end"
            }`}
          >
            <span className="px-2 text-sm text-gray-700">
              {`${percentage}%`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCardBar;
