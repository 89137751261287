import DoughnutChart from "./DoughnutChart";
import { hexToRgb, shortenLargeNumber } from "../../../../utils";

const DealsSummaryCard = ({ summary }) => {
  const colors = [
    { color: "#AE154B", opacity: 0.9 },
    { color: "#AE154B", opacity: 0.4 },
    {
      color: "#AE154B",
      opacity: 0.6,
    },
  ];
  const labels = ["Won", "Lost", "Open"];

  summary?.sort((a, b) => a.order - b.order);

  return (
    <div className={"flex space-x-4 w-full overflow-hidden"}>
      <SummaryCard
        // key{"volume-summary"}
        title={"volume"}
        colors={colors}
        labels={labels}
        values={summary?.map((obj) => obj.count)}
      />
      <SummaryCard
        // key{"value-summary"}
        title={"value (tzs)"}
        colors={colors}
        labels={labels}
        values={summary?.map((obj) => obj.value)}
      />
    </div>
  );
};

const SummaryCard = ({ title, colors, labels, values }) => {
  const total = values?.reduce((total, value) => total + +value, 0);

  const rgbColors = colors.map((colorObj) => {
    const { color, opacity } = colorObj;
    return hexToRgb(color, opacity);
  });

  return (
    <div className={"flex flex-col bg-white h-full w-full p-6 rounded-xl"}>
      <h1 className={"text-xs mb-2 font-semibold uppercase"}>{title}</h1>
      <div className={"flex"}>
        <div className="relative">
          {values ? (
            <DoughnutChart colors={colors} labels={labels} values={values} />
          ) : (
            <div className={"flex justify-center w-[18.72rem]"}>
              <div className="h-[9.34rem] w-[9.34rem] border-4 border-primary rounded-full"></div>
            </div>
          )}
          <div className="flex flex-col items-center absolute top-[3.5rem] left-[8rem]">
            <span className="text-sm uppercase font-medium">total</span>
            <span className="text-sm uppercase font-medium">
              {shortenLargeNumber(total)}
            </span>
          </div>
        </div>
        <div className={"flex flex-col space-y-4"}>
          {labels.map((label, index) => {
            return (
              <div className={"flex items-start space-x-2"}>
                <span
                  style={{
                    backgroundColor: rgbColors[index],
                  }}
                  className={"rounded-full mt-[0.25rem] px-[0.25rem] h-2"}
                ></span>
                <div className="flex flex-col justify-end">
                  <span className="text-xs">{label}</span>
                  <span className="text-xs font-medium border-b-2 border-gray-300 text-end">
                    {values && shortenLargeNumber(values[index])}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DealsSummaryCard;
