import ProductCard from "pages/Products/ProductCard";
import ProductCardLoader from "pages/Products/ProductCardLoader";
import { useProductsSummaries } from "pages/Products/productQueries";

export default function Products() {
  const { data: products, isLoading } = useProductsSummaries();

  return (
    <>
      <div className="bg-white sticky z-10 pt-4 pb-6 px-6 top-0 border-b">
        <h3 className="text-xl font-medium px-2">Products</h3>
      </div>
      <div className="overflow-x-hidden p-4">
        <div className="grid grid-cols-5 gap-6 p-2 overflow-auto">
          {isLoading ? (
            <>
              {[...Array(12)].map((_, index) => (
                <ProductCardLoader key={index} />
              ))}
            </>
          ) : (
            products?.map((product) => {
              return <ProductCard key={product.id} product={product} />;
            })
          )}
        </div>
      </div>
    </>
  );
}
