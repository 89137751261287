import { get, post, put, remove } from "api/request";

export async function getUsers() {
  return await get("/users");
}

export async function getUser(userId) {
  return await get(`/users/${userId}`);
}

export async function addUser(userDetails) {
  console.log("User Details: ", userDetails);
  return await post("/users/invite", userDetails);
}

export async function updateUser({ userId, ...rest }) {
  return await put(`/users/${userId}`, rest);
}

export async function removeUser(userId) {
  return await remove(`/users/${userId}`);
}
