function ListMenuItem({ item, selected, onClick }) {
  return (
    <button
      className={`hover:bg-primary/5 ${
        selected ? "bg-primary/20 pointer-events-none" : ""
      } w-full flex items-center py-1 px-3 m-2 rounded-lg`}
      onClick={() => onClick()}
    >
      <div className="flex-1 text-left">
        <h3 className="capitalize leading-relaxed text-sm">
          {item.name.toLowerCase()}
        </h3>
      </div>
    </button>
  );
}

export default ListMenuItem;
