import { useSearchParams } from "react-router-dom";

import DealsSummaryCard from "./DealsSummaryCard";
import PerformanceCard from "./PerformanceCard";
import HighlightsCard from "./HighlightsCard";
import ActivityCard from "./ActivityCard";
import RecentDeals from "./RecentDeals";
import Copyright from "components/Copyright";
import TeamsPerformance from "pages/Products/ProductDetails/Analytics/TeamsPerformance";
import TatReport from "pages/Products/ProductDetails/Analytics/TatReport";
import { useProductStats } from "pages/Products/ProductDetails/Deals/dealQueries";

const Analytics = () => {
  const [searchParams] = useSearchParams();

  const paramsObject = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const { data: stats, isLoading } = useProductStats(paramsObject);

  return (
    <div className="bg-gray-100 h-[calc(100%_-_5rem)] w-full py-4 pl-4 pr-6 overflow-auto focus:outline-none">
      <div className={"grid grid-cols-3 gap-4 "}>
        <div className="col-span-2">
          {stats && <DealsSummaryCard summary={stats?.summary} />}
        </div>
        <div className="col-span-1">
          {stats && (
            <PerformanceCard
              deals={stats?.wonDeals}
              productId={paramsObject?.productId}
            />
          )}
        </div>
      </div>
      <div className="mt-4">
        {stats && <HighlightsCard highlights={stats?.highlights} />}
      </div>
      <div className={"grid grid-cols-3 gap-4 mt-4"}>
        <div className="col-span-2 flex flex-col space-y-4">
          {stats && (
            <ActivityCard monthlyDealsCounts={stats?.monthlyDealsCounts} />
          )}
          <TatReport />
        </div>
        <div className="col-span-1 flex flex-col space-y-4">
          {stats && <RecentDeals deals={stats?.recentDeals} />}
          {stats && (
            <TeamsPerformance
              deals={stats?.allDeals}
              productId={paramsObject?.productId}
            />
          )}
        </div>
      </div>
      <div className="pt-12">
        <Copyright />
      </div>
    </div>
  );
};

export default Analytics;
