const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <span className="flex justify-center p-4 text-sm text-[#6C6B84]">
      &copy; {year}
      <a
        target="_blank"
        href={"https://ipfsoftwares.com/"}
        className={"pl-2 text-blue-500"}
      >
        iPF Softwares
      </a>
      {/*. All Rights Reserved.*/}
    </span>
  );
};

export default Copyright;
