import { Link, Outlet } from "react-router-dom";
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Button } from "components/form-control";
import { useRemoveRole, useRoles } from "pages/Settings/Roles/roleQueries";

const tableColumnTitles = [
  "Role Name",
  "Permissions",
  "Users",
  "Created",
  "Last Update",
  "Actions",
  "",
];

const Roles = () => {
  const { data: roles } = useRoles();

  const { mutate: removeRoleMutation } = useRemoveRole();

  const handleRemoveRole = (roleId) => removeRoleMutation(roleId);

  return (
    <div className="px-6 py-4">
      <Outlet />

      <div className="flex items-center justify-end mb-1">
        <Link to="add-role">
          <Button
            type={"button"}
            className={
              "flex items-center space-x-1 border-[1px] border-primary text-primary py-[0.36rem]"
            }
            outline
          >
            <PlusIcon className={"h-4 w-4"} strokeWidth={2} />
            <span className="text-sm capitalize">add role</span>
          </Button>
        </Link>
      </div>

      <div className="py-2 align-middle inline-block min-w-full">
        <div className="bg-white shadow border-b border-gray-200 rounded-md overflow-hidden">
          <table className="min-w-full divide-y divide-primary/5">
            <thead className="text-gray-600">
              <tr>
                {tableColumnTitles.map((heading, index) => (
                  <th
                    scope="col"
                    className={`text-gray-500 text-xs ${index ? "text-center" : "text-left"} font-medium uppercase tracking-wider px-6 py-3 border-b-[1px] border-gray-300`}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {roles
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((role, index) => (
                  <tr key={index} className="bg-white relative odd:bg-gray-50">
                    <td className="text-[0.84rem] text-gray-800 px-6 py-4 whitespace-nowrap capitalize">
                      {role?.name ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {role?.permissionCount ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {role?.userCount ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {new Date(role.createdAt).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {new Date(role.updatedAt).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </td>
                    <td className="flex center space-x-4 px-6 py-4 whitespace-nowrap text-[0.835rem]">
                      <Link to={`edit-role/${role.id}`}>
                        <button className="bg-gray-200/60 text-primary-400 flex items-center space-x-0.5 py-1 px-1.5 hover:bg-primary-50 rounded">
                          <PencilSquareIcon className={"h-4 w-4"} />
                        </button>
                      </Link>
                      <button
                        onClick={() => handleRemoveRole(role.id)}
                        className="hidden bg-gray-200/60 text-primary flex items-center space-x-0.5 py-1 px-1.5 hover:bg-primary-50 rounded"
                      >
                        <TrashIcon className={"h-4 w-4"} />
                      </button>
                    </td>
                    <td className="space-x-2 px-6 py-4 whitespace-nowrap text-[0.835rem] text-primary">
                      <Link to={`${role.id}`}>
                        <button className="bg-gray-200/60 flex items-center space-x-0.5 py-1 px-1.5 rounded capitalize">
                          <span className="px-2 underline">view details</span>
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Roles;
