import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
// import TimePicker from 'react-time-picker-input';
import Form from "../../components/Form";
import Input, { DatePickerField } from "../../components/formControl/input";
import Label from "../../components/formControl/label";
import Select from "../../components/formControl/Select";
import Modal from "../../components/Modal";
import { createContact, fetchCompanies, updateContact } from "../../api";

function SetAppointment({ contact = {}, onClose, onSave = () => {} }) {
  const navigate = useNavigate();
  const companyQuery = useQuery("companies", fetchCompanies, {
    refetchOnWindowFocus: false,
  });
  const [values, setValues] = useState({
    phoneType: "Mobile",
    type: "Lead",
    ...contact,
  });
  const [companyChoices, setCompanyChoices] = useState([]);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [time, setTime] = useState("10:00");

  useEffect(() => {
    // saveValueToLocalStorage("companies", []);
    // saveValueToLocalStorage("contacts", []);
    // saveValueToLocalStorage("pipelines", []);
  }, []);

  useEffect(() => {
    if (companyQuery.data) setCompanyChoices(companyQuery.data);
  }, [companyQuery.data]);

  const createMutation = useMutation(createContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  const updateMutation = useMutation(updateContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  async function handleSubmit(data) {
    console.log(data);
    // try {
    //     if(!values._id) createMutation.mutate(data);
    //     else
    //         updateMutation.mutate(data);
    // } catch (error) {
    //     console.log("New contact error: ", error);
    // }
  }

  function handleClose() {
    if (onClose) onClose();
    else {
      navigate("/contacts", {
        replace: true,
      });
    }
  }

  return (
    <Modal title="Set Appointment" size="xl" open={true} onClose={handleClose}>
      <Form
        data={values}
        withWrapper={false}
        onSubmit={handleSubmit}
        // loading={createMutation.isLoading || updateMutation.isLoading}
      >
        {(formikProps) => (
          <div className="space-y-6 mt-5 mb-7">
            <div className="flex flex-col space-y-4">
              <Select
                label="Deal"
                name="deal"
                choices={[
                  "Configuration of new server",
                  "Some good deal",
                  "Software Update",
                ]}
              />
              <Select
                label="Appointment Type"
                name="type"
                choices={["Meeting", "Demo", "Pitch", "Chat"]}
              />
              <Input label="Cotact" name="contact" value={contact.name} />
              <Input label="Agenda" name="agenda" placeholder="" required />
              <Input label="Location" name="location" placeholder="" required />
              <div className="flex flex-col">
                <Label>Date</Label>
                <DatePickerField name="date" />
              </div>
              <div className="flex flex-row space-x-2 w-full">
                <div className="flex flex-col">
                  {/* <Label>Start Time</Label>
                  <TimePicker
                    name="time"
                    className="bg-[#F0F2F7] w-full rounded-md px-3 py-2 border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1 focus:ring-primary/70"
                    onChange={onTimeChange}
                    value={time}
                    clearIcon
                  /> */}
                </div>
                <div className="flex flex-col">
                  {/* <Label>End Time</Label>
                  <TimePicker
                    onChange={(newValue) => setTime(newValue)}
                    value={time}
                  /> */}
                  {/* <TimePicker
                    name="endTime"
                    className="bg-[#F0F2F7] w-full rounded-md px-3 py-2 border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1 focus:ring-primary/70"
                    onChange={onTimeChange}
                    value={time}
                    clearIcon
                  /> */}
                </div>
              </div>

              {/* <Input
                                label="Time"
                                name="time"
                                placeholder=""
                                required
                            /> */}
            </div>

            {/* <Select
                            label="Company"
                            placeholder="Choose company"
                            name="company"
                            loading={isLoading}
                            labelKey="name"
                            choices={
                                [
                                    {
                                        render(){
                                            return (
                                                <div key="New Company" className="px-3 py-2">
                                                    <Button key="Add Contact" className="w-full py-2 pl-2 pr-3 flex items-center capitalize bg-opacity-10 text-primary"
                                                        onClick={() => handleOpenAddCompany(formikProps.values)}
                                                    >
                                                        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd" d="M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6z" /></svg>
                                                        New Company
                                                    </Button>
                                                </div>
                                            );
                                        }
                                    },
                                    ...(companyChoices ? companyChoices : []),
                                ]
                            }
                        /> */}

            {/* <TextArea
                            label="Notes"
                            name="notes"
                            placeholder="E.g. Normally available for a quick chat over coffee on Saturdays."
                        /> */}
          </div>
        )}
      </Form>

      {/* { showAddCompany && <AddCompany onSave={handleCloseAddCompany} onClose={handleCloseAddCompany}  />  } */}
    </Modal>
  );
}

export default SetAppointment;
