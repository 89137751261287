import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import Input from "../../components/formControl/input";
import Select from "../../components/formControl/Select";
import TextArea from "../../components/formControl/textarea";
import Modal from "../../components/Modal";
import { createContact, fetchCompanies, updateContact } from "../../api";
import AddCompany from "./AddCompany";

function AddContact({ contact = {}, onClose, onSave = () => {} }) {
  const navigate = useNavigate();
  const companyQuery = useQuery("companies", fetchCompanies, {
    refetchOnWindowFocus: false,
  });
  const [values, setValues] = useState({
    phoneType: "Mobile",
    type: "Lead",
    ...contact,
  });
  const [companyChoices, setCompanyChoices] = useState([]);
  const [showAddCompany, setShowAddCompany] = useState(false);

  useEffect(() => {
    // saveValueToLocalStorage("companies", []);
    // saveValueToLocalStorage("contacts", []);
    // saveValueToLocalStorage("pipelines", []);
  }, []);

  useEffect(() => {
    if (companyQuery.data) setCompanyChoices(companyQuery.data);
  }, [companyQuery.data]);

  const createMutation = useMutation(createContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  const updateMutation = useMutation(updateContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  async function handleSubmit(data) {
    try {
      if (!values.id) createMutation.mutate(data);
      else updateMutation.mutate(data);
    } catch (error) {
      console.log("New contact error: ", error);
    }
  }

  function handleOpenAddCompany(newValues) {
    setValues({ ...newValues, forceUpdate: true });
    setShowAddCompany(true);
  }

  function handleCloseAddCompany(company) {
    setShowAddCompany(false);

    setTimeout(() => {
      if (company && company.name) {
        setCompanyChoices([company, ...companyChoices]);
        setValues({ ...values, company });
      }
    }, 100);

    setTimeout(() => {
      const { forceUpdate, ...actualValues } = values;
      setValues(actualValues);
    }, 150);
  }

  function handleClose() {
    if (onClose) onClose();
    else {
      navigate("/contacts", {
        replace: true,
      });
    }
  }

  return (
    <Modal
      title={values.id ? "Edit Contact" : "Add Contact"}
      size="xl"
      open={true}
      onClose={handleClose}
    >
      <Form
        data={values}
        withWrapper={false}
        onSubmit={handleSubmit}
        loading={createMutation.isLoading || updateMutation.isLoading}
      >
        {(formikProps) => (
          <div className="space-y-6 mt-5 mb-7">
            <div className="grid grid-cols-2 gap-x-4">
              <Input
                label="Full Name"
                name="name"
                placeholder="E.g. James Ivan Chivya"
                required
              />

              <Select
                label="Contact Type"
                name="type"
                choices={["Lead", "Client", "Other"]}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <Input
                label="Email"
                name="email"
                placeholder="E.g. chivya@vodacom.co.tz"
                required
              />
              <Input label="Phone Number" name="phone" />
            </div>

            {/* <Select
                            label="Company"
                            placeholder="Choose company"
                            name="company"
                            loading={isLoading}
                            labelKey="name"
                            choices={
                                [
                                    {
                                        render(){
                                            return (
                                                <div key="New Company" className="px-3 py-2">
                                                    <Button key="Add Contact" className="w-full py-2 pl-2 pr-3 flex items-center capitalize bg-opacity-10 text-primary"
                                                        onClick={() => handleOpenAddCompany(formikProps.values)}
                                                    >
                                                        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd" d="M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6z" /></svg>
                                                        New Company
                                                    </Button>
                                                </div>
                                            );
                                        }
                                    },
                                    ...(companyChoices ? companyChoices : []),
                                ]
                            }
                        /> */}

            <TextArea
              label="Notes"
              name="notes"
              placeholder="E.g. Normally available for a quick chat over coffee on Saturdays."
            />
          </div>
        )}
      </Form>

      {showAddCompany && (
        <AddCompany
          onSave={handleCloseAddCompany}
          onClose={handleCloseAddCompany}
        />
      )}
    </Modal>
  );
}

export default AddContact;
