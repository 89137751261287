import { useCallback, useEffect, useRef } from "react";

const useClickAway = (handler, escapeKey = false) => {
  if (!handler) {
    throw new Error("handler function is required.");
  }

  if (typeof handler !== "function") {
    throw new Error(
      `handler must be a function, but ${typeof handler} is given.`,
    );
  }

  const domNodeRef = useRef(null);

  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const handleClickEvent = useCallback((event) => {
    if (!domNodeRef.current) return;

    if (!domNodeRef.current.contains(event.target)) {
      handlerRef.current();
    }
  }, []);

  const handleEscapeKey = useCallback(
    (event) => {
      if (!domNodeRef.current) return;

      if (event.key === "Escape") {
        event.preventDefault();
        if (escapeKey) handlerRef.current();
      }
    },
    [escapeKey],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickEvent);
    return () => {
      document.removeEventListener("mousedown", handleClickEvent);
    };
  }, [handleClickEvent]);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleEscapeKey]);

  return domNodeRef;
};

export default useClickAway;
