import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@tailwindui/react";
import { useAuth } from "../../providers/auth";

import logo from "assets/absa-logo.png";
import Avatar from "../../components/Avatar";
import useOnClickOutside from "hooks/useOnClickOutside";
import QuickSearch from "./QuickSearch";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import NotificationsDropdown from "./NotificationsDropdown";
import useClickAway from "../../hooks/useClickAway";
import {
  useGetNotifications,
  useUpdateManyNotifications,
} from "./SearchQueries";
import Badge from "../../components/badge";

const Topbar = ({ onToggleLeftBar }) => {
  const { authUser, signOut } = useAuth();
  const ref = useRef();
  const navigate = useNavigate();

  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  useOnClickOutside(ref, () => setShowProfileDropdown(false));

  const [notifications, setNotifications] = useState();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notSeenNotifications, setNotSeenNotifications] = useState();

  const elemRef = useClickAway(async () => {
    await refetchNotifications();
    setShowNotifications(false);
  }, true);

  const { refetch: refetchNotifications } = useGetNotifications({
    onSuccess: (data) => setNotifications(data),
  });

  const { mutate: updateManyMutation } = useUpdateManyNotifications();

  useEffect(() => {
    if (notifications) {
      setNotSeenNotifications(
        notifications?.filter((notification) => notification.seen === false),
      );
    }
  }, [notifications]);

  const handleClickNotification = () => {
    if (showNotifications) {
      setShowNotifications(false);
      refetchNotifications();
      return;
    }

    setShowNotifications(true);
    const notSeenNotificationsIds = notSeenNotifications?.map(
      (notification) => notification.id,
    );
    if (notSeenNotificationsIds?.length < 1) return;
    updateManyMutation({
      notifications: notSeenNotificationsIds,
      update: { seen: true },
    });
    refetchNotifications();
  };

  const handleSignOut = () => {
    signOut(navigate("/login", { replace: true }));
  };

  return (
    <>
      <div className="flex w-full relative z-40 h-11 bg-white shadow">
        <button
          type="button"
          onClick={() => onToggleLeftBar()}
          className="px-4 my-2.5 border-r border-gray-200 text-gray-500 focus:outline-none"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className={"h-6 w-6"} />
        </button>
        <div className="flex-1 px-6 flex items-center justify-between">
          <div className="flex items-center flex-shrink-0">
            <img className="w-8 h-8 object-cover" src={logo} alt="" />
          </div>

          <div className="">
            <QuickSearch />
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            <div className="relative w-fit" ref={elemRef}>
              {showNotifications && (
                <NotificationsDropdown
                  notifications={notifications}
                  refetchNotifications={refetchNotifications}
                />
              )}
              <button
                onClick={handleClickNotification}
                className={`bg-white relative p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 ${
                  showNotifications && "bg-primary-200"
                }`}
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className={"h-6 w-6 text-primary-800"} />
                {!showNotifications && !!notSeenNotifications?.length && (
                  <div className={"absolute -top-1.5 -right-1"}>
                    <Badge
                      className={
                        "rounded-full h-5 w-5 border-2 border-white center text-primary-800 bg-primary-200 ring-0"
                      }
                      type={"warning"}
                      text={notSeenNotifications?.length}
                    />
                  </div>
                )}
              </button>
            </div>

            <div className="ml-5 flex items-center relative">
              {/*<div>*/}
              <button
                type="button"
                className="max-w-xs bg-white flex items-center text-sm focus:outline-none"
                id="user-menu"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
              >
                <span className="sr-only">Open user menu</span>
                <div className="mr-1 flex items-end flex-col space-y-0.5">
                  <span className="font-medium leading-none text-xs">
                    {authUser.firstName} {authUser.lastName}
                  </span>
                  <span className="leading-none text-xs text-primary-700">
                    {authUser.role.name}
                  </span>
                </div>
                <Avatar size="lg" src={authUser.image} />
              </button>
              {/*</div>*/}

              <Transition
                enter="transition ease-out duration-100"
                enter-from="transform opacity-0 scale-95"
                enter-to="transform opacity-100 scale-100"
                leave="transition ease-out duration-100"
                leave-from="transform opacity-100 scale-100"
                leave-to="transform opacity-0 scale-95"
                show={showProfileDropdown}
              >
                <div
                  ref={ref}
                  className="origin-top-right absolute right-0 mt-[1.4rem] w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  {/*<a*/}
                  {/*  href="/"*/}
                  {/*  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"*/}
                  {/*  role="menuitem"*/}
                  {/*>*/}
                  {/*  Your Profile*/}
                  {/*</a>*/}
                  {/*<a*/}
                  {/*  href="/"*/}
                  {/*  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"*/}
                  {/*  role="menuitem"*/}
                  {/*>*/}
                  {/*  Settings*/}
                  {/*</a>*/}

                  <a
                    href="/"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    onClick={handleSignOut}
                  >
                    Log Out
                  </a>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
