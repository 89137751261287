import useRouteModal from "hooks/useRouteModal";
import {
  useAllPermissions,
  useRole,
  useUpdateRolePermissions,
} from "pages/Settings/Roles/roleQueries";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Modal from "components/Modal/Model";
import ToggleSwitch from "components/ToggleSwitch";
import { groupByKeyValue } from "utils";
import ModalHeader from "components/ModalHeader";
import { Button } from "components/form-control";

const PermissionAssignmentForm = () => {
  const { roleId } = useParams();
  const { open, closeModal } = useRouteModal();

  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  // Using RQ instead of location state or outlet context

  const { data: role, isLoading: isLoadingRole } = useRole(roleId, {
    onSuccess: (role) => {
      setSelectedPermissionIds(
        role?.permit ? role.permit.map((permission) => permission.id) : [],
      );
    },
  });

  const { isLoading: isLoadingAllPermissions } = useAllPermissions({
    onSuccess: (permissions) => {
      setGroupedPermissions(groupByKeyValue("moduleName", permissions));
    },
  });

  const {
    mutate: rolePermissionsAssignmentMutation,
    isLoading: isAssigningPermissions,
  } = useUpdateRolePermissions({
    onSuccess: closeModal,
  });

  const handleResetChanges = () => {
    setSelectedPermissionIds(
      role?.permit ? role.permit.map((permission) => permission.id) : [],
    );
  };

  const handleToggle = (selected, permissionId) => {
    if (selected) {
      setSelectedPermissionIds([...selectedPermissionIds, permissionId]);
    } else {
      setSelectedPermissionIds(
        selectedPermissionIds.filter((id) => id !== permissionId),
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    rolePermissionsAssignmentMutation({
      roleId,
      permissions: selectedPermissionIds,
    });
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <form
        onSubmit={handleSubmit}
        className={
          "w-[60rem] h-[37rem] rounded-xl border overflow-hidden border-gray-300 bg-white "
        }
      >
        <ModalHeader
          title={`Assign ${role?.name} Permissions`}
          onClose={closeModal}
        />

        {isLoadingRole || isLoadingAllPermissions ? (
          <p className={"center text-sm text-gray-400"}>Loading...</p>
        ) : (
          <div
            className={
              "h-[29.3rem] overflow-y-auto py-6 px-8 grid gap-10 grid-cols-3"
            }
          >
            {Object.keys(groupedPermissions ?? {}).map((groupName) => (
              <PermissionGroup
                groupName={groupName}
                permissions={groupedPermissions[groupName]}
                assignedPermissionIds={selectedPermissionIds}
                onToggle={handleToggle}
                key={groupName}
              />
            ))}
          </div>
        )}

        <div
          className={"items-between px-5 pt-3 pb-2 border-t border-gray-300"}
        >
          <Button
            type={"button"}
            onClick={closeModal}
            className={"border-primary text-primary py-[0.36rem]"}
            outline
          >
            Cancel
          </Button>

          <div className={"text-sm space-x-2"}>
            <Button
              type={"button"}
              onClick={handleResetChanges}
              className={"border-primary text-primary py-[0.36rem]"}
              outline
            >
              Reset
            </Button>
            <Button className={"text-sm"}>Save</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const PermissionGroup = ({
  groupName,
  permissions,
  assignedPermissionIds,
  onToggle: handleToggle,
}) => {
  return (
    <div className={"space-y-2 border-green-600"}>
      <p className={"font-medium text-gray-800 capitalize"}>{groupName}</p>
      <div className={"space-y-1"}>
        {permissions?.map((permission) => (
          <div className={"flex items-center space-x-3"} key={permission.id}>
            <ToggleSwitch
              defaultEnabled={assignedPermissionIds?.includes(permission.id)}
              onChange={(value) => handleToggle(value, permission.id)}
              className={"w-8 h-4"}
              sliderClassName={"h-3 w-3"}
              sliderEnabledClassName={"translate-x-4"}
            />
            <p className={"text-sm text-gray-600"}>{permission.displayName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionAssignmentForm;
