const DealTabs = ({ activeTab, onClick, tabs }) => {
  return (
    <div className="flex items-center sticky top-[3rem] z-20 bg-white">
      {tabs.map((tab, index) => {
        const { Icon, name } = tab;
        return (
          <button
            key={index}
            className={`flex items-center justify-center space-x-2 text-[0.89rem] rounded-t border-b-[0.1rem] px-4 py-1.5 ${
              activeTab === index
                ? "text-primary-800 bg-primary/10 border-primary/60"
                : "border-gray-100 text-gray-500"
            }`}
            onClick={() => onClick(index)}
          >
            <Icon className={"h-4 w-4"} strokeWidth={2} />
            <span>{name}</span>
          </button>
        );
      })}
    </div>
  );
};

export default DealTabs;
