import { useQuery } from "react-query";
import formatDate from "date-fns/format";

import Avatar from "../../components/Avatar";
import Loader from "../../components/Loader";
import { fetchContactActivities } from "../../api";
import { useEffect } from "react";
import {
  ActivityAppointment,
  ActivityComment,
} from "pages/Products/ProductDetails/Deals/Deal/ActivityComponents";

const ContactActivities = ({ contact }) => {
  const { isLoading, isRefetching, error, mutate, refetch, data } = useQuery(
    "weeklyActivities",
    handleFetchActivities,
  );

  useEffect(() => {
    refetch();
  }, [contact]);

  function handleFetchActivities() {
    return fetchContactActivities(contact.id);
  }

  // FIXME: Handle contact activities properly

  return (
    <div>
      <div className="sticky bg-white z-20 pt-3 top-0">
        <div className="flex items-center justify-between mb-2.5 px-5 max-w-5xl mx-auto">
          <h3 className="text-xl font-medium leading-none">
            Contact Activities
          </h3>

          <div className="flex items-center">
            <div className="relative rounded-md border border-gray-300 shadow-sm w-72">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                className="font-light focus:ring-gray-300 focus:border-gray-300 block w-full py-1.5 pl-9 text-base border-gray-300 rounded-md placeholder-gray-400"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>

        <div className="border-t border-b border-black/10 grid grid-cols-9 px-5 max-w-5xl mx-auto">
          <h5 className="py-2 font-medium col-span-5">Activity</h5>
          <h5 className="py-2 font-medium col-span-4 pl-8">Reference</h5>
          {/* <h5 className="py-2 font-medium col-span-2 pl-6">Deal</h5> */}
        </div>
      </div>

      <div className="px-5 max-w-5xl mx-auto">
        {(isLoading || isRefetching) && (
          <div className="h-24 relative">
            <Loader color="#aaa" thickness="3" />
          </div>
        )}

        {!isLoading &&
          !isRefetching &&
          data?.length > 0 &&
          data.map((activity, index) => (
            <ActivityEntry key={index} activity={activity} />
          ))}

        {!isLoading && !isRefetching && !data?.length && (
          <div className="text-center py-8 opacity-50">
            This contact has no activities.
          </div>
        )}
      </div>
    </div>
  );
};

function ActivityEntry({ activity, lastChild }) {
  const { type, actorId, action, createdAt, reference } = activity;
  const date = formatDate(new Date(createdAt), "MMM dd, yyyy hh:mm a");
  return (
    <div className="grid grid-cols-9 items-start py-4 border-b border-gray-100">
      <div className="col-span-5">
        <div className="flex items-start">
          <div className="relative">
            <div className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full overflow-hidden relative">
              <Avatar size="lg" src={actorId?.image} />
            </div>

            <span
              className={`absolute -bottom-1 -right-0.5 flex items-center justify-center flex-shrink-0 rounded-full border-2 border-white w-5 h-5 text-white z-10
              ${type === "addDeal" && "bg-gray-500"}
              ${["reorderDeal", "moveDeal"].includes(type) && "bg-indigo-500"}
              ${type === "comment" && "bg-pink-500"}
              ${type === "appointment" && "bg-orange-500"}
              ${type === "dealLost" && "bg-red-600"}
              ${type === "dealWon" && "bg-green-600"}
            `}
            >
              {"AAA"}
            </span>
          </div>

          <div className="-mt-px pt-px ml-3 flex-1 min-w-0">
            <p className="text-sms leading-snug text-black/50">
              <span className="text-black">
                {actorId.firstName} {actorId.lastName}
              </span>
              &nbsp;
              <span dangerouslySetInnerHTML={{ __html: action }}></span>
            </p>

            <p className="mt-1 font-medium text-xs leading-relaxed text-black/40">
              {date}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-4">
        <span className="block">
          {reference && (
            <div className="pl-8 pr-4">
              {type === "comment" && (
                <ActivityComment reference={reference[0] || reference || {}} />
              )}
              {type === "appointment" && (
                <ActivityAppointment
                  reference={reference[0] || reference || {}}
                />
              )}
            </div>
          )}
        </span>
      </div>
      {/* <div className="col-span-2 pl-6">{activity.deal.name}</div> */}
    </div>
  );
}

export default ContactActivities;
