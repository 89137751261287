import formatDate from "date-fns/format";
import { useQuery } from "react-query";
import Avatar from "../../components/Avatar";
import Loader from "../../components/Loader";
import { fetchAppointments } from "../../api";
import { ActivityComment } from "pages/Products/ProductDetails/Deals/Deal/ActivityComponents";

const Appointments = () => {
  const { isLoading, error, mutate, refetch, data } = useQuery(
    "allAppointments",
    fetchAppointments,
  );

  return (
    <div>
      <div className="sticky bg-white z-20 pt-2.5" style={{ top: "0px" }}>
        <div className="flex items-center justify-between mb-2.5 px-6">
          <h3 className="text-xl font-medium">Appointments</h3>
          {/*<div className="flex items-center">*/}
          {/*  <span className="mr-3">Filter:</span>*/}
          {/*  <button*/}
          {/*    type="button"*/}
          {/*    className="mr-3 border-none p-0 m-0 bg-transparent focus:outline-none"*/}
          {/*    id="menu-button"*/}
          {/*    aria-expanded="true"*/}
          {/*    aria-haspopup="true"*/}
          {/*  >*/}
          {/*    <span className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-400">*/}
          {/*      Filter by Sales Person*/}
          {/*      <svg*/}
          {/*        className="-mr-1 ml-2 h-5 w-5"*/}
          {/*        xmlns="http://www.w3.org/2000/svg"*/}
          {/*        viewBox="0 0 20 20"*/}
          {/*        fill="currentColor"*/}
          {/*        aria-hidden="true"*/}
          {/*      >*/}
          {/*        <path*/}
          {/*          fillRule="evenodd"*/}
          {/*          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"*/}
          {/*          clip-rule="evenodd"*/}
          {/*        ></path>*/}
          {/*      </svg>*/}
          {/*    </span>*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    type="button"*/}
          {/*    className="mr-3 border-none p-0 m-0 bg-transparent focus:outline-none"*/}
          {/*    id="menu-button"*/}
          {/*    aria-expanded="true"*/}
          {/*    aria-haspopup="true"*/}
          {/*  >*/}
          {/*    <span className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-400">*/}
          {/*      Filter by Deal*/}
          {/*      <svg*/}
          {/*        className="-mr-1 ml-2 h-5 w-5"*/}
          {/*        xmlns="http://www.w3.org/2000/svg"*/}
          {/*        viewBox="0 0 20 20"*/}
          {/*        fill="currentColor"*/}
          {/*        aria-hidden="true"*/}
          {/*      >*/}
          {/*        <path*/}
          {/*          fillRule="evenodd"*/}
          {/*          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"*/}
          {/*          clipRule="evenodd"*/}
          {/*        ></path>*/}
          {/*      </svg>*/}
          {/*    </span>*/}
          {/*  </button>*/}
          {/*  <div className="relative rounded-md border border-gray-300 shadow-sm w-72">*/}
          {/*    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">*/}
          {/*      <svg*/}
          {/*        className="w-4 h-4 text-gray-400"*/}
          {/*        fill="none"*/}
          {/*        stroke="currentColor"*/}
          {/*        viewBox="0 0 24 24"*/}
          {/*        xmlns="http://www.w3.org/2000/svg"*/}
          {/*      >*/}
          {/*        <path*/}
          {/*          stroke-linecap="round"*/}
          {/*          stroke-linejoin="round"*/}
          {/*          stroke-width="2"*/}
          {/*          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"*/}
          {/*        ></path>*/}
          {/*      </svg>*/}
          {/*    </div>*/}
          {/*    <input*/}
          {/*      type="search"*/}
          {/*      className="font-light focus:ring-gray-300 focus:border-gray-300 block w-full py-1.5 pl-9 text-base border-gray-300 rounded-md placeholder-gray-400"*/}
          {/*      placeholder="Search..."*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className="px-6 border-t border-b border-black/10 grid grid-cols-10">
          <h5 className="py-2 font-medium col-span-4">Appointment</h5>
          <h5 className="py-2 font-medium col-span-3 pl-10">Attendees</h5>
          <h5 className="py-2 font-medium col-span-3 pl-10">Report</h5>
        </div>
      </div>

      {isLoading && (
        <div className="h-56 relative">
          <Loader color="#aaa" thickness="3" />
        </div>
      )}

      {!isLoading &&
        data?.length &&
        data.map((appointment, index) => (
          <AppointmentEntry key={index} appointment={appointment} />
        ))}
    </div>
  );
};

function AppointmentEntry({ appointment, lastChild }) {
  let { type, date, user, contact, startTime, endTime, agenda, notes, report } =
    appointment;
  date = formatDate(new Date(date), "MMM dd, yyyy hh:mm a");

  return (
    <div className="px-6 grid grid-cols-10 items-start py-4 border-b border-gray-100">
      <div className="col-span-4">
        <div className="flex items-start">
          <div className="leading-none uppercase flex flex-col items-center justify-center py-2 px-4 rounded flex-shrink-0 bg-gray-100 border border-gray-400/50">
            <span
              className="mb-px pb-px font-lights leading-none opacity-70 tracking-wide"
              style={{ fontSize: "13px" }}
            >
              {formatDate(new Date(date), "MMM")}
            </span>
            <span className="pt-0.5 font-semibold text-xl leading-none tracking-wide">
              {formatDate(new Date(date), "dd")}
            </span>
          </div>

          <div className="ml-4 flex-1 min-w-0">
            <h5 className="font-medium">{agenda}</h5>
            <div className="mt-1.5 flex items-center">
              <div className="rounded-full py-1 px-2 text-xs leading-none uppercase border">
                {startTime} - {endTime}
              </div>
              <span className="font-bold text-lg leading-none px-2 opacity-40">
                &middot;
              </span>
              <div className="rounded-full py-1 px-2.5 text-xs leading-none capitalize border tracking-wide">
                {type}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 pl-10">
        <div className="inline-flex items-center mb-2 rounded-full text-xs leading-none border p-px">
          {/* <div className="w-6 h-6 bg-gray-200 rounded-full overflow-hidden relative">
            <img
              src={user.image}
              className="absolute w-full h-full object-cover"
              alt=""
            />
          </div> */}
          <Avatar size="sm" src={user.image} />
          <span className="py-1 pl-1.5 pr-2">
            {user["firstName"]} {user["lastName"]}
          </span>
        </div>
      </div>
      <div className="col-span-3 pl-10">
        {report?.length && <ActivityComment reference={{ body: report }} />}

        {!report?.length && (
          <span className="text-black/50">
            {/* No report provided by appointment creator. */}
            &mdash;
          </span>
        )}
      </div>
    </div>
  );
}

export default Appointments;
