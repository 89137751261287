import { Combobox } from "@headlessui/react";

const Options = ({ category, options }) => {
  return (
    <div className={""}>
      {category ? (
        <OptionsSection options={options} />
      ) : (
        <div>
          {options?.products?.length ? (
            <OptionsSection title={"products"} options={options?.products} />
          ) : (
            ""
          )}
          {options?.teams?.length ? (
            <OptionsSection title={"teams"} options={options?.teams} />
          ) : (
            ""
          )}
          {options?.deals?.length ? (
            <OptionsSection title={"deals"} options={options?.deals} />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

const OptionsSection = ({ title, options }) => {
  return (
    <div className={"pt-1 pb-3"}>
      {title && (
        <Combobox.Option key={title} value={title}>
          {({ active }) => (
            <div
              className={`flex items-center justify-between ${
                active ? "bg-primary-200" : ""
              } rounded-lg px-2 py-2`}
            >
              <h1 className={"text-gray-800 text-sm font-medium capitalize"}>
                {title}
              </h1>
              <p
                className={`flex items-center text-gray-500 ${
                  !active && "hidden"
                }`}
              >
                <span className="text-sm">Press</span>
                <span
                  className={
                    "text-[0.7rem] text-gray-600 bg-white font-black px-1.5 py-1 mx-2 border border-[#e5e7eb] rounded-md"
                  }
                >
                  Enter
                </span>
                <span className={"text-sm"}>to search in</span>
                <span className={"text-sm ml-1 capitalize"}>{title}</span>
              </p>
            </div>
          )}
        </Combobox.Option>
      )}
      {options?.map((option) => (
        <Combobox.Option key={option.id} value={option}>
          {({ active }) => {
            return (
              <div
                className={`${
                  active ? "bg-primary-200" : "bg-gray-100"
                } flex items-center justify-between rounded-lg my-1 ml-2 px-3 py-2`}
              >
                <span className={"text-[0.8rem] text-gray-800"}>
                  {option.name}
                </span>
                {/*<span className={`${active ? "text-primary-800" : "hidden"}`}>*/}
                {/*    <ArrowRightIcon className={"h-4 w-4"} strokeWidth={2}/>*/}
                {/*</span>*/}
                <p
                  className={`flex items-center text-gray-500 ${
                    !active && "hidden"
                  }`}
                >
                  <span className="text-sm">Press</span>
                  <span
                    className={
                      "text-[0.7rem] text-gray-600 bg-white font-black px-1.5 py-1 mx-2 border border-[#e5e7eb] rounded-md"
                    }
                  >
                    Enter
                  </span>
                  <span className={"text-sm"}>to jump</span>
                </p>
              </div>
            );
          }}
        </Combobox.Option>
      ))}
    </div>
  );
};

export default Options;
