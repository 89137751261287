import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useFormikContext } from 'formik';
import { getNestedObjectValue } from '../utils';
import Label from './formControl/label';

const ColorPicker = ({
  children,
  nolabel,
  choices = [
    "#2196f3", //blue
    "#4caf50", //green
    "#ff9800", //orange
    "#f44336", //red
    "#cde31e", //lime
    "#E91E63", //pink
    "#9c27b0", //purple
    // "#3f51b5", //violet
    "#795548", //brown
  ],
  value = "#2196f3",
  onChange = () => {},
  name = 'color',
  
}) => {
  const formikContext = useFormikContext();
  const { setFieldValue, values } = formikContext || {};
  const [selected, setSelected] = useState(values ? getNestedObjectValue(values, name) || value : value);
  
  useEffect(() => {
    setFieldValue(name, selected);
  }, [selected]);

  return (
    <div className="relative">
      { !nolabel ? <Label>Color</Label> : '' }
      <Popover className="relative">
        {({ close }) => (
          <>
            { !children && (
              <Popover.Button
                className="w-7 h-7 rounded-full focus:outline-none"
                style={{background: selected}}
              >
              </Popover.Button>
            ) }

            { children && <Popover.Button> { children({selected}) } </Popover.Button> }

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-40 left-0s">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white p-2 grid gap-2 grid-cols-4">
                    {choices.map((color, index) => (
                     <button key={index} type="button" className="w-full pb-[100%] rounded-full relative focus:outline-none"
                        style={{background: color}}
                        onClick={() => {setSelected(color); close()}}
                     >
                        { selected === color && (
                            <svg className="text-white absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                         )}
                     </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ColorPicker;