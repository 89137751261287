import { Outlet, useSearchParams } from "react-router-dom";

import Phase from "./Phase";
import { useProductDealsInPhases } from "pages/Products/ProductDetails/Deals/dealQueries";

const Deals = () => {
  const [searchParams] = useSearchParams();

  const paramsObject = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const { data: phases } = useProductDealsInPhases(paramsObject);

  const sortedPhases = phases?.sort((a, b) => a.order - b.order);
  const openPhases = sortedPhases?.slice(2);
  const closedPhases = sortedPhases?.slice(0, 2);
  const arrangedPhases = openPhases ? openPhases.concat(closedPhases) : [];

  return (
    <div className="bg-gray-100 flex items-start overflow-hidden h-[calc(100%_-_5rem)] space-x-4 w-full">
      <Outlet />
      <div className="flex flex-row my-4 mx-5 w-full overflow-x-auto space-x-4 h-full">
        {arrangedPhases?.map((phase) => {
          return <Phase key={phase.id} phase={phase} />;
        })}
      </div>
    </div>
  );
};

export default Deals;
