import { twMerge } from "tailwind-merge";

const SkeletonLoader = ({ className }) => {
  return (
    <p
      className={twMerge(`skeleton-loader w-full py-2 rounded`, className)}
    ></p>
  );
};

export default SkeletonLoader;
