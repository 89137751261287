import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

export default function SelectInput({
  options = [],
  selectedOption,
  onChange,
  className,
}) {
  const [selected, setSelected] = useState(
    selectedOption ? selectedOption : options[0],
  );

  const handleChange = (selectedOption) => {
    setSelected(selectedOption);
    if (onChange) onChange(selectedOption);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative">
        <Listbox.Button
          className={twMerge(
            "relative cursor-default rounded-lg bg-gray-50 py-2 pl-3 pr-10 text-[0.825rem] text-left active:outline-none focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2",
            className,
          )}
        >
          <span className="block truncate text-gray-900">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            className={twMerge(
              "absolute z-50 mt-1 max-h-56 w-max overflow-auto rounded-md bg-gray-50 py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              className,
            )}
          >
            {options.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `text-gray-900 relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-primary/10 " : ""
                  }`
                }
                value={person}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-normal" : "font-normal"
                      }`}
                    >
                      {person.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-800">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
