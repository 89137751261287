import {
  ArrowDownRightIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/24/outline";

import { shortenLargeNumber } from "utils";

const HighlightsCard = ({ highlights = {} }) => {
  const { winRate, lossRate, avgDealSize, avgDealCycle } = highlights;

  const winRateFormatted = (winRate ? winRate.toFixed() : 0) + "%";
  const lossRateFormatted = (lossRate ? lossRate.toFixed() : 0) + "%";
  const avgDealSizeFormatted =
    "TZS " + (avgDealSize ? shortenLargeNumber(avgDealSize) : 0);
  const avgDealCycleFormatted =
    (avgDealCycle ? (avgDealCycle / (24 * 3600000)).toFixed() : 0) + " DAYS";

  return (
    <div
      className={
        "bg-white rounded-xl flex divide-x space-x-4 px-4 py-8 justify-between"
      }
    >
      <Highlight title={"win rate"} value={winRateFormatted} change={2.3} />
      <Highlight title={"loss rate"} value={lossRateFormatted} change={0} />
      <Highlight
        title={"avg. deal size"}
        value={avgDealSizeFormatted}
        change={-1.6}
      />
      <Highlight
        title={"avg. deal cycle"}
        value={avgDealCycleFormatted}
        change={1.1}
      />
    </div>
  );
};

const Highlight = ({ title, value, change = 0 }) => {
  return (
    <div className={"w-full flex flex-col pl-12"}>
      <span className={"text-sm text-gray-500 capitalize"}>{title}</span>
      <span className={"text-3xl text-gray-700"}>{value}</span>
      <div className="flex items-center">
        {change === 0 ? (
          <ArrowRightIcon
            className={
              "h-4 w-4 rounded-full p-[0.1rem] text-green-400 bg-green-400 bg-opacity-20"
            }
            strokeWidth={3}
          />
        ) : change > 0 ? (
          <ArrowUpRightIcon
            className={
              "h-4 w-4 rounded-full p-[0.1rem] text-green-400 bg-green-400 bg-opacity-20"
            }
            strokeWidth={3}
          />
        ) : (
          <ArrowDownRightIcon
            className={
              "h-4 w-4 rounded-full p-[0.1rem] text-red-400 bg-red-400 bg-opacity-20"
            }
            strokeWidth={3}
          />
        )}
        <span
          className={`${
            change < 0 ? "text-red-400" : "text-green-400"
          } w-fit text-[0.8rem] pl-1 pr-2 py-0.25 rounded-lg`}
        >
          {`${change > 0 ? "+" : ""}${change.toFixed(2)}%`}
        </span>
        <span className={"w-fit text-[0.8rem] text-gray-400"}>
          vs last quarter
        </span>
      </div>
    </div>
  );
};

export default HighlightsCard;
