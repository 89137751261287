import SkeletonLoader from "components/SkeletonLoader";

const ProductCardLoader = () => {
  return (
    <div className="flex flex-col justify-between bg-white rounded-lg px-6 py-4">
      <div className="flex flex-col space-y-2 border-b-[1px] pb-4">
        <SkeletonLoader className={"p-2.5 w-16"} />
        <SkeletonLoader className={"p-2 w-36"} />
      </div>
      <div className="grid grid-cols-2 gap-4 py-4">
        {[...Array(4)].map((_, index) => (
          <div key={index} className="flex flex-col space-y-1">
            <SkeletonLoader className={"p-2.5 w-8"} />
            <SkeletonLoader className={"p-2 w-12"} />
          </div>
        ))}
      </div>
      <div className="flex flex-col space-y-2">
        <SkeletonLoader className={"p-[0.85rem]"} />
        <SkeletonLoader className={"p-[0.85rem]"} />
      </div>
    </div>
  );
};

export default ProductCardLoader;
