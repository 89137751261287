import ListMenuItem from "./ListMenuItem";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import Badge from "components/badge/badge";

function ListMenu({
  title = "List",
  onAdd,
  items = [],
  selected,
  onSelect,
  loading = false,
}) {
  return (
    <div className="w-[13rem] h-full relative overflow-hidden shadow-md flex flex-col bg-[#FDFCFC] flex-shrink-0">
      <div className="flex-shrink-0 px-3 pt-4 pb-2 border-b">
        <div className="flex items-center justify-between">
          <h3 className="flex text-xl space-x-2">
            <span className="">{title}</span>
            {/* <span className="rounded-full px-2 py-1 text-primary bg-red-100 text-sm font-light">
              {items?.length}
            </span> */}
            <Badge
              type="error"
              text={items?.length}
              className={"ring-0 bg-primary/10 text-primary-900"}
            />
          </h3>
          <span className="">
            {onAdd && (
              <div onClick={() => onAdd()}>
                <PlusCircleIcon className="h-6 w-6" strokeWidth={1.7} />
              </div>
            )}
          </span>
        </div>
      </div>
      <div className="flex-1 ml-1 overflow-y-auto relative">
        {loading ? (
          <div>loading..</div>
        ) : items?.length ? (
          items?.map((item) => (
            <ListMenuItem
              key={item.id}
              selected={selected?.id === item.id}
              item={item}
              onClick={() => onSelect(item)}
            />
          ))
        ) : (
          <div className="pl-4 py-2 text-gray-600">No teams!</div>
        )}
      </div>
    </div>
  );
}

// function Loader() {
//   return (
//     <div className="flex flex-col space-x-2">
//       {[...Array(6)].map((_, index) => (
//         <div key={index} className="h-5">
//           <Skeleton className="p-6 w-10 bg-gray-200" key={index} />
//         </div>
//       ))}
//     </div>
//   );
// }

export default ListMenu;
