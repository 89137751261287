import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SlideOver from "components/SlideOver";
import DealSlideHeader from "./DealSlideHeader";
import Details from "./Details";
import { useDealQuery } from "pages/Products/productQueries";

const Deal = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const { data: deal } = useDealQuery(dealId);

  function handleClose() {
    navigate(-1);
    setOpen(false);
  }

  return (
    <SlideOver open={open} onClose={handleClose}>
      <div className="bg-white h-full w-[40rem]">
        <DealSlideHeader title={deal?.name} onClose={handleClose} />
        <div className="h-[calc(100%_-_3rem)] px-4">
          <Details deal={deal} />
        </div>
      </div>
    </SlideOver>
  );
};

export default Deal;
