import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import Input from "../../components/formControl/input";
import { useMutation } from "react-query";
import { changePassword } from "../../api";
import { useAuth } from "../../providers/auth";
import logo from "assets/absa-logo.png";

export default function ChangePassword() {
  const navigate = useNavigate();
  const { setAuthUser, setAuthToken } = useAuth();

  const {
    isLoading,
    error,
    mutate: changePass,
  } = useMutation(changePassword, {
    onSuccess: () => {
      navigate("/login", { replace: true });
    },
    onError: (err) => {
      console.log("Error changing password: ", err);
      navigate("/login", { replace: true });
    },
  });

  async function onSubmit({ oldPassword, password }) {
    const payload = { oldPassword, password };
    changePass(payload);
  }

  return (
    <div className="min-h-screen flex pb-12">
      <div className="flex-1 flex flex-col">
        <div className="container mx-auto py-6 px-8">
          <img className="w-14" src={logo} alt="" />
        </div>

        <div className="flex-1 flex flex-col justify-center pb-36">
          <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form
              data={{ oldPassword: "", password: "" }}
              onSubmit={onSubmit}
              error={error}
              loading={isLoading}
              withWrapper={false}
              buttonText="Login"
              fullWidthButton={true}
            >
              <h1 className="-mb-1 text-4xl font-medium">
                Create new password
              </h1>
              <div className="">
                <label htmlFor="password" className="block opacity-75">
                  Old Password
                </label>
                <div className="mt-1">
                  <Input
                    name="oldPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border bg-transparent border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="pb-3">
                <label htmlFor="password" className="block opacity-75">
                  New Password
                </label>
                <div className="mt-1">
                  <Input
                    name="password"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border bg-transparent border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>
            </Form>

            {error && <p className="text-red-500 mt-5 text-center">{error}</p>}

            <div className="fixed inset-x-0 bottom-0 py-4 text-center bg-primary text-white text-sm">
              © {new Date().getFullYear()} Uzo. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
