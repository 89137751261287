import { useMutation, useQuery } from "react-query";

import { useRefreshCache } from "hooks/useManageCache";
import {
  addComment,
  fetchDeal,
  fetchDealActivities,
  fetchDealComments,
  fetchDealDeliveryData,
  fetchProductDealsDeliveryData,
  fetchProducts,
} from "api";
import { getProductsSummaries } from "api/endpoints/products";

export const productQueryKeys = {
  products: ["products"],
  statistics: (productId) => ["product", productId, "product-deliveries"],
};

export const dealQueryKeys = {
  deal: (dealId) => ["deal", dealId],
  dealDeliveries: (dealId) => ["deal", dealId, "deliveries"],
  dealActivities: (dealId) => ["deal", dealId, "activities"],
  dealComments: (dealId) => ["deal", dealId, "comments"],
};

export const useProductsQuery = (options) => {
  return useQuery(productQueryKeys.products, fetchProducts, { ...options });
};

export const useProductsSummaries = (options) => {
  return useQuery(productQueryKeys.products, getProductsSummaries, {
    ...options,
    select: (res) => res.data,
  });
};

export const useProductDealsQuery = (productId, options) => {
  return useQuery(
    productQueryKeys.statistics(productId),
    () => fetchProductDealsDeliveryData(productId),
    {
      enabled: !!productId,
      ...options,
      select: (tats) => tats.data,
    },
  );
};

/*
 * Deal
 */

export const useDealQuery = (dealId, options) => {
  return useQuery(dealQueryKeys.deal(dealId), () => fetchDeal(dealId), {
    enabled: !!dealId,
    select: (res) => res.data,
    ...options,
  });
};

export const useDealDeliveriesQuery = (dealId, options) => {
  return useQuery(
    dealQueryKeys.dealDeliveries(dealId),
    () => fetchDealDeliveryData(dealId),
    {
      enabled: !!dealId,
      ...options,
      select: (deliveries) => deliveries.data,
    },
  );
};

export const useGetDealActivitiesQuery = (dealId, options) => {
  const queryOptions = {
    ...options,
    enabled: !!dealId,
    select: (res) => res.data,
  };

  return useQuery(
    dealQueryKeys.dealActivities(),
    () => fetchDealActivities(dealId),
    queryOptions,
  );
};

export const useGetDealCommentsQuery = (dealId, options) => {
  const queryOptions = {
    ...options,
    enabled: !!dealId,
    select: (res) => res.data,
  };

  return useQuery(
    dealQueryKeys.dealComments(),
    () => fetchDealComments(dealId),
    queryOptions,
  );
};

export const useAddCommentMutation = (dealId, options) => {
  const refreshCache = useRefreshCache();

  const handleOnError = () => {
    alert("Adding comment failed");
  };

  const handleOnSuccess = async (data) => {
    await refreshCache(dealQueryKeys.dealComments(dealId));
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  };

  return useMutation(["add-comment", dealId], addComment, mutationOptions);
};
