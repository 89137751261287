import { useMutation, useQuery } from "react-query";

import { useRefreshCache } from "hooks/useManageCache";
import {
  addRole,
  getAllPermissions,
  getRoleById,
  getRoles,
  removeRole,
  updateRole,
  updateRolePermissions,
} from "api/endpoints/rolesAndPermissions";

const roleQueryKeys = {
  roles: ["roles"],
  role: (roleId) => ["roles", roleId],
  allPermissions: ["all-permissions"],
};

export const useRoles = (options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
  };

  return useQuery(roleQueryKeys.roles, getRoles, queryOptions);
};

export const useRole = (roleId, options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
    enabled: !!roleId,
  };

  return useQuery(
    roleQueryKeys.role(roleId),
    () => getRoleById(roleId),
    queryOptions,
  );
};

export const useAllPermissions = (options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
  };

  return useQuery(
    roleQueryKeys.allPermissions,
    getAllPermissions,
    queryOptions,
  );
};

export const useAddRole = (options) => {
  const refreshCache = useRefreshCache();

  const handleOnSuccess = async (data) => {
    await refreshCache(roleQueryKeys.roles, true);
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleOnSuccess,
  };

  return useMutation(["add-role"], addRole, mutationOptions);
};

export const useUpdateRolePermissions = (options) => {
  const refreshCache = useRefreshCache();

  const handleSuccess = async (data) => {
    await refreshCache(roleQueryKeys.roles, true);
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleSuccess,
  };

  return useMutation(
    ["update-role-permissions"],
    updateRolePermissions,
    mutationOptions,
  );
};

export const useUpdateRole = (options) => {
  const refreshCache = useRefreshCache();

  const handleSuccess = async (data) => {
    await refreshCache(roleQueryKeys.roles, true);
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleSuccess,
  };

  return useMutation(["update-role"], updateRole, mutationOptions);
};

export const useRemoveRole = (options) => {
  const refreshCache = useRefreshCache();

  const handleSuccess = async () => {
    await refreshCache(roleQueryKeys.roles, true);
    if (options?.onSuccess) {
      options.onSuccess();
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleSuccess,
  };

  return useMutation(["remove-role"], removeRole, mutationOptions);
};
