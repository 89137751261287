import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import {
  createContact,
  deleteContact,
  fetchCompanies,
  updateContact,
} from "../../api";

function DeleteContact({ contact = {}, onClose, onSave = () => {} }) {
  const navigate = useNavigate();
  const companyQuery = useQuery("companies", fetchCompanies, {
    refetchOnWindowFocus: false,
  });
  const [values, setValues] = useState({
    phoneType: "Mobile",
    type: "Lead",
    ...contact,
  });

  useEffect(() => {
    // saveValueToLocalStorage("companies", []);
    // saveValueToLocalStorage("contacts", []);
    // saveValueToLocalStorage("pipelines", []);
  }, []);

  // useEffect(() => {
  //     if(companyQuery.data) setCompanyChoices(companyQuery.data);
  // }, [companyQuery.data]);

  const createMutation = useMutation(createContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  const updateMutation = useMutation(updateContact, {
    onSuccess: (data) => {
      // console.log("Contact created: ", data);
      onSave(data);
    },
  });

  const deleteMutation = useMutation(deleteContact, {
    onSuccess: (data) => {
      console.log("deleted contact yeah -->", data);
    },
    onError: (err) => {
      console.log("errororor delete", err);
    },
  });

  async function handleSubmit() {
    try {
      // console.log(values._id)
      // if(!values._id) createMutation.mutate(data);
      // else
      // updateMutation.mutate(data);
      deleteMutation.mutate(values.id);
    } catch (error) {
      console.log("New contact error: ", error);
    }
  }

  function handleClose() {
    if (onClose) onClose();
    else {
      navigate("/contacts", {
        replace: true,
      });
    }
  }

  return (
    <Modal
      title={values.id && "Delete Contact"}
      size="xl"
      open={true}
      onClose={handleClose}
    >
      <div className="   flex flex-col overflow-hidden px-10 py-10">
        <div className="text-center"></div>
        <div className="text-center py-6 text-2xl text-gray-700">
          Are you sure ?
        </div>
        <div className="text-center font-light text-gray-700 mb-8">
          Do you really want to delete this contact? This process cannot be
          undone.
        </div>
        <div className="flex justify-center">
          <button
            className="bg-gray-300 text-gray-900 rounded hover:bg-gray-200 px-6 py-2 focus:outline-none mx-1"
            onClick={handleClose}
          >
            Cancel
          </button>

          <button
            className="bg-primary text-gray-200 rounded hover:bg-red-400 px-6 py-2 focus:outline-none mx-1"
            onClick={handleSubmit}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteContact;
