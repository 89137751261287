import { useParams } from "react-router-dom";

import useRouteModal from "hooks/useRouteModal";
import Modal from "components/Modal";
import Form from "components/Form";
import Input from "components/formControl/input";
import Select from "components/formControl/Select";
import { useRoles } from "pages/Settings/Roles/roleQueries";
import {
  useAddUser,
  useUpdateUser,
  useUser,
} from "pages/Settings/Users/userQueries";

export default function AddUser() {
  const { userId } = useParams();
  const { open, closeModal } = useRouteModal();

  const { mutate: addUserMutation, isLoading: isAddingUser } = useAddUser({
    onSuccess: closeModal,
  });

  const { mutate: updateUserMutation, isLoading: isUpdatingUser } =
    useUpdateUser({
      onSuccess: closeModal,
    });

  const { data: user } = useUser(userId);
  const { data: roles, isLoading: isLoadingRoles } = useRoles();

  console.log("User: ", user);

  const handleSubmit = (formValues = {}) => {
    const { firstName, lastName, email, phoneNumber } = formValues;

    if (userId) {
      const { role, ...rest } = formValues;
      updateUserMutation({
        ...rest,
        userId,
        roleId: role?.id,
      });
    }

    if (!userId && firstName && lastName && email && phoneNumber) {
      const { role, ...rest } = formValues;
      addUserMutation({
        ...rest,
        roleId: role?.id,
      });
    }
  };

  return (
    <Modal
      size="lg"
      open={open}
      onClose={closeModal}
      title={`${userId ? "Edit" : "Add"} Role`}
    >
      <Form
        withWrapper={false}
        loading={isAddingUser || isUpdatingUser}
        buttonText={userId ? "Save" : "Add"}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <div className="space-y-6 mt-5 mb-7">
            <Input
              label="First Name"
              name="firstName"
              placeholder="e.g. Rosemary"
              defaultValue={user?.firstName}
            />

            <Input
              label="Last Name"
              name="lastName"
              placeholder="e.g. Mlyapatali"
              defaultValue={user?.lastName}
            />

            <Input
              label="Email"
              name="email"
              placeholder="e.g. Rosemary.Mlyapatali@example.com"
              defaultValue={user?.email}
            />

            <Input
              label="Phone Number"
              name="phoneNumber"
              placeholder="e.g. 0756895654"
              defaultValue={user?.phoneNumber}
            />

            {roles && (
              <Select
                label={"Role"}
                placeholder={"Choose Role"}
                name={"role"}
                loading={isLoadingRoles}
                labelKey={"name"}
                choices={roles}
                defaultValue={user?.role}
              />
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
}
