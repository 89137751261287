import {
  addEntryToLocalStorageArray,
  getValueFromLocalStorage,
  saveValueToLocalStorage,
} from "hooks/useLocalStorageState";
import { get, patch, post, put, remove } from "api/request";

const waitDuration = 500;

export async function signIn(payload) {
  return post("/auth/staff/login", payload);
}

export async function changePassword(payload) {
  return post("/auth/change-password", payload);
}

export async function resetPassword(payload) {
  return post("/auth/forgot-password", payload);
}

export async function fetchCompanies() {
  await { then: (res) => setTimeout(res, waitDuration) };
  //    return getValueFromLocalStorage("companies", []);
  return get("/companies");
}

export async function fetchTeams() {
  await { then: (res) => setTimeout(res, waitDuration) };
  return getValueFromLocalStorage("teams", []);
}

export async function createCompany(payload) {
  const entry = addEntryToLocalStorageArray("companies", payload, true);
  await { then: (res) => setTimeout(res, waitDuration) };
  return entry;
  // return post('/companies', payload);
}

export async function addTeam(payload) {
  return post("/teams", payload);
}

export async function editTeam(payload) {
  return put(`/teams/${payload.id}`, payload);
}

export async function inviteMemberToTeam({ teamId, newMemberId, targets }) {
  return put(`/teams/${teamId}/manage-members`, {
    members: { added: [newMemberId] },
    targets,
  });
}

export async function fetchContacts() {
  return get("/contacts/all");
}

export async function createContact(payload) {
  return post("/contacts", payload);
}

export async function updateContact(payload) {
  return patch("/contacts/" + payload.id, payload);
}

export async function deleteContact(payload) {
  return delete `/contacts/${payload}`;
}

export async function fetchProducts() {
  return await get("/products");
}

export async function fetchDeal(dealId) {
  return await get(`/deals/${dealId}`);
}

export async function searchProducts(query) {
  return await get(`/products/search?q=${query}`);
}

export async function searchTeams(query) {
  return await get(`/teams/search?q=${query}`);
}

export async function searchDeals(query) {
  return await get(`/deals/search?q=${query}`);
}

export async function searchAll(query) {
  return await get(`/products/search/all?q=${query}`);
}

export async function fetchSectors() {
  return await get("/sectors");
}

export async function fetchNotifications() {
  return await get("/notifications");
}

export async function updateNotification({ id, ...rest }) {
  return await put(`/notifications/${id}`, { ...rest });
}

export async function updateManyNotification(payload) {
  return await post("/notifications/bulk-update", payload);
}

export async function removeNotification(notificationId) {
  return await remove(`/notifications/${notificationId}`);
}

// fetch deal tat data
export async function fetchDealDeliveryData(dealId) {
  return await get(`/kpis/tats/deal/${dealId}/`);
}

// fetch product deals with tat
export async function fetchProductDealsDeliveryData(productId) {
  return await get(`/kpis/tats/product/${productId}/`);
}

export async function createProduct(payload) {
  return post("/products", payload);
}

export async function fetchAppointments() {
  const appointments = await get("/appointments");
  return appointments ? appointments["data"] : null;
}

export async function fetchMetricTypes() {
  return await get("/metrics/types");
}

export async function fetchKPIs() {
  const kpis = await get("/kpis");
  return kpis ? kpis["data"] : null;
}

export async function createKPI(payload) {
  return await post("/kpis", payload);
}

export async function addComment(payload) {
  return await post(`/comments`, payload);
}

export async function fetchDealComments(dealId) {
  return await get(`/comments/${dealId}`);
}

export async function fetchActivities() {
  const activities = await get("/activities");
  return activities ? activities["data"] : null;
}

export async function fetchContactActivities(contactId) {
  const activities = await get(`/activities/by-query/?reference=${contactId}`);
  return activities ? activities["data"] : null;
}

export async function fetchMemberActivities(actorId) {
  return await get(`/activities/by-query?actorId=${actorId}`);
}

export async function fetchMemberActivitiesForProduct(actorId, productId) {
  return await get(`/activities/all?actorId=${actorId}&productId=${productId}`);
}

export async function fetchSalesTeam() {
  return get("/teams");
}

export async function fetchSalesTeamById(id) {
  return get(`/teams/${id}`);
}

export async function deleteSalesTeamById(teamId) {
  return await remove(`/teams/${teamId}`);
}

export async function fetchSalesTeamDeals(id) {
  return get(`/deals/team/${id}`);
}

export async function fetchSalesPeople() {
  return await get(`/access-control/roles/62a6dedf95a5e70004fd4c75/staff`);
}

export async function fetchDealActivities(dealId) {
  return await get(`/activities/by-query?reference=${dealId}`);
}

export async function fetchDeals() {
  return await get("/deals");
}

export async function fetchKpis() {
  return await get("/kpis");
}

//
export async function fetchTeamPerformances(id) {
  return get(`/teams/${id}/performance`);
}

export async function deletePipeline(pipelineId) {
  return remove("/NOpipelines/" + pipelineId);
}

//
export async function deleteProduct(productId) {
  return remove("products/" + productId);
}

export async function createProductPhase(payload) {
  return await put(`/products/${payload.id}/phases`, payload);
}

/**********************/

export async function addDealToStage({ stage, pipeline, ...payload }) {
  return await post(
    `/NOMOREpipelines/${pipeline.id}/stages/${stage.id}`,
    payload,
  );
}

// export async function fetchSalesTeamPipelineStats(id) {
//     return get(`/NOMOREpipelines/${id}/stats`);
// };

export async function moveDeal({ pipeline, affectedStages, deal }) {
  console.log(pipeline, affectedStages, deal);
  return patch(`/NOpipelines/${pipeline.id}/move-stage`, {
    fromStageId: affectedStages[0],
    toStageId: affectedStages[1] ? affectedStages[1] : affectedStages[0],
    dealId: deal.id,
  });
}

export async function updatePipeline(payload) {
  payload.stages = payload.stages.map((stage) => {
    if (stage && stage.id) return stage;
    const uniqId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    return {
      ...stage,
      id: uniqId,
      deals: [],
    };
  });
  let pipelines = getValueFromLocalStorage("pipelines", []);
  pipelines = pipelines.map((p) => {
    if (p.id === payload.id) return payload;

    return p;
  });
  saveValueToLocalStorage("pipelines", pipelines);
  await { then: (res) => setTimeout(res, waitDuration) };
  return payload;
  // return post('/pipeline', payload);
}
