import { Link, useMatch, useResolvedPath } from "react-router-dom";
import {
  CalendarIcon,
  Cog8ToothIcon,
  HomeIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

function SidebarLink({ to, matchAll = true, children, ...props }) {
  const resolved = useResolvedPath(to + "/*");

  const match = useMatch({ path: resolved.pathname, end: true });

  let className =
    "hover:text-black flex flex-auto justify-start items-center py-3 px-4 text-base border-l-4 ";

  className += match
    ? "border-primary bg-primary/20"
    : "border-transparent hover:bg-primary-50";

  return (
    <Link to={to} className={className} {...props}>
      {children}
    </Link>
  );
}

export default function Sidebar({ leftBarMinimized }) {
  return (
    <div
      className={`
      bg-white shadow text-black z-30 flex-shrink-0 overflow-hidden transition-all
      ${leftBarMinimized ? "w-14" : "w-[12rem]"} 
    `}
    >
      <div className="h-full flex flex-col">
        <nav className="flex flex-col mt-2">
          <SidebarLink to="/dashboard/home">
            <HomeIcon
              className="h-[1.1rem] w-[1.1rem] flex-shrink-0"
              strokeWidth={2}
            />
            <span className="pl-5 text-[0.9rem] truncate">Dashboard</span>
          </SidebarLink>
          <SidebarLink to="/dashboard/teams">
            <UserGroupIcon
              className="h-[1.1rem] w-[1.1rem] flex-shrink-0"
              strokeWidth={2}
            />
            <span className="pl-5 text-[0.9rem] truncate">Teams</span>
          </SidebarLink>
          {/*<SidebarLink to="/dashboard/products">*/}
          {/*  <svg*/}
          {/*    className="h-[1.1rem] w-[1.1rem] flex-shrink-0"*/}
          {/*    fill="currentColor"*/}
          {/*    viewBox="0 0 24 24"*/}
          {/*  >*/}
          {/*    <path*/}
          {/*      d="M20 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm0 2h-2v14h2zM6 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm0 2H4v10h2zm7-2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm0 2h-2v6h2z"*/}
          {/*      fillRule="evenodd"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*  <span className="pl-5 text-[0.9rem] truncate">Products</span>*/}
          {/*</SidebarLink>*/}
          <SidebarLink to="/dashboard/contacts">
            <UserCircleIcon
              className="h-[1.1rem] w-[1.1rem] flex-shrink-0"
              strokeWidth={2}
            />
            <span className="pl-5 text-[0.9rem] truncate">Contacts</span>
          </SidebarLink>
          <SidebarLink to="/dashboard/appointments">
            <CalendarIcon
              className="h-[1.1rem] w-[1.1rem] flex-shrink-0"
              strokeWidth={2}
            />
            <span className="pl-5 text-[0.9rem] truncate">Appointments</span>
          </SidebarLink>
          {/*<SidebarLink to="/tracker">*/}
          {/*  <span className="pl-5 truncate">Tracker</span>*/}
          {/*</SidebarLink>*/}
        </nav>
        {/* { authUser.role.name === 'Root' &&  */}
        <div className="mt-auto border-t">
          <SidebarLink to="/dashboard/settings">
            {/* <svg
              className="h-5 w-5 flex-shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg> */}
            <Cog8ToothIcon
              className="h-[1.2rem] w-[1.2rem] flex-shrink-0"
              strokeWidth={2}
            />
            <span className="pl-5 text-[0.9rem] truncate">Settings</span>
          </SidebarLink>
        </div>
        {/* } */}
      </div>
    </div>
  );
}
