import { useEffect, useState } from "react";
import Topbar from "./TopBar";
import LeftBar from "./LeftBar";
import {
  getValueFromLocalStorage,
  saveValueToLocalStorage,
} from "hooks/useLocalStorageState";

function AuthLayout({ children }) {
  const [leftBarMinimized, setLeftBarMinimized] = useState(
    getValueFromLocalStorage("minimizeSidebar", true)
  );

  useEffect(() => {
    saveValueToLocalStorage("minimizeSidebar", leftBarMinimized);
  }, [leftBarMinimized]);

  const onToggleLeftBar = () => setLeftBarMinimized(!leftBarMinimized);

  return (
    <main className="flex flex-col w-screen h-screen soverflow-y-hidden">
      <Topbar onToggleLeftBar={onToggleLeftBar} />
      <div className="flex-1 flex overflow-y-hidden">
        <LeftBar leftBarMinimized={leftBarMinimized} />
        <div className="flex-1 overflow-y-auto relative bg-gray-100">
          {children}
        </div>
      </div>
    </main>
  );
}

export default AuthLayout;
