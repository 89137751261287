import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
//
import Modal from "components/Modal/Model";
import { Combobox } from "@headlessui/react";
import { useSearch } from "./SearchQueries";
import Options from "./Options";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../utils";
import useKeyboardEventListener from "../../hooks/useKeyboardEventListener";

const QuickSearch = () => {
  const [showSearchBox, setShowSearchBox] = useState(false);

  const handleClick = () => setShowSearchBox(false);

  useKeyboardEventListener(() => setShowSearchBox(true), "/");

  return (
    <div
      tabIndex={1}
      className={`${
        showSearchBox && "hidden"
      } flex items-center justify-between space-x-2 pl-3 pr-2 py-1 border-[1px] rounded-lg bg-gray-100 text-gray-400 w-72`}
      onClick={() => setShowSearchBox(true)}
    >
      <MagnifyingGlassIcon className={"h-4 w-4"} />
      <span className={"text-[0.9rem] flex-1"}>Quick search... </span>
      <span
        className={
          "text-[0.7rem] text-gray-600 bg-white font-black px-2.5 py-[0.1rem] border border-[#e5e7eb] rounded-md"
        }
      >
        /
      </span>
      <SearchBox open={showSearchBox} onClose={handleClick} />
    </div>
  );
};

const SearchBox = ({ open, onClose }) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const interval = useRef(null);

  const [category, setCategory] = useState("");
  const [query, setQuery] = useState("");

  const { data: searchResults } = useSearch(query, category);

  const handleInputChange = ({ target: { value } }) => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (!value) {
      setQuery("");
      return;
    }

    interval.current = setInterval(() => setQuery(value), 250);
  };

  const handleSelect = (selected) => {
    if (!selected) return;
    if (typeof selected === "string") {
      setCategory(selected);
      return;
    }
    switch (selected.type) {
      case "product":
        navigate(`home/${slugify(selected.name)}/analytics`);
        break;
      case "team":
        navigate(`teams/${slugify(selected.name)}`);
        break;
      case "deal":
        navigate(`home/${slugify(selected.product)}/deals/${selected.id}`);
        break;
      default:
        return;
    }
    navigate(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.key === "Backspace" && !query) {
      setCategory("");
    }
  };

  const handleClearCategory = () => {
    setCategory("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClose = () => onClose();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      dialogClass={" pt-[2vh] md:pt-[15vh]"}
    >
      <Combobox
        as={"div"}
        className={
          "bg-white w-[42rem] rounded-xl overflow-hidden mx-auto border border-[#e5e7eb]"
        }
        onChange={handleSelect}
      >
        <header
          className={`flex items-center space-x-2  px-3 py-2 ${
            searchResults?.length ? "border-b border-b-[#e5e7eb]" : ""
          }`}
        >
          <MagnifyingGlassIcon
            className={"h-5 w-5 text-gray-600"}
            strokeWidth={2}
          />

          {category !== "" && (
            <div
              className={
                "bg-primary-600 flex items-center space-x-2 px-2 rounded-xl"
              }
            >
              <span className="text-white capitalize p-0.5">{category}</span>
              <span className="text-white" onClick={handleClearCategory}>
                <XCircleIcon
                  className={"h-[1.1rem] w-[1.1rem]"}
                  strokeWidth={2}
                />
              </span>
            </div>
          )}

          <Combobox.Input
            onChange={handleInputChange}
            onKeyDownCapture={handleKeyDown}
            type="text"
            ref={inputRef}
            placeholder={
              category ? `Search in ${category} ...` : "Type to search..."
            }
            className={"flex-1 focus:ring-0 border-0 bg-inherit px-1"}
            displayValue={(option) => option?.name}
          />

          {/* cancel button */}
          {/*<button*/}
          {/*    onClick={() => handleClose()}*/}
          {/*    className={*/}
          {/*        "text-[10px] text-gray-600 bg-white font-black px-1.5 py-1 border border-[#e5e7eb] rounded-md"*/}
          {/*    }*/}
          {/*>*/}
          {/*    <span className="hidden md:inline">ESC</span>*/}
          {/*</button>*/}
        </header>

        <Combobox.Options
          static={true}
          className={"divide-y m overflow-y-auto divide-[#e5e7eb] px-3"}
        >
          {/*{*/}
          {/*    (category === "" && !searchResults?.products?.length && !searchResults?.teams?.length && searchResults?.deals?.length) ?*/}
          {/*        <p className={"text-sm"}>No results!<span className=""></span></p> :*/}
          {/*        <Options category={category} options={searchResults}/>*/}
          {/*}*/}

          <Options category={category} options={searchResults} />
        </Combobox.Options>
      </Combobox>
    </Modal>
  );
};

export default QuickSearch;
