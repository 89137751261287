import { queryClient } from "App";

export const useRefreshCache = () => {
  return (queryKey, hardReload) =>
    hardReload
      ? queryClient.invalidateQueries(queryKey)
      : queryClient.refetchQueries(queryKey);
};

export const useAddToCache = () => (queryKey, newData) => {
  queryClient.setQueryData(queryKey, (oldQueryData) => ({
    ...oldQueryData,
    data: [newData, ...oldQueryData.data],
  }));
};

export const useUpdateCache = () => (queryKey, newData) => {
  queryClient.setQueryData(queryKey, (oldQueryData) => ({
    ...oldQueryData,
    data: oldQueryData.data.map((oldData) =>
      oldData.id === newData.id ? newData : oldData,
    ),
  }));
};

export const useRemoveFromCache = () => (queryKey, newData) => {
  queryClient.setQueryData(queryKey, (oldData) => ({
    ...oldData,
    data: oldData.data.filter((_data) => _data?.id !== newData.id),
  }));
};

export const useCacheData = (queryKey, defaultValue) => {
  const cachedData = queryClient.getQueryData(queryKey);
  return cachedData || defaultValue;
};
