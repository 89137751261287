import { useEffect, useRef } from "react";
import { Chart } from "chart.js";

const LineChart = ({
  label = "Deals",
  labels = ["Jan", "Feb", "Mar", "Apr", "May"],
  data = [48, 32, 63, 45, 85],
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    const gradient = myChartRef.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(174, 21, 75, 0.8)");
    gradient.addColorStop(1, "rgba(174, 21, 75, 0)");

    const presentChart = Chart.getChart("myChart");
    if (presentChart != undefined) {
      presentChart.destroy();
    }

    new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            backgroundColor: gradient,
            borderColor: "rgba(179, 82, 92, 1)",
            borderWidth: 2,
            fill: "start",
            tension: 0.4,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
            },
          },
          y: {
            grid: {
              drawOnChartArea: false,
            },
            beginAtZero: true,
          },
        },
      },
    });
  });

  // useEffect(() => {
  //   const myChartRef = chartRef.current.getContext("2d");
  //   let chartInstance = Chart.getChart("myChart");
  //
  //   // Initialize the chart if it doesn't exist
  //   if (!chartInstance) {
  //     const gradient = myChartRef.createLinearGradient(0, 0, 0, 400);
  //     gradient.addColorStop(0, "rgba(179, 82, 92, 1)");
  //     gradient.addColorStop(1, "rgba(179, 82, 92, 0)");
  //
  //     chartInstance = new Chart(myChartRef, {
  //       type: "line",
  //       data: {
  //         labels,
  //         datasets: [
  //           {
  //             label,
  //             backgroundColor: gradient,
  //             borderColor: "rgba(179, 82, 92, 1)",
  //             borderWidth: 2,
  //             fill: "start",
  //             tension: 0.4,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: true,
  //         plugins: {
  //           legend: {
  //             display: false,
  //           },
  //           title: {
  //             display: false,
  //           },
  //         },
  //         scales: {
  //           x: {
  //             grid: {
  //               drawOnChartArea: false,
  //             },
  //           },
  //           y: {
  //             beginAtZero: true,
  //           },
  //         },
  //       },
  //     });
  //   } else {
  //     // Update existing chart
  //     chartInstance.data.labels = labels;
  //     chartInstance.data.datasets[0].data = data;
  //     chartInstance.update();
  //   }
  //
  //   return () => {
  //     chartInstance.destroy();
  //   };
  // }, [labels, data]);

  return (
    <div className="">
      <canvas id="myChart" ref={chartRef}></canvas>
    </div>
  );
};

export default LineChart;
