import BarChart from "components/BarChart";
import React, { useState } from "react";

const DealsDistribution = ({ label, dealsData }) => {
  const [scope, setScope] = useState(0);

  let data = {};
  data.names = dealsData?.names ?? [];
  if (label == "open") {
    data.counts = dealsData?.openDealsCounts ?? [];
    data.values = dealsData?.openDealsValues ?? [];
  } else if (label == "won") {
    data.counts = dealsData?.wonDealsCounts ?? [];
    data.values = dealsData?.wonDealsValues ?? [];
  } else {
    data.counts = [];
    data.values = [];
  }

  return (
    <div className="h-[26rem] flex-column space-y-2 bg-white rounded-xl overflow-hidden border p-4">
      <h1 className="center uppercase font-medium text-sm pb-2 pt-1">
        {`${label} DEALS DISTRIBUTION (IN VALUE & VOLUME)`}
      </h1>
      <div className="h-full flex flex-col space-y-2">
        <div className="h-8 flex items-center justify-center">
          <button
            onClick={() => (scope ? setScope(0) : scope)}
            className={`${
              !scope ? "bg-primary/70" : "bg-[#00000014]"
            } text-xs text-gray-700 py-0.5 px-2 mx-1 uppercase`}
          >
            value
          </button>
          <button
            onClick={() => (scope ? scope : setScope(1))}
            className={`${
              scope ? "bg-primary/70" : "bg-[#00000014]"
            } text-xs text-gray-700 py-0.5 px-2 mx-1 uppercase`}
          >
            volume
          </button>
        </div>
        <div className="h-[calc(100%_-_5rem)] w-full overflow-auto">
          <BarChart
            label={scope ? "Count" : "Amount"}
            xData={data.names}
            yData={scope ? data.counts : data.values}
          />
        </div>
      </div>
    </div>
  );
};

export default DealsDistribution;
