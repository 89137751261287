import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Appointments from "pages/Appointments";
import Contacts from "pages/Contacts";
import ProductsRoutes from "pages/Products/ProductRoutes";
import TeamsRoutes from "pages/Teams/TeamsRoutes";
import SettingsRoutes from "pages/Settings/SettingsRoutes";
import { RequireAuth } from "providers/auth";

function PrivateRoutes() {
  return (
    <RequireAuth>
      <Routes>
        <Route index element={<Navigate to="/dashboard/home" />} />
        {/*<Route path="home/" element={<Home />} />*/}
        <Route path="home/*" element={<ProductsRoutes />} />
        <Route path="teams/*" element={<TeamsRoutes />} />
        <Route path="products/*" element={<ProductsRoutes />} />
        <Route path="contacts/*" element={<Contacts />} />
        <Route path="appointments/*" element={<Appointments />} />
        <Route path="settings/*" element={<SettingsRoutes />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </RequireAuth>
  );
}

export default PrivateRoutes;
