import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { hexToRgb } from "utils";

Chart.register(...registerables);

const BarChart = ({
  label = "Label",
  xData = [],
  yData = [],
  // xData = [
  //   "Meeting",
  //   "Report Review",
  //   "Presentation",
  //   "Task Assignment",
  //   "Training Session",
  //   "Budget Analysis",
  //   "Code Review",
  //   "Project Planning",
  //   "QA Testing",
  //   "Customer Support",
  // ],
  // yData = [15, 67, 82, 29, 53, 41, 18, 74, 62, 37],
}) => {
  const data = {
    labels: xData.map((val) => val.split(" ")),
    datasets: [
      {
        label: label,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, hexToRgb("#AE154B", 0.7));
          gradient.addColorStop(1, hexToRgb("#AE154B", 0.7));
          return gradient;
        },
        data: yData.map((val) => Number(val)),
        borderRadius: 3,
        barPercentage: 0.6,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    barThickness: 40,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          display: true,
        },
      },
    },
  };

  return (
    <div className="h-full w-full overflow-auto p-3">
      <div
        className={`${
          xData.length < 6 ? "w-full" : "w-[56rem]"
        } flex h-full 2xl:w-full`}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
