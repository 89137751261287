import { useState } from "react";
import Modal from "components/Modal";
import Form from "components/Form";
import Select from "components/formControl/Select";
import Input from "components/formControl/input";

function AddTargetForm({ products, target, onSave, onClose }) {
  const [values, setValues] = useState(
    target
      ? {
          ...target,
          name: `${target.firstName} ${target.lastName}`,
        }
      : {}
  );

  return (
    <Modal size="md" open={true} onClose={onClose}>
      <Form
        data={values}
        withWrapper={false}
        buttonText={values.id ? "Edit Target" : "Add Target"}
        onSubmit={(data) => onSave(data)}
      >
        {(formikProps) => (
          <div className="space-y-6 mt-5 mb-7">
            <Select
              label="Product"
              placeholder="Choose Product"
              name="product"
              labelKey="name"
              choices={products ?? []}
              required
            />

            <Input
              label="Count"
              name="count"
              placeholder="e.g. 10"
              type="number"
              required
            />

            <Input
              label="Amount"
              name="amount"
              placeholder="e.g. 1000000"
              type="number"
              required
            />

            {/* <Select
              label="Role"
              placeholder="Choose Role"
              name="role"
              loading={loadingRoles}
              labelKey="name"
              choices={roleChoices ? roleChoices : []}
            /> */}
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default AddTargetForm;
