import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import Input from "../../components/formControl/input";
import { useMutation } from "react-query";
import { resetPassword, signIn } from "api";
import { useAuth } from "providers/auth";
import logo from "assets/absa-logo.png";
import illustration from "assets/illustration.jpg";
import { useState } from "react";

const PasswordInput = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="relative">
      <Input
        className="appearance-none block w-full px-3 py-2 border bg-transparent border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
        name="password"
        type={showPassword ? "text" : "password"}
        required
        autoComplete="current-password"
      />
      <span
        onClick={() => setShowPassword(!showPassword)}
        className="absolute inset-y-2 right-2 opacity-70 p-0.5"
      >
        {showPassword ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        )}
      </span>
    </div>
  );
};

export default function LogIn() {
  // const [showPassword, setShowPassword] = useState(false);
  const [havePassword, setHavePassword] = useState(true);
  const { setAuthUser, setAuthToken } = useAuth();
  const [authError, setAuthError] = useState();
  const navigate = useNavigate();

  // const [notification, setNotification] = useState({ notify: false });
  // useEffect(() => {
  //   setTimeout(() => setNotification({ notify: false }), 1000);
  // }, [notification])

  const { mutate: loginUser } = useMutation("login", signIn, {
    onSuccess: ({ data }) => {
      setAuthUser(data.user);
      setAuthToken(data.token);
      navigate("/", { replace: true });
    },
    onError: ({ response }) => {
      const { data, status } = response;
      if (status === 403 && !data.data.firstTimeLoginFlag) {
        setAuthToken(data.data.token);
        navigate("/change-password", { replace: true });
      } else if (status === 401 && !data.token) {
        setAuthError(data.message);
      }
    },
  });

  const { mutate: resetUserPassword } = useMutation(
    "reset-password",
    resetPassword,
    {
      onSuccess: (data) => {
        console.log("Successful log in:", data);
        // setNotification({
        //   notify: true,
        //   message: "Reset password sent!"
        // })
      },
      onError: (error) => {
        console.log("Error logging in:", error);
        // setNotification({
        //   notify: true,
        //   message: "There is no user with that email address!"
        // })
      },
    },
  );

  async function onSubmit({ email, password }) {
    if (havePassword) {
      setAuthError("");
      loginUser({ email, password });
    }
    if (!havePassword) {
      setHavePassword(true);
      resetUserPassword({ email });
    }
  }

  return (
    <div className="min-h-screen flex pb-12">
      <div className="flex-1 flex flex-col">
        <div className="container mx-auto py-6 px-8">
          <img className="w-14" src={logo} alt="" />
        </div>
        <div className="flex-1 flex flex-col justify-center pb-36">
          <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
            {/* {notification.notify ? <div><p>notification.message</p></div> : ( */}
            <Form
              data={havePassword ? { email: "", password: "" } : { email: "" }}
              onSubmit={onSubmit}
              withWrapper={false}
              buttonText={havePassword ? "Login" : "Reset Password"}
              fullWidthButton={true}
            >
              <h1 className="-mb-1 text-4xl font-medium">
                {havePassword ? "Log In" : "Reset Password"}
              </h1>
              <div>
                <label htmlFor="email" className="block opacity-75">
                  Email
                </label>
                <div className="mt-1">
                  <Input
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="user@absa.com"
                    className="appearance-none block w-full px-3 py-2 border bg-transparent border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                  />
                </div>
                {!havePassword && (
                  <div className="p-1 text-end text-sm text-red-900 cursor-pointer">
                    <p className="">
                      Reset password will be sent to this address.
                    </p>
                  </div>
                )}
              </div>

              {havePassword && (
                <div className="pb-3">
                  <label htmlFor="password" className="block opacity-75">
                    Password
                  </label>
                  <div className="mt-1">
                    <PasswordInput
                    // showPassword={showPassword}
                    // setShowPassword={setShowPassword}
                    />
                  </div>

                  <div className="p-1 text-sm text-end text-red-900">
                    <p className="">
                      Forgot password?
                      <span
                        className="underline cursor-pointer"
                        onClick={() => setHavePassword(false)}
                      >
                        Reset password
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </Form>
            {/* )} */}
            {authError && (
              <p className="text-red-900 mt-5 text-end p-1">{authError}</p>
            )}
            <div className="fixed inset-x-0 bottom-0 py-4 text-center bg-primary text-white text-sm">
              © {new Date().getFullYear()} Uzo by iPF Softwares. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 px-12 w-[400px] flex flex-col justify-center">
        <img
          className="w-full h-56 mb-9 object-top object-cover"
          src={illustration}
          alt=""
        />
        <h3 className="text-2xl mb-1">Uzo Pro Features</h3>
        <ul className="list-disc leading-relaxed pl-5 space-y-2 opacity-50">
          <li>Contact Management</li>
          <li>Customized Reports</li>
          <li>Unlimited Storage</li>
        </ul>
      </div>
    </div>
  );
}
