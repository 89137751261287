import { PlusIcon } from "@heroicons/react/24/outline";
import MemberCard from "./MemberCard";
import { useNavigate } from "react-router-dom";

const Members = ({ members, deals }) => {
  const navigate = useNavigate();

  const getDealsClosedCount = (id) => {
    const memberDeals = deals?.filter((deal) => deal.assigneeId == id);
    return memberDeals?.length;
  };

  const getDealsClosedValue = (id) => {
    const memberDeals = deals?.filter((deal) => deal.assigneeId == id);
    return memberDeals?.reduce((sum, val) => sum + +val.value, 0);
  };

  return (
    <div className="mb-12">
      <div className="flex justify-between items-center pt-6 pb-4">
        <h1 className="text-sm font-semibold">TEAM MEMBERS</h1>
        <button
          onClick={() => navigate("add-member")}
          className="flex items-center px-2 py-2 space-x-1 h-full bg-white shadow rounded"
        >
          <PlusIcon className="h-4 w-4" strokeWidth={2} />
          <span className="text-xs font-semibold pr-1 uppercase">
            Add Member
          </span>
        </button>
      </div>
      <div className="grid grid-cols-4 2xl:grid-cols-4 gap-4 mt-2">
        {members?.map((member) => {
          return (
            <span key={member.id} className="col-span-1">
              <MemberCard
                member={member}
                dealsClosedCount={getDealsClosedCount(member.id)}
                dealsClosedValue={getDealsClosedValue(member.id)}
              />
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Members;
