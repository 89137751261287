import { createContext, useContext, useState } from "react";
import useLocalStorageState from "hooks/useLocalStorageState";
import { useQuery } from "react-query";
import { fetchDeals } from "api";

const AppContext = createContext({});

export function AppProvider({ children }) {
  const [sidebarMinimized, setSidebarMinimized] = useLocalStorageState(
    "minimizeSidebar",
    true,
  );
  const [allDeals, setDeals] = useState({
    success: true,
    message: "",
    data: [],
    count: 0,
  });

  const { isLoading: loadingDeals } = useQuery("deals", fetchDeals, {
    onSuccess: (data) => {
      setDeals(data);
    },
    onError: ({ error }) => {
      console.log(error?.message);
    },
  });

  // FIXME: Move deals state to dashboard context

  const value = {
    sidebarMinimized,
    toggleSidebarMinimized: () => {
      console.log("Sidebar toggled!");
      setSidebarMinimized(!sidebarMinimized);
    },
    loadingDeals,
    allDeals,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppData() {
  return useContext(AppContext);
}
