import {useLayoutEffect, useRef} from "react";
import formatDate from "date-fns/format";
import Loader from "components/Loader";
import ActionButton from "components/Button/ActionButton";

function ListItem({ contact, selected, onClick }) {
    return (
        <button id={`contact${contact.id}`}
            className={`${selected ? 'bg-gray-200 pointer-events-none' : 'hover:bg-gray-50'} w-full flex items-center py-3 px-5`}
            onClick={() => onClick(contact.id)}
        >
            <div className="mr-3 uppercase w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center text-lg">
                {contact.name.split(' ').map(n => n[0]).join('')}
            </div>
            <div className="flex-1 text-left">
                <h3 className="capitalize leading-relaxed text-base font-medium">{contact.name}</h3>
                <p className="text-sm opacity-50">
                    { contact.createdAt ? `Added ${ formatDate(new Date(contact.createdAt), 'MMM dd, yyyy')}` : "--" }
                </p>
            </div>

            <span className="rounded-full py-1.5 px-3 bg-orange-200 text-xs leading-none font-light">
                Lead
            </span>
        </button>
    );
}

function ContactList({ loading = false, selectedContact, contacts = [], onSelect, onAdd }) {
    const contactListRef = useRef(null);

    useLayoutEffect(() => {
        if (!loading && selectedContact && contactListRef?.current && contactListRef.current.scrollTop === 0) {
            const selectedContactNode = contactListRef.current.querySelector(`#contact${selectedContact}`);
            if (selectedContactNode) selectedContactNode.scrollIntoView();
        }
    }, [selectedContact, loading]);

    return (
        <div className="relative overflow-hidden shadow-md flex flex-col flex-shrink-0" style={{ width: "300px" }}>
            <div className="flex-shrink-0 px-3 pt-4 pb-2 border-b">
                <div className="flex items-center justify-between">
                    <h3 className="text-xl font-medium mb-2 px-2">
                        Contacts
                    </h3>

                    <div className="pb-1">
                        <ActionButton onClick={onAdd}>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </ActionButton>
                    </div>
                </div>

                {/* <Tabs /> */}
            </div>
            <div ref={contactListRef} className="flex-1 overflow-y-auto relative">
                {loading && <Loader color="#aaa" thickness="3" />}
                {!loading && contacts?.length > 0 && contacts.map(contact => (
                    <ListItem key={contact.id}
                        selected={selectedContact === contact.id}
                        contact={contact}
                        onClick={onSelect}
                    />
                ))}
            </div>
        </div>
    );
}

export default ContactList;