import { useState } from "react";
import { useDealDeliveriesQuery } from "pages/Products/productQueries";

const getTotal = (rows) => {
  const expectedTotalTurnAroundTime = rows.reduce((total, row) => {
    return total + +row.expTat;
  }, 0);
  const actualTotalTurnAroundTime = rows.reduce((total, row) => {
    return total + +row.tat;
  }, 0);
  const total = actualTotalTurnAroundTime;
  const status =
    actualTotalTurnAroundTime < expectedTotalTurnAroundTime
      ? "In Time"
      : "Delayed";
  return { total, status };
};

const calculateRows = (data) => {
  // const completedDeliveries = data.filter(item => item.entered && item.left);
  const groupedDeliveryData = data.reduce((groupedData, obj) => {
    if (obj.phase.name == "Won" || obj.phase.name == "Lost") {
      return groupedData;
    }
    const phaseName = obj.phase.name;
    if (!groupedData[phaseName]) {
      groupedData[phaseName] = [];
    }
    groupedData[phaseName].push(obj);
    return groupedData;
  }, {});
  let rows = [];
  Object.keys(groupedDeliveryData).forEach((stageName) => {
    const phaseTatData = groupedDeliveryData[stageName];
    for (const item of phaseTatData) {
      const enteredTime = new Date(item.entered);
      const leftTime = item?.left ? new Date(item.left) : new Date();
      const tat = leftTime - enteredTime;
      const expTat = +item.phase.time * 24 * 3600000;
      const status = tat < expTat ? "In Time" : "Delayed";
      rows.push({ stageName, expTat, tat, status });
    }
  });
  return rows;
};

function formatTime(milliseconds) {
  if (!milliseconds) return "";

  const totalSeconds = Math.round(milliseconds / 1000);

  const days = Math.floor(totalSeconds / 86400);
  const remainingSeconds = totalSeconds % 86400;

  const hoursFormatted = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const secondsFormatted = remainingSeconds % 60;

  const formattedDays = days.toString().padStart(2, "0");
  const formattedHours = hoursFormatted.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = secondsFormatted.toString().padStart(2, "0");

  return `${formattedDays} : ${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
}

function TurnaroundTime({ dealId }) {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState({});

  const { data: dealDeliveryData } = useDealDeliveriesQuery(dealId, {
    onSuccess: (data) => {
      setRows([]);
      setTotal({});
      if (!data.length) return;
      const rows = calculateRows(data);
      if (rows.length) {
        setRows(rows);
        setTotal(getTotal(rows));
      }
    },
  });

  return (
    <table className="table-auto w-full text-left divide-y-[16px] divide-transparent">
      <tr className="">
        <td className="text-start">
          <p className="uppercase text-[0.75rem] text-gray-600 font-medium pl-2 py-2">
            Stage
          </p>
        </td>
        <th className="text-center">
          <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
            TAT (dd:hh:mm:ss)
          </p>
        </th>
        <th className="text-center">
          <p className="uppercase text-[0.75rem] text-gray-600 font-medium">
            Status
          </p>
        </th>
      </tr>
      <tbody>
        {rows?.map((row, index) => (
          <tr key={index} className="odd:bg-slate-50 hover:bg-primary-50">
            <td className="text-start">
              <p className="text-sm pl-2 py-2 capitalize">{row.stageName}</p>
            </td>
            <td className="text-center">
              <p className="text-sm">{formatTime(row.tat)}</p>
            </td>
            <td className="text-center">
              <p
                className={`text-sm py-3 pl-3 ${
                  row.status == "In Time"
                    ? "text-green-400"
                    : row.status == "Delayed"
                    ? "text-red-400"
                    : ""
                }`}
              >
                {row.status}
              </p>
            </td>
          </tr>
        ))}
        <tr key={"total"} className="odd:bg-slate-100 hover:bg-primary-50">
          <td className="text-start">
            <p className="text-[0.825rem] font-medium uppercase pl-2 py-4">
              total
            </p>
          </td>
          <td className="text-center">
            <p className="text-sm font-medium">{formatTime(total?.total)}</p>
          </td>
          <td className="text-center">
            <p
              className={`text-sm font-medium py-3 pl-3 ${
                total?.status === "In Time"
                  ? "text-green-400"
                  : total?.status === "Delayed"
                  ? "text-red-400"
                  : ""
              }`}
            >
              {total?.status}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TurnaroundTime;
