import {
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import { shortenLargeNumber } from "utils";
import HighlightsCardBar from "./HightlightCardBar";

const HighlightsCard = ({ winRate, avgDealCycle, avgDealSize }) => {
  const winRateFormatted = (winRate ? winRate.toFixed() : 0) + "%";
  const avgDealSizeFormatted =
    "TZS " + (avgDealSize ? shortenLargeNumber(avgDealSize) : 0);
  const avgDealCycleFormatted =
    (avgDealCycle ? (avgDealCycle / (24 * 3600000)).toFixed() : 0) + " DAYS";

  return (
    <div className="h-64 w-full flex flex-col bg-white border shadow p-4 rounded-xl">
      <div className="h-full flex flex-col space-y-4 p-2 rounded-md">
        <h1 className="uppercase text-sm font-semibold text-gray-700">
          Highlights
        </h1>
        <div className="flex flex-col space-y-6 text-gray-600">
          <HighlightsCardBar
            Icon={ArrowTrendingUpIcon}
            label={"Win Rate"}
            value={winRateFormatted}
          />
          <HighlightsCardBar
            Icon={BriefcaseIcon}
            label={"Avg. Deal Size"}
            value={avgDealSizeFormatted}
          />
          <HighlightsCardBar
            Icon={ArrowPathIcon}
            label={"Avg. Deal Cycle"}
            value={avgDealCycleFormatted}
          />
        </div>
      </div>
    </div>
  );
};

export default HighlightsCard;
