import { useMutation, useQuery } from "react-query";

import { useRefreshCache } from "hooks/useManageCache";
import {
  addUser,
  getUser,
  getUsers,
  removeUser,
  updateUser,
} from "api/endpoints/users";

const userQueryKeys = {
  users: ["users"],
  user: (userId) => ["users", userId],
};

export const useUsers = (options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
  };

  return useQuery(userQueryKeys.users, getUsers, queryOptions);
};

export const useUser = (userId, options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
    enabled: !!userId,
  };

  return useQuery(
    userQueryKeys.user(userId),
    () => getUser(userId),
    queryOptions,
  );
};

export const useAddUser = (options) => {
  const refreshCache = useRefreshCache();

  const handleOnSuccess = async (data) => {
    await refreshCache(userQueryKeys.users, true);
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleOnSuccess,
  };

  return useMutation(["add-user"], addUser, mutationOptions);
};

export const useUpdateUser = (options) => {
  const refreshCache = useRefreshCache();

  const handleSuccess = async (data) => {
    await refreshCache(userQueryKeys.users, true);
    if (options?.onSuccess) {
      options.onSuccess(data);
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleSuccess,
  };

  return useMutation(["update-user"], updateUser, mutationOptions);
};

export const useRemoveUser = (options) => {
  const refreshCache = useRefreshCache();

  const handleSuccess = async () => {
    await refreshCache(userQueryKeys.users, true);
    if (options?.onSuccess) {
      options.onSuccess();
    }
  };

  const mutationOptions = {
    ...options,
    onSuccess: handleSuccess,
  };

  return useMutation(["remove-user"], removeUser, mutationOptions);
};
