import formatDate from "date-fns/format";
import { useState } from "react";

export function ActivityComment({ reference }) {
  const { body } = reference || {};
  return (
    <div className="bg-white shadow border border-gray-100 rounded-lg p-2.5">
      <div className="border-l-2 pl-3 text-sm leading-relaxed">
        <span dangerouslySetInnerHTML={{ __html: body }}></span>
      </div>
    </div>
  );
}

export function ActivityAppointment({ reference }) {
  const { agenda, contact, date, startTime, endTime, location, notes } =
    reference || {};
  const [showNotes, setShowNotes] = useState(false);
  return (
    <div className="bg-white shadow border border-gray-100 rounded-lg p-2.5">
      <div className="flex items-center">
        <div className="leading-none uppercase flex flex-col items-center justify-center w-12s sh-12 py-1.5 px-3 rounded flex-shrink-0 bg-gray-100 border border-gray-400/50">
          <span
            className="mb-px pb-px font-lights leading-none opacity-70 tracking-widest"
            style={{ fontSize: "10px" }}
          >
            {date && formatDate(new Date(date), "MMM")}
          </span>
          <span className="pt-px font-semibold text-lg leading-none">
            {date && formatDate(new Date(date), "dd")}
          </span>
        </div>
        <div className="pb-0.5 flex-1 px-2 min-w-0" style={{ minWidth: 0 }}>
          <h5 className="text-sm pb-px mb-1 opacity-70 font-medium truncate">
            {agenda}
          </h5>
          <p className="flex mb-px text-xs font-light opacity-70 leading-none">
            {startTime} - {endTime} &nbsp;
            <span className="font-bold">&middot;</span> &nbsp;&nbsp;
            {contact?.name}&nbsp;&nbsp;
            <span className="font-bold">&middot;</span> &nbsp;&nbsp;
            <span className="truncate flex-1">
              {location}
              {/*location && JSON.parse(location).displayName*/}
            </span>
          </p>
        </div>
      </div>

      {showNotes && (
        <div className="mt-3 border-l-2 bg-gray-50 py-1.5 pl-3 pr-2 text-sm leading-relaxed">
          <span
            className="font-light"
            dangerouslySetInnerHTML={{ __html: notes }}
          ></span>
        </div>
      )}

      {notes && notes.length && (
        <div className="mt-1">
          <button
            className="text-xs text-primary"
            onClick={() => setShowNotes(!showNotes)}
          >
            {showNotes ? "Hide" : "Show"} meeting notes
          </button>
        </div>
      )}
    </div>
  );
}
