import BarChart from "components/BarChart";

const DealsProgressionChart = ({ stagesWithCounts }) => {
  const xData = stagesWithCounts?.map((stage) => stage.name);
  const yData = stagesWithCounts?.map((stage) => stage.count);
  return (
    <div className="h-96 flex flex-col space-y-2 p-4 rounded-xl overflow-hidden bg-white border ">
      <h1 className="center uppercase font-medium text-sm pb-2 pt-1">
        STAGE-BASED DEALS DISTRIBUTION
      </h1>
      <div className="h-full">
        <BarChart label="Deals" xData={xData} yData={yData} />
      </div>
    </div>
  );
};

export default DealsProgressionChart;
