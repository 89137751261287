import LineChart from "./LineChart";

const ActivityCard = ({ monthlyDealsCounts = [] }) => {
  return (
    <div className="bg-white rounded-xl p-4">
      <div className="pb-3">
        <h1 className="text-xs text-gray-700 uppercase font-semibold pb-1">
          activity
        </h1>
        <p className={"text-[0.825rem] text-gray-500"}>
          Deals addition activity
        </p>
      </div>
      <div className="py-2">
        <LineChart
          label={"Deals"}
          labels={monthlyDealsCounts.map((obj) => obj.name)}
          data={monthlyDealsCounts.map((obj) => obj.count)}
        />
      </div>
    </div>
  );
};

export default ActivityCard;
