import { useEffect, useState } from "react";

import { useTeamsQuery } from "pages/Teams/TeamsQueries";
import { shortenLargeNumber } from "utils";

const getPerformance = (teams, deals, productId) => {
  if (!teams?.length || !deals || !productId) return [];

  const allTargets = teams.reduce((allTargets, team) => {
    const teamTargets = team.member.reduce((targets, member) => {
      const memberTargets = [...member.targets];
      const currentProductTargets = memberTargets.filter(
        (target) => target.productId === productId,
      );
      targets.push(...currentProductTargets);
      return targets;
    }, []);
    allTargets.push(...teamTargets);
    return allTargets;
  }, []);

  const volumeTarget = allTargets.reduce((total, target) => {
    return total + +target.volume;
  }, 0);
  const valueTarget = allTargets.reduce((total, target) => {
    return total + +target.value;
  }, 0);

  const dealsCount = deals.length;
  const dealsValue = deals.reduce((total, deal) => {
    return total + +deal.value;
  }, 0);

  return {
    volume: dealsCount,
    value: dealsValue,
    volumeTarget: volumeTarget,
    valueTarget: valueTarget,
  };
};

export default function PerformanceCard({ deals, productId }) {
  const [performance, setPerformance] = useState(null);

  useTeamsQuery({
    onSuccess: (data) => {
      setPerformance(getPerformance(data, deals, productId));
    },
  });

  const performanceList = [
    {
      type: "volume",
      value: performance?.volume,
      target: performance?.volumeTarget,
    },
    {
      type: "value",
      value: performance?.value,
      target: performance?.valueTarget,
    },
  ];

  return (
    <div className="bg-white w-full p-6 h-full rounded-xl">
      <div className="h-full flex flex-col space-y-6 rounded-md">
        <h1 className="uppercase text-xs font-semibold text-gray-700">
          performance
        </h1>
        {performance === null ? (
          <div className={""}>loading</div>
        ) : (
          <div className="flex flex-col space-y-4">
            {performanceList.map((performance) => (
              <PerformanceBar
                key={performance.type}
                label={performance.type}
                value={performance.value}
                target={performance.target}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const PerformanceBar = ({ label, value, target }) => {
  const [width, setWidth] = useState("0%");
  const percentage = +target ? ((+value * 100) / +target).toFixed() : 0;

  useEffect(() => {
    setWidth(`${percentage < 100 ? percentage : 100}%`);
  }, [percentage]);

  return (
    <div className="flex-column space-y-2">
      <div className="flex items-center justify-between">
        <div className="">
          <span className="text-[0.825rem] capitalize">{`${label}:`}</span>
          <span className="text-[0.825rem] text-gray-700 font-medium pl-2">
            {shortenLargeNumber(+value)}
          </span>
          <span className="text-[0.825rem] text-gray-700 px-2">/</span>
          <span className="text-[0.825rem] text-gray-700 font-medium">
            {shortenLargeNumber(+target)}
          </span>
        </div>
        <span className="px-2 text-[0.825rem] text-gray-700 font-medium">{`${percentage}%`}</span>
      </div>
      <div className="h-3 bg-primary/10 rounded">
        <div className="">
          <div
            style={{ width: width }}
            className={`h-3 bg-primary rounded flex items-center transition-all ease-in-out duration-1000 ${
              percentage < 26 ? "justify-start" : "justify-end"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};
