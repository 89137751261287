import { Link, Outlet } from "react-router-dom";
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Button } from "components/form-control";
import { useRemoveUser, useUsers } from "pages/Settings/Users/userQueries";

const tableColumnTitles = [
  "Full Name",
  "Email Address",
  "Phone Number",
  "Role",
  "Team",
  "",
];

const Users = () => {
  const { data: users } = useUsers();

  const { mutate: removeUserMutation } = useRemoveUser();

  const handleRemoveUser = (userId) => removeUserMutation(userId);

  return (
    <div className="px-6 py-4">
      <Outlet />

      <div className="flex items-center justify-end mb-1">
        <Link to="add">
          <Button
            type={"button"}
            className={
              "flex items-center space-x-1 border-[1px] border-primary text-primary py-[0.36rem]"
            }
            outline
          >
            <PlusIcon className={"h-4 w-4"} strokeWidth={2} />
            <span className="text-sm capitalize">add user</span>
          </Button>
        </Link>
      </div>

      <div className="py-2 align-middle inline-block min-w-full">
        <div className="bg-white shadow border-b border-gray-200 rounded overflow-hidden">
          <table className="min-w-full divide-y divide-primary/5">
            <thead className="text-gray-600">
              <tr>
                {tableColumnTitles.map((header, index) => (
                  <th
                    scope="col"
                    className={`text-gray-500 text-xs ${index ? "text-center" : "text-left"} font-medium uppercase tracking-wider px-6 py-3 border-b-[1px] border-gray-300`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {users
                ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map((user, index) => (
                  <tr key={index} className="bg-white relative odd:bg-gray-50">
                    <td className="text-[0.84rem] text-gray-800 px-6 py-4 whitespace-nowrap capitalize">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {user?.email ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {user?.phoneNumber ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {user?.role?.name ?? "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-[0.835rem] text-gray-700">
                      {(user?.team?.length && user.team[0].name) || "N/A"}
                    </td>
                    <td className="flex center space-x-4 px-6 py-4 whitespace-nowrap text-[0.835rem]">
                      <Link to={`${user.id}/edit`}>
                        <button className="bg-gray-200/60 text-primary-400 flex items-center space-x-0.5 py-1 px-1.5 hover:bg-primary-50 rounded">
                          <PencilSquareIcon className={"h-4 w-4"} />
                        </button>
                      </Link>
                      <button
                        onClick={() => handleRemoveUser(user?.id)}
                        className="hidden bg-gray-200/60 text-primary flex items-center space-x-0.5 py-1 px-1.5 hover:bg-primary-50 rounded"
                      >
                        <TrashIcon className={"h-4 w-4"} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;
