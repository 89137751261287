import Loader from "components/Loader";
import { useState } from "react";
import AddComment from "./AddComment";
import CommentsList from "./CommentsList";
import DealActivityEntry from "./DealActivityEntry";
import DealTabs from "./DealTabs";
import Summary from "./Summary";
import {
  BoltIcon,
  BriefcaseIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import {
  useAddCommentMutation,
  useGetDealActivitiesQuery,
  useGetDealCommentsQuery,
} from "pages/Products/productQueries";

const tabs = [
  {
    Icon: BriefcaseIcon,
    name: "Summary",
  },
  {
    Icon: BoltIcon,
    name: "Activities",
  },
  {
    Icon: DocumentTextIcon,
    name: "Comments",
  },
];

const Details = ({ deal }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { data: activities } = useGetDealActivitiesQuery(deal?.id);

  const { data: comments, isLoading: isLoadingComments } =
    useGetDealCommentsQuery(deal?.id);

  const { mutate: addComment } = useAddCommentMutation(deal?.id);

  const handleAddComment = (comment) => {
    addComment({
      ...comment,
      reference: deal?.id,
    });
  };

  const handleTabClick = (index) => setActiveTab(index);

  return (
    <div className={"h-full"}>
      <DealTabs tabs={tabs} activeTab={activeTab} onClick={handleTabClick} />
      <div className="h-[calc(100%_-_2.5rem)] py-2 pr-2 overflow-y-auto">
        {activeTab === 0 ? (
          <Summary deal={deal} />
        ) : activeTab === 1 ? (
          <div className={"pt-4"}>
            {activities.length &&
              activities
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((activity, index) => (
                  <DealActivityEntry
                    key={index}
                    activity={activity}
                    lastChild={index === activities?.length - 1}
                  />
                ))}
          </div>
        ) : activeTab === 2 ? (
          comments &&
          (!isLoadingComments ? (
            <div className="pr-1 pt-5">
              <div className="w-full">
                <AddComment
                  onSave={handleAddComment}
                  isLoading={isLoadingComments}
                />
              </div>
              <CommentsList comments={comments} />
            </div>
          ) : (
            <Loader />
          ))
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Details;
