import { get, post, put, remove } from "api/request";

export async function getRoles() {
  return await get("/access-control/roles");
}

export async function getAllPermissions() {
  return await get("/access-control/permissions");
}

export async function getRoleById(roleId) {
  return await get(`/access-control/roles/${roleId}`);
}

export async function updateRolePermissions({ roleId, ...rest }) {
  return await put(`/access-control/roles/${roleId}/permissions`, rest);
}

export async function addRole(roleDetails) {
  return await post("/access-control/roles", roleDetails);
}

export async function updateRole({ roleId, ...rest }) {
  return await put(`/access-control/roles/${roleId}`, rest);
}

export async function removeRole(roleId) {
  return await remove(`/access-control/roles/${roleId}`);
}
