const CommentsList = ({ comments }) => {
  return (
    <div className="bg-gray-100 flex flex-col rounded-b-md px-3">
      {comments.length ? (
        comments
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(({ createdAt, body, actor }, index) => (
            <div
              key={`comment-${index + 1}`}
              className="flex flex-col items-start py-3 w-full"
            >
              <div className="bg-gray-200 rounded-xl p-4 w-full text-gray-800">
                {body}
              </div>
              <span className="w-full text-end text-xs text-gray-400 pr-2">
                {new Date(createdAt).toLocaleString()}
              </span>
              <div className="flex items-center justify-end w-full space-x-1 text-gray-500 pt-1 pr-2">
                <span className="capitalize text-[0.92rem]">
                  {`${actor?.lastName + " " + actor?.firstName[0]}.`}
                </span>
                <div className="flex items-center justify-center bg-primary rounded-full text-sm text-white uppercase h-8 w-8">
                  {`${actor?.lastName[0]}`}
                </div>
              </div>
            </div>
          ))
      ) : (
        <p className={"text-sm text-gray-600 center py-4"}>
          No comment now! Add a comment above
        </p>
      )}
    </div>
  );
};

export default CommentsList;
