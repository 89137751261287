import Input from "./input";

export default function TextArea({rows = 3, ...props}){
    return (
        <Input 
            {...props} 
            as="textarea" 
            rows={rows} 
        />
    );
}