import {
  BanknotesIcon,
  CalendarIcon,
  ClockIcon,
  CreditCardIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import TurnaroundTime from "./TurnaroundTime";
import { simplifyTime } from "utils";

const Summary = ({ deal }) => {
  return (
    <div className="h-full">
      <SummaryBody deal={deal} />
      <div className={""}>
        <h3
          className={
            "text-gray-500 text-[0.95rem] border-b-[0.1rem] border-gray-200 pt-2 pb-3"
          }
        >
          Turnaround Time
        </h3>
        {deal && <TurnaroundTime dealId={deal?.id} />}
      </div>
    </div>
  );
};

const SummaryBody = ({ deal }) => {
  const timeDiff = new Date(deal?.expectedClosingDate) - new Date();
  return (
    <div className={" grid grid-cols-2 px-2 py-4"}>
      <SummaryItem
        key={1}
        Icon={BanknotesIcon}
        name={"Value"}
        value={`${deal?.currency} ${(+deal?.value).toLocaleString("en-US")}`}
      />
      <SummaryItem
        key={2}
        Icon={CreditCardIcon}
        name={`Price (${deal?.priceType?.toUpperCase()})`}
        value={
          deal?.priceType === "interest rate"
            ? `${deal?.price}%`
            : `${deal?.currency} ${(+deal?.price).toLocaleString("en-US")}`
        }
      />
      <SummaryItem
        key={3}
        Icon={UserIcon}
        name={"Assignee"}
        value={
          deal?.assignee
            ? deal.assignee.firstName + " " + deal.assignee.lastName
            : ""
        }
      />
      <SummaryItem
        key={4}
        Icon={CalendarIcon}
        name={"Expected Closing Date"}
        value={new Date(deal?.expectedClosingDate).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
      />
      <SummaryItem
        key={5}
        Icon={ClockIcon}
        name={`Time ${timeDiff < 0 ? "exceeded" : "left"}`}
        value={simplifyTime(Math.abs(timeDiff))}
      />
    </div>
  );
};

const SummaryItem = ({ Icon, name, value }) => {
  return (
    <div className={"flex items-center space-x-3 p-2"}>
      <span className="bg-primary/10 p-2 rounded-full">
        <Icon className={"h-5 w-5 text-primary-800"} />
      </span>
      <div className="flex flex-col">
        <span className="text-sm text-gray-500">{name}</span>
        <span className="text-sm text-gray-700">{value}</span>
      </div>
    </div>
  );
};

export default Summary;
