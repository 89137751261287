import Loader from "../Loader";

export default function Button({
    children, type = "button", 
    disabled = false,
    loading = false,
    className="",
    onClick= () => {}
}) {
    return (
        <button
            type={type}
            className={
                `${disabled || loading && 'pointer-events-none'} ${disabled && 'opacity-50'} 
                bg-primary text-white text-sm uppercase tracking-wide flex items-center justify-center px-5 py-3 leading-none rounded relative
                ${className}
            `}
            onClick={onClick}
        >
            {children}

            { loading && <Loader size={26} thickness={8} color="#888" /> }
        </button>
    );
}