import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import AddContact from "../AddContact";
import DeleteContact from "../DeleteContact";
import ContactActivities from "../ContactActivities";
import SetAppointment from "../SetAppointment";

function ProfileButton({ children, text, onClick = () => {} }) {
  return (
    <button
      className="flex flex-col items-center justify-center"
      onClick={onClick}
    >
      <span className="bg-gray-200 hover:bg-gray-300 text-gray-500 rounded-full w-11 h-11 flex items-center justify-center mb-1.5">
        {children}
      </span>
      <span className="text-xs">{text}</span>
    </button>
  );
}

function ContactDetails({
  contacts,
  setSelectedContact = () => {},
  onContactSaved = () => {},
}) {
  const [editContact, setEditContact] = useState(false);
  const [addAppointment, setAddAppointment] = useState(false);
  const [deleteContact, setDeleteContact] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.contactId) setSelectedContact(params.contactId);

    return () => setSelectedContact(null);
  }, [params.contactId]);

  useEffect(() => {
    if (params.contactId && contacts && !contacts.length)
      setSelectedContact(null);
  }, [contacts]);

  // const { isLoading, error, mutate, data } = useQuery('contacts', fetchContacts);
  if (!contacts || !contacts.length || !params?.contactId)
    return <Loader color="#aaa" thickness="3" />;

  const contact = contacts.find(({ id }) => id === params.contactId);

  if (!contact) return <p>Contact not found!</p>;

  function handleViewMore() {
    navigate("more");
  }

  return (
    <div className="flex h-full overflow-hidden">
      <div className="flex-1 relative overflow-hidden flex flex-col">
        <div className="flex-shrink-0">
          {/* <div className="mb-6">
                        <ActionButton onClick={() => setSelectedContact(null)}>
                            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </ActionButton>
                    </div> */}

          <div className="p-6 border-b">
            <div className="flex items-center max-w-5xl mx-auto">
              <div className="uppercase w-20 h-20 rounded-full bg-white border-2 border-black/20 flex items-center justify-center text-2xl tracking-wide">
                {contact.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </div>

              <div className="ml-5 flex-1">
                <h3 className="mb-2 text-2xl font-semibold capitalize">
                  {contact.name}
                </h3>
                <p className="text-base font-light">{contact.phone}</p>
              </div>
              {/* <Button
                className="bg-gray-200/0 border border-primary text-primary py-1.5 pl-3 mr-4 pr-4 flex items-center capitalize text-base font-medium"
                onClick={() => setAddAppointment(true)}
              >
                <svg 
                                  className="w-4 h-4 my-0.5 mr-3"

                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 448 512"> 
                fill="currentColor"
                <path d="M216.1 408.1C207.6 418.3 192.4 418.3 183 408.1L119 344.1C109.7 335.6 109.7 320.4 119 311C128.4 301.7 143.6 301.7 152.1 311L200 358.1L295 263C304.4 253.7 319.6 253.7 328.1 263C338.3 272.4 338.3 287.6 328.1 296.1L216.1 408.1zM128 0C141.3 0 152 10.75 152 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0zM400 192H48V448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192z"/></svg>
                Set Appointment 
              </Button> */}

              {/* <Button
                className="bg-gray-200/0 border border-primary text-primary py-1.5 pl-3 mr-4 pr-4 flex items-center capitalize text-base font-medium"
                onClick={() => setEditContact(true)}
              >
                <svg
                  className="w-4 h-4 my-0.5 mr-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                Edit
              </Button> */}

              {/* <Button
                className="bg-gray-200/0 border border-primary text-primary py-1.5 pl-3 pr-4 flex items-center capitalize text-base font-medium"
                onClick={() => setDeleteContact(true)}
              >
                <svg 
                  className="w-4 h-4 my-0.5 mr-3"
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 448 512">
                    <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"/>
                </svg>
                Delete
              </Button> */}
            </div>
          </div>

          {/* <div className="flex space-x-4 items-center justify-center">
                        <ProfileButton text="Text">
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                            </svg>
                        </ProfileButton>
                        <ProfileButton text="Email">
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        </ProfileButton>
                        <ProfileButton text="Tag">
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                            </svg>
                        </ProfileButton>
                        <ProfileButton text="Note">
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </ProfileButton>
                        <ProfileButton text="Task">
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                            </svg>
                        </ProfileButton>
                        <ProfileButton text="More"
                            onClick={handleViewMore}
                        >
                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                            </svg>
                        </ProfileButton>
                    </div> */}
        </div>
        <div className="flex-1 overflow-y-auto">
          <ContactActivities contact={contact} />
          {/* <div className=""></div> */}
        </div>
      </div>

      {/* <div className="flex-1 relative overflow-hidden border-l bg-gray-50">
                <Routes>
                    <Route path="more" element={<MoreDetails contact={contact} onContactSaved={onContactSaved} />} />
                </Routes>
            </div> */}

      {editContact && (
        <AddContact
          contact={contact}
          onSave={(data) => {
            onContactSaved(data);
            setEditContact(false);
          }}
          onClose={() => setEditContact(false)}
        />
      )}

      {addAppointment && (
        <SetAppointment
          contact={contact}
          onSave={(data) => {
            onContactSaved(data);
            setAddAppointment(false);
          }}
          onClose={() => setAddAppointment(false)}
        />
      )}

      {deleteContact && (
        <DeleteContact
          contact={contact}
          onSave={(data) => {
            onContactSaved(data);
            setDeleteContact(false);
          }}
          onClose={() => setDeleteContact(false)}
        />
      )}
    </div>
  );
}

export default ContactDetails;
