import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "components/form-control/button";

const DealSlideHeader = ({ title, onClose }) => {
  return (
    <header
      className={
        "bg-white items-between font-medium text-gray-700 sticky top-0 z-20 px-4 py-2"
      }
    >
      <p className={"text-[0.9rem] uppercase"}>{title}</p>
      <Button
        onClick={onClose}
        className={
          "h-8 w-8 bg-transparent active:ring-black/70 focus:ring-black/70 focus:bg-gray-200 focus:text-gray-600 hover:text-gray-600 hover:bg-gray-200 text-gray-400 p-0 center"
        }
      >
        <XMarkIcon className={"h-5 w-5"} />
      </Button>
    </header>
  );
};

export default DealSlideHeader;
