import { get } from "api/request";
import { generateQueryParams } from "hooks/useURLParamsState";

export async function getProductsSummaries() {
  return await get("/products/summaries");
}

export async function getProductDealsInPhases(filters) {
  const { productId, ...rest } = filters;
  return await get(`/products/${productId}/deals?${generateQueryParams(rest)}`);
}

export async function getProductStats(filters) {
  const { productId, ...rest } = filters;
  return await get(
    `/products/${productId}/analytics?${generateQueryParams(rest)}`,
  );
}

export async function getProduct(productId) {
  return await get(`/products/${productId}`);
}
