function ActionButton({
    children,
    onClick = () => {}
}) {
    return (
        <button className="hover:bg-gray-100 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={onClick}
        >
            { children }
        </button>
    );
}

export default ActionButton;