import {useCallback, useEffect, useRef} from "react";

const useKeyboardEventListener = (callback, key, ref) => {
    if (!key) {
        throw new Error("Invalid key")
    }

    if (typeof callback !== "function") {
        throw new Error("Invalid callback");
    }

    const domNodeRef = useRef(null);
    const callBackRef = useRef(callback);

    const handleKeyDown = useCallback((event) => {
        if (!domNodeRef.current) return;
        if (event.key.toLowerCase() === key.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            callBackRef.current()
        }
    }, [callback])

    useEffect(() => {
        domNodeRef.current = ref?.current ?? window;
        if (domNodeRef.current) {
            domNodeRef.current.addEventListener("keydown", handleKeyDown)
        }
        return () => {
            domNodeRef?.current?.removeEventListener("keydown", handleKeyDown)
        }
    }, [domNodeRef.current]);
}

export default useKeyboardEventListener;