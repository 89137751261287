import { capitalizeString } from "utils";

export const dealsSheetColumns = [
  "SN",
  "Name",
  "Currency",
  "Value",
  "Pricing",
  "Price",
  // "Assignee",
  "Current Stage",
  "Closed At",
  "Created At",
  "Expected To Close At",
];

export const getDealsSheetRows = (deals) => {
  const dateOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return deals.map((deal, index) => {
    return {
      SN: index + 1,
      Name: deal?.name ? deal.name.toUpperCase() : "N/A",
      Currency: deal?.currency ?? "N/A",
      Value: deal?.value
        ? deal.currency + " " + parseFloat(deal.value).toLocaleString()
        : "N/A",
      Pricing: deal?.priceType ? capitalizeString(deal.priceType) : "N/A",
      Price: deal?.price
        ? deal.priceType === "fees"
          ? deal.currency + " " + parseFloat(deal.price).toLocaleString()
          : deal.price + "%"
        : "N/A",
      // Assignee: deal?.assignee
      //   ? deal.assignee.firstName + " " + deal.assignee.lastName
      //   : "N/A",
      "Current Stage": deal?.currentPhase ?? "N/A",
      "Closed At": deal?.dateClosed
        ? new Date(deal.dateClosed).toLocaleString("en-US", dateOptions)
        : "N/A",
      "Created At": deal?.createdAt
        ? new Date(deal.createdAt).toLocaleString("en-US", dateOptions)
        : "N/A",
      "Expected Closing Date": deal?.expectedClosingDate
        ? new Date(deal.expectedClosingDate).toLocaleString(
            "en-US",
            dateOptions,
          )
        : "N/A",
    };
  });
};
