import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export const saveExcelFile = (fileName, workbook) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  return FileSaver.saveAs(data, fileName + fileExtension);
};

const useExportJSONAsXLSX = () => {
  return ({ data, dataTitle, headers, sheetName, fileName }) => {
    if (!isArrayOfArrays(data) && !isArrayOfObjects(data)) {
      throw new Error(
        "data property should be either array of array or array of object",
      );
    }

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);

    if (dataTitle) {
      // merge cell from A1 to J1 for appending title on it
      const mergeRange = "A1:G1";

      if (!worksheet["!merges"]) {
        worksheet["!merges"] = [];
      }

      const mergeCellRange = XLSX.utils.decode_range(mergeRange);
      worksheet["!merges"].push(mergeCellRange);

      // append data title to the sheet
      const cellAddress = XLSX.utils.encode_cell({
        r: mergeCellRange.s.r,
        c: mergeCellRange.s.c,
      });

      worksheet[cellAddress] = {
        t: "s",
        v: dataTitle,
      };
    }

    // protect a sheet from being edited
    // worksheet["!protect"] = {password: EXCEL_SHEET_PASSWORD};

    if (headers) {
      // append data headers to the sheet
      XLSX.utils.sheet_add_aoa(worksheet, headers, {
        origin: dataTitle ? "A2" : "A1",
      });
    }

    const options = {
      origin: dataTitle && headers ? "A3" : dataTitle || headers ? "A2" : "A1",
      skipHeader: !!headers,
    };
    // append data to the sheet

    if (isArrayOfObjects(data)) {
      XLSX.utils.sheet_add_json(worksheet, data, options);
    } else {
      XLSX.utils.sheet_add_aoa(worksheet, data, options);
    }

    // append worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // download created workbook 🎉😀
    saveExcelFile(fileName, workbook);
  };
};

function isArrayOfArrays(data) {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.every((item) => Array.isArray(item));
}

function isArrayOfObjects(data) {
  if (!Array.isArray(data)) {
    return false;
  }
  return data.every(
    (item) => typeof item === "object" && item !== null && !Array.isArray(item),
  );
}

export default useExportJSONAsXLSX;
