import { Field, useFormikContext } from "formik";
import React from "react";
import { getNestedObjectValue } from "../../utils";
import Label from "./label";

import { useField } from "formik";
import DatePicker from "react-datepicker";

export const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            className="bg-[#F0F2F7] w-full rounded-md px-3 py-2 border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1 focus:ring-primary/70"
            name={field.name}
            autoComplete='false'
            dateFormat="dd/MM/yyyy"
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val.toDateString());
            }}
        />
    );
};

export default function Input({ label, name, fullWidth, className, ...inputProps }) {
    const { values, setFieldValue } = useFormikContext();
    const computedValue = getNestedObjectValue(values, name);
    const [field] = useField(name);

    const handleChange = (event) => {
        const { value } = event.target;
        setFieldValue(name, value);
    };

    return (
        <div className={`flex flex-col ${fullWidth && 'w-full'}`}>
            {label && label.length && <Label>{label}</Label>}

            <Field
                className={`
          bg-[#F0F2F7] w-full rounded-md px-3 py-2 border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1 focus:ring-primary/70
          ${className}
        `}
                value={computedValue || ''}
                onChange={handleChange} //
                name={name}
                {...field}
                {...inputProps}
            />
        </div>
    );
}
