import { useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineDatabase } from "react-icons/ai";
import { generateQueryParams } from "hooks/useURLParamsState";

const RecentDeals = ({ deals }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const paramsObject = {};
  for (const [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const handleClick = () => {
    navigate(`/dashboard/home/deals?${generateQueryParams(paramsObject)}`);
  };

  return (
    <div className={"bg-white rounded-xl p-4"}>
      <div className="flex items-center justify-between pb-3 border-b-[1px]">
        <div className="">
          <h1 className="text-xs text-gray-700 uppercase font-semibold pb-1">
            deals
          </h1>
          <p className={"text-[0.825rem] text-gray-500"}>Most recent deals</p>
        </div>
        <button
          onClick={handleClick}
          className="bg-primary flex items-center rounded-lg text-sm px-3 py-1.5 hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-primary/50 focus:outline-none active:outline-none"
        >
          <AiOutlineDatabase className={"h-[1rem] w-[1rem] text-white"} />
          <span className={"px-1 text-sm text-white"}>All Deals</span>
        </button>
      </div>
      {1 ? (
        <table className="table-auto w-full text-left divide-y-[16px] divide-transparent">
          <tr className="">
            <td className="text-start">
              <p className="text-[0.85rem] font-medium opacity-60 capitalize pt-3 pb-2 pl-2">
                deal
              </p>
            </td>
            {/*<th className="text-center">*/}
            {/*  <p className="text-[0.85rem] font-medium opacity-60 capitalize">*/}
            {/*    value(TZS)*/}
            {/*  </p>*/}
            {/*</th>*/}
            <th className="text-center">
              <p className="text-[0.85rem] font-medium opacity-60 capitalize">
                date created
              </p>
            </th>
            {/*<th className="text-center">*/}
            {/*  <p className="text-[0.85rem] font-medium opacity-60 capitalize">*/}
            {/*    assignee*/}
            {/*  </p>*/}
            {/*</th>*/}
          </tr>
          <tbody className="divide-y-[20px] divide-transparent">
            {deals?.map((deal) => (
              <tr
                key={deal.id}
                className="odd:bg-slate-50 hover:bg-primary-50 group"
              >
                <td className="text-start">
                  <p className="text-sm text-gray-800 py-3 pl-2 pr-2">
                    {deal.name}
                  </p>
                </td>
                {/*<td className="text-center">*/}
                {/*  <p className="text-sm">{shortenLargeNumber(+deal.value)}</p>*/}
                {/*</td>*/}
                <td className="text-center">
                  <p className="text-sm">
                    {new Date(deal.createdAt).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </td>
                {/*<td className="text-center">*/}
                {/*  <p className="text-sm">{`${*/}
                {/*    deal.assignee.lastName*/}
                {/*  } ${deal.assignee.firstName[0].toUpperCase()}.`}</p>*/}
                {/*</td>*/}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={"text-sm"}>
          A table of Won, Lost, Open deals in that order!
        </p>
      )}
    </div>
  );
};

export default RecentDeals;
