import { Routes, Route } from "react-router-dom";
import Teams from ".";
import TeamDetails from "./TeamDetails";
import AddMemberSlide from "./Forms/AddMemberSlide";

const TeamsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Teams />}>
        <Route path=":teamName" element={<TeamDetails />}>
          <Route path="add-member" element={<AddMemberSlide />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default TeamsRoutes;
