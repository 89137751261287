import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import Form from "components/Form";
import Input from "components/formControl/input";
import Select from "components/formControl/Select";
import Modal from "components/Modal";
import { useAuth } from "providers/auth";
import { getRoles } from "api/endpoints/rolesAndPermissions";
import { addUser, updateUser } from "api/endpoints/users";

const AddStaffUser = ({
  staffUser,
  onSave,
  onClose,
  fromTeamsView = false,
}) => {
  // const {
  //   availableRoles,
  //   loadingRoles
  // } = useSettingsData();

  const { authUser } = useAuth();
  const [roleChoices, setRoleChoices] = useState([]);
  const [values] = useState(
    staffUser
      ? {
          ...staffUser,
          name: `${staffUser.firstName} ${staffUser.lastName}`,
        }
      : {},
  );

  const { isLoading: loadingRoles } = useQuery("roles", getRoles, {
    onSuccess: ({ data }) => {
      // setAvailableRoles(data);
      if (data) {
        const availableRoles = [...data];
        let filteredRoles = null;
        if (fromTeamsView || authUser.role.name === "Sales Manager") {
          filteredRoles = availableRoles.filter(
            (role) => role.name === "Sales Person",
          );
        } else if (authUser.role.name === "Admin") {
          filteredRoles = availableRoles.filter(
            (role) =>
              role.name !== "Sales Person" || role.name !== "Sales Manager",
          );
        } else if (authUser.role.name === "Super Admin") {
          filteredRoles = availableRoles.filter(
            (role) =>
              role.name !== "Sales Person" ||
              role.name !== "Sales Manager" ||
              role.name !== "Admin",
          );
        } else if (authUser.role.name === "Senior Manager") {
          filteredRoles = availableRoles.filter(
            (role) =>
              role.name !== "Sales Person" ||
              role.name !== "Sales Manager" ||
              role.name !== "Admin",
          );
        } else if (authUser.role.name === "Root") {
          filteredRoles = availableRoles;
        }
        setRoleChoices(filteredRoles);
      }
    },
    onError: ({ err }) => {
      console.error(err);
    },
  });

  const addStaffUserMutation = useMutation(addUser, {
    onSuccess: (data) => {
      onClose();
      onSave(data.data);
    },
  });

  const updateStaffUserMutation = useMutation(updateUser, {
    onSuccess: (user) => {
      onSave(user);
    },
  });

  // useEffect(() => {
  //   if (availableRoles) {
  //     console.log("Available roles: ", availableRoles);

  //     let filteredRoles = null;

  //     if (authUser.role.name === "Sales Manager") {
  //       filteredRoles = availableRoles.filter(
  //         (role) => role.name == "Sales Person"
  //       );
  //     } else if (authUser.role.name === "Admin") {
  //       filteredRoles = availableRoles.filter(
  //         (role) => role.name !== "Root" && role.name !== "System Tester"
  //       );
  //     } else if (authUser.role.name === "Root") {
  //       filteredRoles = availableRoles;
  //     }

  //     setRoleChoices(filteredRoles);
  //   }
  // }, []);

  async function handleSubmit(payload = {}) {
    if (fromTeamsView) payload.role = roleChoices[0];
    payload.roleId = payload.role ? payload.role.id : null;
    let names = payload.name ? payload.name.split(" ") : ["Unknown", "User"];
    payload.firstName = names[0];
    payload.lastName = names[1];
    try {
      if (values.id) updateStaffUserMutation.mutate(payload);
      else addStaffUserMutation.mutate(payload);
    } catch (error) {
      console.log("Add or update staff user error: ", error);
    }
  }

  return (
    <Modal size="md" open={true} onClose={onClose}>
      <Form
        data={values}
        withWrapper={false}
        loading={
          addStaffUserMutation.isLoading || updateStaffUserMutation.isLoading
        }
        buttonText={values.id ? "Edit User" : "Add User"}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <div className="space-y-6 mt-5 mb-7">
            <Input
              label="Full NameX"
              name="name"
              placeholder="e.g. Juma Kasoma"
            />

            <Input
              label="Email"
              name="email"
              placeholder="e.g. jumakasoma@example.com"
            />

            <Input
              label="Phone Number"
              name="phoneNumber"
              placeholder="e.g. 0756895654"
            />

            {roleChoices.length > 1 && (
              <Select
                label="Role"
                placeholder="Choose Role"
                name="role"
                loading={loadingRoles}
                labelKey="name"
                choices={roleChoices ? roleChoices : []}
              />
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AddStaffUser;
