import { Route, Routes } from "react-router-dom";

import Roles from "pages/Settings/Roles/Roles";
import RoleDetails from "pages/Settings/Roles/RoleDetails";
import PermissionAssignmentForm from "pages/Settings/Roles/Forms/PermissionAssignmentForm";
import AddRole from "pages/Settings/Roles/Forms/AddRole";

const RoleRoutes = () => {
  return (
    <Routes>
      {/*<Route index element={<Navigate to={"/roles"} />} />*/}
      <Route path={"/"} element={<Roles />}>
        <Route path={"add-role"} element={<AddRole />} />
        <Route path={"edit-role/:roleId"} element={<AddRole />} />
        <Route path={":roleId"} element={<RoleDetails />}>
          <Route path={"permissions"} element={<PermissionAssignmentForm />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RoleRoutes;
