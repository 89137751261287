import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useNavigate } from "react-router-dom";
import { fetchContacts } from "api";
import ContactList from "./List";
import ContactDetails from "./Detail";
import AddContact from "./AddContact";
import Button from "components/Button";

function ContactEmptyState({ loading, onAdd }) {
  if (loading) return null;

  return (
    <div className="py-24 bg-primary/5 flex flex-col items-center justify-center text-center">
      <h3 className="text-primary mb-2.5 text-3xl font-serif font-semibold">
        Add your contacts to get started
      </h3>
      <p className="text-xl font-light mb-5 text-primary-900 opacity-70 max-w-3xl leading-relaxed px-4">
        iPF CRM helps you get rid of unmanageable contact lists by providing you
        with a convinient way to store, find and track your contacts.
      </p>

      <Button className="flex items-center capitalize" onClick={onAdd}>
        {/* <svg className="w-4 h-4 mr-1 text-white" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd" d="M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6z" /></svg> */}
        Add A Contact
      </Button>
    </div>
  );
}

function Contacts() {
  const { isLoading, error, mutate, refetch, data } = useQuery(
    "contacts",
    handleFetchContacts,
  );
  const [selectedContact, setSelectedContact] = useState(null);
  const [addContact, setAddContact] = useState();
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  async function handleFetchContacts() {
    return await fetchContacts();
  }

  useEffect(() => {
    if (!selectedContact) navigate("");
  }, [selectedContact]);

  useEffect(() => {
    if (!data?.data) return;

    // console.log("Fetched data: ", data);
    setContacts(data.data);
  }, [data]);

  useEffect(() => {
    // console.log("Contacts: ", contacts);
    if (contacts) setDefaultSelectedContact();
  }, [contacts]);

  function setDefaultSelectedContact() {
    if (contacts?.length && !selectedContact && !isLoading)
      navigate(contacts[0]?.id);
  }

  async function handleContactSaved() {
    setAddContact(false);
    await refetch();
    setDefaultSelectedContact();
  }

  function handleContactSelected(contact) {
    navigate(`${contact}`);
  }

  return (
    <div className="flex h-full overflow-hidden">
      <ContactList
        loading={isLoading}
        contacts={contacts}
        selectedContact={selectedContact}
        onSelect={handleContactSelected}
        onAdd={() => setAddContact(true)}
      />

      {addContact && (
        <AddContact
          onSave={handleContactSaved}
          onClose={() => setAddContact(false)}
        />
      )}

      <div className="flex-1">
        <Routes>
          <Route
            index
            element={
              <ContactEmptyState
                loading={isLoading}
                onAdd={() => setAddContact(true)}
              />
            }
          />
          <Route
            path=":contactId/*"
            element={
              <ContactDetails
                contacts={contacts}
                onContactSaved={handleContactSaved}
                setSelectedContact={(newValue) => {
                  if (newValue !== selectedContact)
                    setSelectedContact(newValue);
                }}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Contacts;
