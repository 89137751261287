import Form from "components/Form";
import Input from "components/formControl/input";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";

const AddComment = ({ onSave, isLoading }) => {
  const handleSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      withWrapper={false}
      showSubmitButton={false}
      loading={isLoading}
      resetAfterSubmit={true}
    >
      <div className="flex pt-6 pb-3 pl-3 pr-5 space-x-2 bg-gray-100">
        <Input
          name="body"
          fullWidth={true}
          className="rounded-xl bg-gray-200 text-gray-900 block flex-1 w-full text-sm border border-gray-400 px-4 pt-3 pb-6"
          placeholder="Write your comment here ..."
        />
        <button
          type="submit"
          disabled={isLoading}
          className={`inline-flex items-center justify-center text-primary-600`}
        >
          <PaperAirplaneIcon className={"h-7 w-7"} />
        </button>
      </div>
    </Form>
  );
};

export default AddComment;
