export const getNestedObjectValue = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const isEmptyObject = (obj) => !Object.keys(obj ?? {}).length;

export const bigMoney = (value) => {
  if (value > 100000 && value < 1000000) {
    return "TZS " + Math.floor(value / 1000).toLocaleString() + "K";
  } else if (value >= 1000000) {
    return "TZS " + Math.floor(value / 1000000).toLocaleString() + "M";
  } else if (value >= 1000000000) {
    return "TZS " + Math.floor(value / 1000000000).toLocaleString() + "B";
  } else if (typeof value !== "number") {
    return "TZS 0";
  } else {
    return "TZS " + Number(value).toLocaleString();
  }
};

export const formatMoneyValue = (value) => {
  if (typeof value !== "number" || isNaN(value)) {
    return "TZS 0";
  }

  if (value >= 1000000000) {
    return `TZS ${(value / 1000000000).toFixed(1)}B`;
  } else if (value >= 1000000) {
    return `TZS ${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `TZS ${(value / 1000).toFixed(1)}K`;
  } else {
    return `TZS ${value.toFixed(1)}`;
  }
};

export const shortenLargeNumber = (value) => {
  if (typeof value !== "number" || isNaN(value)) {
    return "0";
  }

  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed()}B`;
  } else if (value >= 1000000) {
    return `${(value / 1000000).toFixed()}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed()}K`;
  } else {
    return `${value}`;
  }
};

export function slugify(text) {
  if (typeof text !== "string") {
    throw new Error("Invalid parameter");
  }
  return text.toLowerCase().replace(/\s+/g, "-");
}

export const simplifyTime = (ms) => {
  const daysInMilliseconds = 24 * 60 * 60 * 1000;
  const weeksInMilliseconds = daysInMilliseconds * 7;
  const monthsInMilliseconds = daysInMilliseconds * 30;

  const months = Math.round(+ms / monthsInMilliseconds);
  if (months > 0) {
    if (months === 1) return "1 week";
    return `${months} months`;
  }

  const weeks = Math.round(+ms / weeksInMilliseconds);
  if (weeks > 0) {
    if (weeks === 1) return "1 week";
    return `${weeks} weeks`;
  }

  const days = Math.round(+ms / daysInMilliseconds);
  if (days > 0) {
    if (days === 1) return "1 day";
    return `${days} days`;
  }

  const hours = Math.round(+ms / (60 * 60 * 1000));
  if (hours > 0) {
    if (hours === 1) return "1 hour";
    return `${hours} hours`;
  }

  const minutes = Math.round(+ms / (60 * 1000));
  if (minutes > 0) {
    if (minutes === 1) return "1 minute";
    return `${minutes} minutes`;
  }

  return "0 minutes";
};

export const hexToRgb = (hexColor, alpha = 1) => {
  // Remove '#' symbol if present
  hexColor = hexColor.replace("#", "");

  // Parse hexadecimal components
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Return RGB color string
  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
};

export function capitalizeString(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function groupByKeyValue(key, objectsArray) {
  const groups = {};
  objectsArray?.forEach((obj) => {
    const objKeyValue = obj[key];
    groups[objKeyValue] = groups[objKeyValue] || [];
    groups[objKeyValue].push(obj);
  });
  return groups;
}
