import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import Label from './label';
import { useFormikContext } from 'formik';
import Loader from '../Loader';

const
  Select = ({
    choices = ["Choice 1", "Choice 2"],
    loading = false,
    label, labelKey = "id",
    placeholder = "Choose",
    name = 'select',
    width
  }) => {
    const { setFieldValue, values } = useFormikContext();
    let value = values[name];
    if (value) value = value[labelKey] || value;

    const [selected, setSelected] = useState(value);

    let actualChoices = [];
    let choiceType = "regular";

    if (choices && choices.length) {
      const validChoices = [...choices.filter(choice => choice && !choice.render)];
      if (validChoices[0] && validChoices[0][labelKey]) {
        choiceType = "object";
        actualChoices = choices.map(choice => {
          return choice.render ? choice : choice[labelKey];
        });
      } else {
        actualChoices = choices;
      };
    }

    useEffect(() => {
      if (!selected) return;

      let actualSelectedValue = selected;
      if (choiceType === "object")
        actualSelectedValue = choices.find(choice => choice[labelKey] === selected);

      if (actualSelectedValue && actualSelectedValue !== value)
        setFieldValue(name, actualSelectedValue);
    }, [selected]);

    return (
      <div className={`w-${width ? width : "full"} relative ${loading && "pointer-events-none"}`}>
        {label && label.length && <Label>{label} &nbsp;</Label>}
        {/* border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1
      focus:ring-primary/70 */}
        <div className={`relative ${loading && "opacity-30"} `}>
          <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
              <Listbox.Button
                type="button"
                className="relative bg-[#F0F2F7] w-full rounded-md px-3 py-2 border-[1px] border-[#E3E8F0] text-[#000] focus:outline-none focus:ring-1 focus:ring-primary/70"
              >
                <span className="block text-left truncate">
                  {selected ? selected[labelKey] || selected : placeholder}
                </span>

                <span className="absolute inset-y-0 right-0 flex items-center">
                  <span className="flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                      />
                    </svg>
                  </span>

                  {loading && (
                    <span className="absolute w-6 h-6 flex items-center justify-center">
                      <Loader color="#888" size={20} thickness={6} />
                    </span>
                  )}
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {actualChoices &&
                    actualChoices.map((choice, choiceIdx) => {
                      if (choice.render) return choice.render();

                      return (
                        <Listbox.Option
                          key={choiceIdx}
                          className={({ active }) =>
                            `${active
                              ? "text-blue-900 bg-blue-100"
                              : "text-gray-900"
                            }
                          cursor-default select-none relative pt-2 px-4
                        `
                          }
                          value={choice}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                              >
                                {choice}
                              </span>
                              {selected ? (
                                <span
                                  className={`
                                ${active ? "text-blue-600" : "text-blue-600"}
                                absolute inset-y-0 left-0 flex items-center pl-3
                              `}
                                >
                                  <svg
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    );
  };

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  choices: PropTypes.array
};

export default Select;