import { useMutation, useQuery } from "react-query";
import {
  fetchNotifications,
  removeNotification,
  searchAll,
  searchDeals,
  searchProducts,
  searchTeams,
  updateManyNotification,
  updateNotification,
} from "../../api";

export const useSearch = (query, category, options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
  };

  let queryKey, queryFn;

  switch (category.toLowerCase()) {
    case "products":
      queryKey = ["search-products", query];
      queryFn = () => searchProducts(query);
      break;
    case "teams":
      queryKey = ["search-teams", query];
      queryFn = () => searchTeams(query);
      break;
    case "deals":
      queryKey = ["search-deals", query];
      queryFn = () => searchDeals(query);
      break;
    default:
      queryKey = ["search-all", query];
      queryFn = () => searchAll(query);
      break;
  }

  return useQuery(queryKey, queryFn, queryOptions);
};

export const useGetNotifications = (options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
  };
  const queryKey = ["notifications"];
  const queryFn = () => fetchNotifications();
  return useQuery(queryKey, queryFn, queryOptions);
};

export const useUpdateNotification = (payload, options) => {
  const mutationOptions = {
    ...options,
    enabled: !!payload?.id,
    select: (res) => res.data,
  };
  const mutationKey = ["update-notification", payload?.id];
  const mutationFn = updateNotification;
  return useMutation(mutationKey, mutationFn, mutationOptions);
};

export const useUpdateManyNotifications = (options) => {
  const mutationOptions = {
    ...options,
    select: (res) => res.data,
  };
  const mutationKey = ["update-many-notifications"];
  const mutationFn = updateManyNotification;
  return useMutation(mutationKey, mutationFn, mutationOptions);
};

export const useDeleteNotification = (notificationId, options) => {
  const mutationOptions = {
    ...options,
    enabled: !!notificationId,
    select: (res) => res.data,
  };
  const mutationKey = ["delete-notification", notificationId];
  const mutationFn = removeNotification;
  return useMutation(mutationKey, mutationFn, mutationOptions);
};
