import LogIn from "pages/LogIn";
import ChangePassword from "pages/LogIn/ChangePassword";

const { Route, Routes, Navigate } = require("react-router-dom");

const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/dashboard" />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default PublicRoutes;
