import { Navigate, Route, Routes } from "react-router-dom";

import SettingsNav from "pages/Settings/SettingsNav";
import UserRoutes from "pages/Settings/Users/UserRoutes";
import RoleRoutes from "pages/Settings/Roles/RoleRoutes";
import PipelineSettings from "pages/Settings/PipelineSettings";

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route element={<SettingsNav />}>
        <Route index element={<Navigate to="users" />} />
        <Route path="users/*" element={<UserRoutes />} />
        <Route path="roles-and-permissions/*" element={<RoleRoutes />} />
        <Route path="products/*" element={<PipelineSettings />} />
      </Route>
    </Routes>
  );
};

export default SettingsRoutes;
