import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

const ToggleSwitch = ({
  defaultEnabled = false,
  className,
  sliderClassName,
  sliderEnabledClassName,
  wrapperClassName,
  // name,
  onChange,
}) => {
  const [enabled, setEnabled] = useState(defaultEnabled);

  useEffect(() => {
    setEnabled(defaultEnabled);
  }, [defaultEnabled]);

  useEffect(() => {
    if (onChange) {
      onChange(enabled);
    }
  }, [enabled]);

  return (
    <div className={wrapperClassName}>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={twMerge(
          `${enabled ? "bg-primary-500" : "bg-gray-300"}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`,
          className,
        )}
        // name={name}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={twMerge(
            `${
              enabled
                ? twMerge("translate-x-6", sliderEnabledClassName)
                : "translate-x-0 "
            }
            pointer-events-none inline-block h-5 w-5 bg-white transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`,
            sliderClassName,
          )}
        />
      </Switch>
    </div>
  );
};

export default ToggleSwitch;
