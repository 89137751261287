import ListMenu from "components/ListMenu";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTeamsQuery } from "./TeamsQueries";
import AddTeamForm from "pages/Teams/OldFiles/AddTeamForm";
import { slugify } from "../../utils";

function Teams() {
  const { teamName } = useParams();
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = useState();
  const [showAddTeamForm, setShowAddTeamForm] = useState();

  const {
    refetch: refetchTeams,
    data: teams,
    isLoading: isLoadingTeams,
  } = useTeamsQuery();

  useEffect(() => {
    if (teams && !selectedTeam) {
      !teamName
        ? setSelectedTeam(teams[0])
        : setSelectedTeam(
            teams.find((team) => slugify(team.name) === teamName),
          );
    }
    if (selectedTeam) {
      navigate(slugify(selectedTeam.name));
    }
  }, [teamName, teams, selectedTeam]);

  const handleSave = () => {
    setShowAddTeamForm(false);
    navigate("");
    refetchTeams();
  };
  const handleAddTeamClick = () => setShowAddTeamForm(true);
  const handleClose = () => setShowAddTeamForm(false);
  const handleSelectTeam = (selected) => setSelectedTeam(selected);

  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="h-full">
        <ListMenu
          // loading={true}
          title={"Teams"}
          items={teams}
          onAdd={handleAddTeamClick}
          selected={selectedTeam}
          onSelect={handleSelectTeam}
        />
        {showAddTeamForm && (
          <AddTeamForm onSave={handleSave} onClose={handleClose} />
        )}
      </div>
      <Outlet context={{ selectedTeam }} />
    </div>
  );
}

export default Teams;
