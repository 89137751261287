import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { deleteProduct, fetchProducts } from "../../../api";
import AddProductForm from "./Add/AddPipelineForm";
import { productTemplates } from "constants";

const DeletePipeline = ({ pipeline, onDelete = () => {} }) => {
  const { mutate, isLoading } = useMutation(deleteProduct, {
    onSuccess: (data) => {
      console.log("Contact created: ", data);
      onDelete();
    },
  });

  return (
    <Button
      type="button"
      className="mr-2 leading-none rounded bg-red-500 border hover:opacity-80 text-white py-1.5 pl-2 pr-3 flex items-center tracking-wide text-sm"
      loading={isLoading}
      onClick={(e) => {
        e.stopPropagation();
        mutate(pipeline.id);
      }}
    >
      <svg
        className="h-3.5 w-3.5 mr-1.5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
      Delete
    </Button>
  );
};

const PipelineSettings = () => {
  const navigate = useNavigate();
  const [pipelines, setPipelines] = useState();
  const { data, refetch } = useQuery("pipelines", handleFetchPipelines);

  async function handleFetchPipelines() {
    return await fetchProducts();
  }

  useEffect(() => {
    if (data) setPipelines(data["data"]);
  }, [data]);

  const handleClose = () => {
    refetch();
    navigate(-1);
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-end mb-1">
        <Link to="add">
          <Button className="bg-gray-200/60 border text-primary py-1.5 pl-2 pr-3 flex items-center capitalize">
            <svg
              className="w-5 h-5 my-0.5 mr-1"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6z"
              />
            </svg>
            Add Product
          </Button>
        </Link>
      </div>
      <div className="py-2 grid grid-cols-3 gap-6">
        {pipelines &&
          pipelines.map((pipeline, index) => {
            const { id, name } = pipeline;
            return (
              <div key={`${index}`} className="relative">
                {/* <Link
                  to={`dashboard/products/${name
                    .toLowerCase()
                    .replace(" ", "-")}`}
                > */}
                <div className="cursor-pointer bg-white shadow border border-gray-100 rounded-md overflow-hidden">
                  <div className="flex items-center justify-between mb-3 mt-4 px-4">
                    <h3 className="text-xl font-semibold">{name}</h3>
                  </div>
                  <div className="pt-3 pb-2 px-4 border-t">
                    <div className="flex items-center flex-wrap">
                      {pipeline.stages?.length > 0 &&
                        pipeline.stages.map((stage) => {
                          return (
                            <div
                              className="overflow-hidden relative rounded-r-full borders sborder-l-4 pl-2.5 pr-4 py-2 text-sm leading-none mr-2 mb-2"
                              style={{
                                borderLeftColor: stage.color,
                                borderRadius: "12px 50px 50px 12px",
                              }}
                            >
                              <span
                                className="absolute inset-0"
                                style={{ background: stage.color }}
                              ></span>
                              <span className="relative text-white">
                                {stage.name}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {/* </Link> */}

                <div className="absolute top-0 right-0 h-14 pr-3 flex items-center">
                  {/* <DeletePipeline pipeline={pipeline} onDelete={refetch} /> */}

                  <Link to="edit" className="hidden">
                    <button className="leading-none rounded border hover:bg-gray-100 text-primary py-1.5 pl-2 pr-3 flex items-center tracking-wide text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5 mr-1.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        <Routes>
          <Route
            path="add"
            element={
              <AddProductForm
                template={{ ...productTemplates.general }}
                onSave={() => handleClose()}
                onClose={() => handleClose()}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default PipelineSettings;
