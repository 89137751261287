import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const generateQueryParams = (params) => {
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      queryParams.append(key, value.toString());
    }
  }

  return queryParams;
};

const useURLParamsState = (defaultParams) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(search));
  }, [search]);

  const setParams = (p) => {
    if (p) {
      const queryParams = generateQueryParams({ ...params, ...p });
      navigate(`${pathname}?${queryParams}`, { replace: true });
    }
  };

  useEffect(() => {
    if (defaultParams) setParams(defaultParams);
  }, []);

  return [params, setParams];
};

export default useURLParamsState;
