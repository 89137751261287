import { useFormikContext } from "formik";
import { useRef } from "react";
import { useMutation } from "react-query";
import { createProduct } from "api";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import Form from "components/Form";
import Input from "components/formControl/input";
import ColorPicker from "components/ColorPicker";

function ProductPhases() {
  const pipelineStagesWrapper = useRef();
  const { setFieldValue, values } = useFormikContext();

  function handleAddNewPhase() {
    setFieldValue("phases", [...values.phases, ""]);
    setFieldValue("times", [...values.times, ""]);
    setFieldValue("colors", [...values.colors, "#66bb6a"]);
    try {
      const newInput = pipelineStagesWrapper.current.querySelector(
        `[name="phases[${values.phases.length}]"]`,
      );
      if (newInput) newInput.focus();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div ref={pipelineStagesWrapper} className="">
      <div className="space-y-2">
        {values.phases?.map((item, index) => (
          <div key={index} className="flex items-center relative">
            <div className="space-x-2 mt-2 mr-10 flex items-center">
              <ColorPicker name={`colors[${index}]`} nolabel />
              <div className="grid grid-cols-3 content-center gap-2">
                <div className="col-span-2">
                  <Input
                    fullWidth={true}
                    name={`phases[${index}]`}
                    placeholder="Stage"
                    required
                  />
                </div>

                <Input
                  fullWidth={true}
                  placeholder="TAT"
                  name={`times[${index}]`}
                  required
                />

                {/* <Select
                  name={`statuses[${index}]`}
                  placeholder="Status"
                  choices={["Open", "Lost", "Won"]}
                /> */}
              </div>
            </div>

            <div className="  opacity-100 group-hover:opacity-100 absolute inset-y-0 right-1 -mr-1 flex items-center">
              <button
                type="button"
                className={`flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-black/5 hover:bg-black/10
                        ${index === 0 && "opacity-0 pointer-events-none"}
                    `}
                tabIndex={index === 0 ? -1 : ""}
                onClick={() => {
                  setFieldValue(
                    "phases",
                    values.phases.filter((_, i) => i !== index),
                  );
                  setFieldValue(
                    "colors",
                    values.colors.filter((_, i) => i !== index),
                  );
                }}
              >
                <svg
                  className="h-3.5 w-3.5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <button
        type="button"
        className="flex items-center mt-2 py-2 rounded border-none bg-transparent text-sm text-primary"
        onClick={handleAddNewPhase}
      >
        <svg
          className="h-5 mr-1.5 opacity-80"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
        Add New Stage
      </button>
    </div>
  );
}

function AddProductForm({ template, onSave, onClose }) {
  const navigate = useNavigate();
  const { mutate } = useMutation(createProduct, {
    onSuccess: (data) => {
      onSave(data);
      onClose();
      navigate("/settings/products", {
        replace: true,
      });
    },
  });

  async function handleSubmit(data) {
    const name = data.name;
    let phases = [];
    data.phases.forEach((phase, index) => {
      phases.push({ name: phase, order: index + 1, time: data.times[index] });
    });
    const payload = { name, phases };
    try {
      mutate(payload);
    } catch (error) {
      console.log("Creating product error: ", error);
    }
  }

  return (
    <Modal title="Add Product" size="xl" open={true} onClose={onClose}>
      <Form
        data={{ ...template }}
        withWrapper={false}
        buttonText="Submit"
        onSubmit={handleSubmit}
      >
        <div className="m-4">
          <Input
            label="Name"
            name="name"
            placeholder="Enter Product Name"
            required
          />
        </div>
        <div className="mt-6 mx-4 h-72">
          <label htmlFor="" className="flex flex-col mb-2">
            <span className="text-[11px] text-red-800 font-semibold">
              STAGES
            </span>
            <div className="flex flex-row font-medium text-[13.5px] mt-1 text-gray-500">
              <span className="">Color</span>
              <div className="grid grid-cols-4 gap-2 ml-4 space-x-4">
                <span className="col-span-3 ml-12">Name</span>
                <span className="ml-4">TAT(Days)</span>
              </div>
            </div>
          </label>
          <div className="overflow-y-auto h-56">
            <ProductPhases />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default AddProductForm;
