import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { useTeamsQuery } from "pages/Teams/TeamsQueries";

const getTeamsPerformance = (teams, deals, productId) => {
  if (!teams?.length || !deals || !productId) return [];
  return teams.map((team) => {
    const targets = team.member.reduce((targets, member) => {
      targets.push(...member.targets);
      return targets;
    }, []);
    const teamProductTargets = targets.filter(
      (target) => target.productId === productId,
    );
    const teamProductVolumeTarget = teamProductTargets.reduce(
      (total, target) => {
        return total + +target.volume;
      },
      0,
    );
    const teamProductValueTarget = teamProductTargets.reduce(
      (total, target) => {
        return total + +target.value;
      },
      0,
    );

    const teamWonDeals = deals.filter(
      (deal) => deal.teamId === team.id && deal.status === 1,
    );
    const teamWonDealsCount = teamWonDeals.length;
    const teamWonDealsValue = teamWonDeals.reduce((total, deal) => {
      return total + +deal.value;
    }, 0);

    return {
      name: team.name,
      volume: teamWonDealsCount,
      value: teamWonDealsValue,
      volumeTarget: teamProductVolumeTarget,
      valueTarget: teamProductValueTarget,
    };
  });
};

export default function TeamsPerformance({ deals, productId }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  useTeamsQuery({
    onSuccess: (data) => {
      setRows(getTeamsPerformance(data, deals, productId));
    },
  });

  return (
    <div className={"bg-white rounded-xl p-4"}>
      <div className="flex flex-col space-y-1 pb-3 border-b-[1px]">
        <span className="text-xs font-semibold uppercase">teams</span>
        <span className={"text-[0.825rem] text-gray-500"}>
          Performance summary
        </span>
      </div>
      {1 ? (
        <table className="table-auto w-full text-left divide-y-[16px] divide-transparent">
          <tr className="">
            <td className="text-start">
              <p className="text-[0.85rem] font-medium opacity-60 capitalize pt-3 pb-2 pl-2">
                team
              </p>
            </td>
            <th className="text-center">
              <p className="text-[0.85rem] font-medium opacity-60 capitalize">
                volume
              </p>
            </th>
            <th className="text-center">
              <p className="text-[0.85rem] font-medium opacity-60 capitalize">
                value
              </p>
            </th>
            <th className="">
              <p className=""></p>
            </th>
          </tr>
          <tbody className="divide-y-[20px] divide-transparent">
            {rows?.map((row) => (
              <tr
                key={row.name}
                onClick={() => {
                  navigate(
                    `/dashboard/teams/${row.name
                      .toLowerCase()
                      .replace(/\s/g, "-")}`,
                  );
                }}
                className="cursor-pointer odd:bg-slate-50 hover:bg-primary-50 group"
              >
                <td className="text-start">
                  <p className="text-sm text-gray-800 py-3 pl-2">{row.name}</p>
                </td>
                <td className="text-center">
                  <p className="text-sm">{`${
                    row.volumeTarget
                      ? ((row.volume * 100) / row.volumeTarget).toFixed()
                      : 0
                  }%`}</p>
                </td>
                <td className="text-center">
                  <p className="text-sm">{`${
                    row.valueTarget
                      ? ((row.value * 100) / row.valueTarget).toFixed()
                      : 0
                  }%`}</p>
                </td>
                <td className="text-center">
                  <p className="text-primary group-hover:text-primary-700 pl-4">
                    <BsFillArrowRightCircleFill
                      className={"h-[1.1rem] w-[1.1rem]"}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={"text-sm"}>Empty!</p>
      )}
    </div>
  );
}
