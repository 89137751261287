import { monthNames } from "constants";
import { shortenLargeNumber, slugify } from "utils";
import { useNavigate } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/outline";

export const DealCard = ({ deal }) => {
  const navigate = useNavigate();
  const expectedTimeToClose =
    new Date(deal.expectedClosingDate) - new Date(deal.createdAt);
  const timeElapsed = new Date() - new Date(deal.createdAt);
  const percentage = expectedTimeToClose
    ? (timeElapsed / expectedTimeToClose).toFixed()
    : 0;
  const width = `${percentage < 100 ? percentage : 100}%`;

  const handleClick = () => {
    navigate(`/dashboard/home/${slugify(deal.productName)}/deals/${deal.id}`);
  };

  return (
    <div className="flex flex-col space-y-2 border px-3 pt-2 pb-3 rounded-md shadow-sm">
      <div className="flex justify-between pb-1">
        <h1 className="font-medium text-[0.8rem]">
          {deal?.name && deal?.name.length > 18
            ? `${deal?.name.slice(0, 17)}...`
            : deal?.name}
        </h1>
        <p className="font-medium text-[0.8rem]">
          {`TZS ${shortenLargeNumber(+deal.value)}`}
        </p>
      </div>
      <div className="flex justify-between items-center space-x-1">
        <div className="flex items-center">
          <span className="text-[0.8rem] text-gray-700 border-l-2 border-gray-300 pl-1.5 font-medium">
            {monthNames[new Date(deal.createdAt).getMonth()] +
              " " +
              new Date(deal.createdAt).getDate()}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-[0.8rem] text-gray-700 border-r-2 border-gray-300 pr-1.5 font-medium">
            {monthNames[new Date(deal.expectedClosingDate).getMonth()] +
              " " +
              new Date(deal.expectedClosingDate).getDate()}
          </span>
        </div>
      </div>
      <div className="h-5 w-full bg-primary/10 rounded-md">
        <div className="">
          <div
            style={{ width: width }}
            className={`h-5 bg-primary/60 rounded-md flex items-center ${
              percentage < 26 ? "justify-start" : "justify-end"
            }`}
          >
            <span className="px-2 text-sm text-gray-700">{`${percentage}%`}</span>
          </div>
        </div>
      </div>
      <div className={"w-full flex justify-end"}>
        <span
          onClick={handleClick}
          className={
            "flex items-center space-x-1 mt-1 px-2 py-0.5 rounded-lg hover:cursor-pointer text-primary-700 border-[1px] border-primary-700/70"
          }
        >
          <span className={"text-[0.9rem]"}>View</span>
          <EyeIcon className={"h-4 w-4"} />
        </span>
      </div>
    </div>
  );
};

export default DealCard;
