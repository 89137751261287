import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { useAddToCache } from "hooks/useManageCache";
import AddStaffUser from "pages/Settings/Users/AddStaffUser";
import Button from "components/form-control/button";
import Combobox from "components/combobox";
import SlideOverHeader from "components/SlideOverHeader";
import AddTargetForm from "../Forms/AddTargetForm";
import TargetCard from "../Forms/TargetCard";
import SlideOver from "components/SlideOver";
import { PlusIcon } from "@heroicons/react/24/outline";
import { getUsers } from "api/endpoints/users";
import { fetchProducts, inviteMemberToTeam } from "api";

const AddMemberSlide = ({ onClose, onSubmit }) => {
  const { selectedTeam } = useOutletContext();

  const [open, setOpen] = useState(true);

  const [member, setMember] = useState({});
  const [targets, setTargets] = useState([]);
  // const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showAddTargetForm, setShowAddTargetForm] = useState(false);
  const [showEditTargetForm, setShowEditTargetForm] = useState(false);

  const [targetToEdit, setTargetToEdit] = useState({});

  const navigate = useNavigate();

  const addToCache = useAddToCache();

  const { data: users } = useQuery("staff", getUsers, {
    select: (data) => {
      const salespeople = data?.data?.filter(
        (user) => !user.role || user.role.name === "Sales Person",
      );
      return salespeople?.map((user) => {
        const fullName = user.firstName + " " + user.lastName;
        return { ...user, fullName };
      });
    },
  });

  useQuery("products-100", fetchProducts, {
    onSuccess: ({ data }) => {
      setProducts(data);
    },
    onError: ({ err }) => {
      console.error(err);
    },
  });

  const { mutate: addMember } = useMutation(inviteMemberToTeam, {
    onError: (error) => {
      console.log("Error adding member: ", error);
    },
  });

  const handleSaveTarget = (target) => {
    setShowAddTargetForm(false);
    const id = targets.length + 1;
    const targetData = { ...target, id };
    setTargets([...targets, targetData]);
  };

  const handleEditTarget = (target) => {
    setShowEditTargetForm(false);
    const editedTargetIndex = targets.findIndex((t) => t.id === target.id);
    let newTargetsArr = [...targets];
    newTargetsArr.splice(editedTargetIndex, 1, target);
    setTargets(newTargetsArr);
  };

  const handleDeleteTarget = (target) => {
    const newTargetsArr = targets.filter((t) => t.id !== target.id);
    setTargets(newTargetsArr);
  };

  const handleEdit = (target) => {
    setTargetToEdit(target);
    setShowEditTargetForm(true);
  };

  const handleDelete = (target) => {
    handleDeleteTarget(target);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  function handleSubmit() {
    const teamId = selectedTeam?.id;
    const newMemberId = member?.id;
    const formattedTargets = targets.map((target) => {
      return {
        volume: target.count,
        value: target.amount,
        productId: target.product.id,
        currency: target.currency,
      };
    });
    if (teamId && newMemberId)
      addMember({ teamId, newMemberId, targets: formattedTargets });

    handleClose();
  }

  const handleSaveUser = (user) => {
    addToCache("staff", user);
    setShowAddUserForm(false);
  };

  /* <SlideOver
              open={showMemberForm}
              onClose={() => setShowMemberForm(false)}
            >
              <AddMemberSlide
                onClose={() => setShowMemberForm(false)}
                onSubmit={() => {
                  refetchTeams();
                  refetchTeam();
                  setShowMemberForm(false);
                }}
              />
            </SlideOver> 
            */

  return (
    <SlideOver open={open} onClose={handleClose}>
      <div className="relative w-[460px] 2xl:w-[900px] bg-white h-full">
        <SlideOverHeader title={"Add Member"} onClose={handleClose} />
        <div className="px-5 py-2">
          <p className="text-sm text-gray-600">
            {`Search and/or select to add a member to ${selectedTeam?.name}. Add user if they are not present.`}
          </p>
        </div>
        <div className="h-[calc(100%_-_7.5rem)] pt-5 px-5">
          <div className="flex items-center space-x-2">
            <Combobox
              className={"placeholder:text-gray-500"}
              wrapperClass={"flex-1 space-y-0 "}
              options={users}
              displayName="fullName"
              onChange={(user) => setMember(user)}
            />
            <Button
              outline
              className={
                "flex items-center text-primary border-gray-300 focus:bg-red-200 text-sm pl-2 py-2.5 focus:border-0 border rounded"
              }
              onClick={() => setShowAddUserForm(true)}
            >
              <PlusIcon className={"h-4 w-4 text-primary"} strokeWidth={2} />
              <span className={"text-primary"}>User</span>
            </Button>
          </div>
          <div className="my-4 h-[88%] 2xl:h-[95%]">
            <div className="items-between">
              <h1 className="font-medium text-gray-800 mt-4 mb-1">Targets</h1>
              {/* <button onClick={() => setShowAddTargetForm(true)}>
                <PlusCircleIcon strokeWidth={2} className="h-6 w-6" />
              </button> */}
            </div>
            <div className="h-[78%] 2xl:h-[85%] overflow-auto mt-4 bg-gray-100 rounded-md">
              <div className="flex justify-end"></div>
              {targets.length > 0 ? (
                targets.map((target) => (
                  <TargetCard
                    target={target}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                ))
              ) : (
                <div className="h-full flex flex-col ml-2 space-y-2 justify-center items-center">
                  <p className="text-gray-400 text-[0.94rem] p-1">
                    No targets assigned!
                  </p>
                  <AddTargetButton onClick={() => setShowAddTargetForm(true)} />
                </div>
              )}
              {targets.length > 0 && (
                <div className="flex justify-center">
                  <AddTargetButton onClick={() => setShowAddTargetForm(true)} />
                </div>
              )}
            </div>
          </div>
          {showAddUserForm && (
            <AddStaffUser
              onSave={handleSaveUser}
              onClose={() => setShowAddUserForm(false)}
              fromTeamsView={true}
            />
          )}
          {showAddTargetForm && (
            <AddTargetForm
              products={products}
              onClose={() => setShowAddTargetForm(false)}
              onSave={handleSaveTarget}
            />
          )}
          {showEditTargetForm && (
            <AddTargetForm
              products={products}
              onClose={() => setShowEditTargetForm(false)}
              onSave={handleEditTarget}
              target={targetToEdit}
            />
          )}
          <div className="w-full absolute right-0 bottom-0 mb-4 px-6">
            <Button
              onClick={() => handleSubmit()}
              className="w-full bg-primary text-white"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </SlideOver>
  );
};

const AddTargetButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="flex items-center space-x-1 py-2 pl-2 pr-3 m-2 rounded text-primary-700 focus:ring-2 focus:ring-offset-1 focus:ring-primary/50 border-[1.5px] border-primary/70 active:ring-primary/50"
      onClick={onClick}
    >
      <PlusIcon className="h-4 w-4 text-primary" strokeWidth={2} />
      <span className="text-primary">Add Target</span>
    </button>
  );
};

export default AddMemberSlide;
