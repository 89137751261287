import { Link } from "react-router-dom";
import { BsPersonCheck } from "react-icons/bs";

import { shortenLargeNumber } from "utils";

export function DealCard({ deal, phaseTime }) {
  // const lastActivity = deal.lastActivity || {};

  const { firstName, lastName } = deal.assignee;
  const assigneeFullName = `${firstName} ${lastName}`;

  const timeToBeSpent = phaseTime * 24 * 60 * 60 * 1000;
  const timeSpent = new Date() - new Date(deal.phaseCommenced);
  const isLate = timeSpent > timeToBeSpent;

  return (
    <div
      className={
        "bg-white flex flex-col space-y-1 rounded-md shadow-sm pt-3 pb-2 px-2 mb-4"
      }
    >
      <div className="flex space-y-1 flex-col ml-2 pt-1">
        <div className="flex space-x-1">
          <span className="font-medium text-xs uppercase">{deal?.name}</span>
          {deal.status === 2 &&
            (isLate ? (
              <div className="bg-red-400 w-2 h-2 rounded-full"></div>
            ) : (
              <div className="bg-green-400 w-2 h-2 rounded-full"></div>
            ))}
        </div>
        <span className="text-xs text-gray-500">
          {`TZS ${shortenLargeNumber(+deal.value)}`}
        </span>
      </div>
      <div className="flex items-center justify-between pb-1">
        <div className="flex ml-2 py-1.5">
          <div className="flex space-x-2 items-center">
            <BsPersonCheck className={"h-[1.1rem] w-[1.1rem] text-gray-500"} />
            <span className="text-xs text-gray-800">{assigneeFullName}</span>
          </div>
        </div>
        <div className="flex justify-enddd px-2">
          <Link to={deal.id}>
            <button className="flex items-center space-x-1 border-[1px] border-primary hover:bg-primary/10 rounded-md px-2 py-0.5">
              <small className="text-primary-700">View</small>
            </button>
          </Link>
        </div>
      </div>
      {/*<p className="mt-1.5 text-xs leading-relaxed text-black/50">*/}
      {/*  Last activity:&nbsp;*/}
      {/*  <span className="text-black">*/}
      {/*    {"Jerry"} {"Lusato"}*/}
      {/*  </span>*/}
      {/*  &nbsp;*/}
      {/*  <span*/}
      {/*    dangerouslySetInnerHTML={{*/}
      {/*      __html: lastActivity.action,*/}
      {/*    }}*/}
      {/*  ></span>*/}
      {/*</p>*/}
    </div>
  );
}
