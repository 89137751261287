import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getQuarter } from "date-fns";
import { LuBriefcase } from "react-icons/lu";
import { AiOutlineLineChart } from "react-icons/ai";

import useURLParamsState, {
  generateQueryParams,
} from "hooks/useURLParamsState";
import { useProductsQuery } from "pages/Products/productQueries";
import SelectInputLoader from "pages/Products/ProductDetails/SelectInputLoader";
import SelectInput from "pages/Products/ProductDetails/SelectInput";
import { quarters } from "pages/Teams/teamsConstantsAndUtils";

const years = [
  { id: 1, name: "2023" },
  { id: 2, name: "2024" },
];

export default function DetailsLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const [products, setProducts] = useState(null);
  const [product, setProduct] = useState(null);

  const defaultQuarter = quarters.find(
    (quarter) => quarter.id === getQuarter(new Date()).toString(),
  );

  const [paramsState, setParamsState] = useURLParamsState({
    quarter: defaultQuarter.id,
    year: 2024,
  });

  useProductsQuery({
    onSuccess: (data) => {
      setProducts(data);
      setProduct(data.find((p) => p.id === paramsState.productId));
    },
    select: (res) => res.data,
    enabled: !!paramsState.productId,
  });

  const setQuarter = (q) => setParamsState({ ...paramsState, quarter: q.id });
  const setYear = (y) => setParamsState({ ...paramsState, year: y.name });

  const currentView = location.pathname.split("/")[3];

  const toggleView = () => {
    if (currentView === "analytics")
      navigate(`/dashboard/home/deals?${generateQueryParams(paramsState)}`);
    if (currentView === "deals")
      navigate(`/dashboard/home/analytics?${generateQueryParams(paramsState)}`);
  };

  const handleSelectProduct = (product) => {
    setParamsState({ ...paramsState, productId: product.id });
    setProduct(product);
  };

  return (
    <div className="h-full">
      <div className="h-20 flex items-center justify-between bg-white pl-4 pr-6 py-3 border-b">
        {product === null ? (
          <div className={"flex space-x-2"}>
            <SelectInputLoader width={"w-72"} textWidth={"w-32"} />
            <SelectInputLoader width={"w-72"} textWidth={"w-32"} />
            <SelectInputLoader width={"w-20"} />
          </div>
        ) : (
          <div className="flex space-x-2">
            <SelectInput
              options={products}
              selectedOption={product}
              onChange={handleSelectProduct}
              className={"w-72 bg-gray-100"}
            />

            <SelectInput
              options={quarters}
              selectedOption={defaultQuarter}
              onChange={setQuarter}
              className={"w-72 bg-gray-100"}
            />

            <SelectInput
              options={years}
              selectedOption={{ id: 2, name: "2024" }}
              onChange={setYear}
              className={"w-28 bg-gray-100"}
            />
          </div>
        )}
        {product === null ? (
          <SelectInputLoader width={"w-20"} />
        ) : (
          <div className="flex items-center bg-gray-100 rounded-lg">
            <span
              onClick={toggleView}
              className={`px-3 py-2 rounded-l-lg ${
                currentView === "analytics" &&
                "bg-primary-100/70 hover:bg-primary-100"
              }`}
            >
              <AiOutlineLineChart
                className={`h-4 w-4 ${
                  currentView === "analytics"
                    ? "text-primary-700"
                    : "text-primary"
                }`}
                strokeWidth={3}
              />
            </span>
            <span
              onClick={toggleView}
              className={`px-3 py-2 rounded-r-lg ${
                currentView === "deals" &&
                "bg-primary-100/70 hover:bg-primary-100"
              }`}
            >
              <LuBriefcase
                className={`h-4 w-4 ${
                  currentView === "deals" ? "text-primary-700" : "text-primary"
                }`}
                strokeWidth={2}
              />
            </span>
          </div>
        )}
      </div>

      <Outlet />
    </div>
  );
}
