import { monthNames } from "constants";
import { endOfQuarter, isWithinInterval } from "date-fns";

export const generateFormattedTimestamp = (date) => {
  const dateObj = new Date(date);
  // Get the current month, day, hours, and minutes
  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";
  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;
  // Add leading zeros to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  // Create the formatted date string
  return `${month} ${day}, ${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

export const quarters = [
  { id: "0", name: "All Year" },
  { id: "1", name: "1st Quarter" },
  { id: "2", name: "2nd Quarter" },
  { id: "3", name: "3rd Quarter" },
  { id: "4", name: "4th Quarter" },
];

export const isDateInQuarter = (dateToCheck, quarter, year) => {
  if (quarter < 1 || quarter > 4) {
    throw new Error("Quarter must be between 1 and 4");
  }

  const startMonth = (quarter - 1) * 3 + 1;
  const startOfQuarterDate = new Date(year, startMonth - 1, 1);
  const endOfQuarterDate = endOfQuarter(startOfQuarterDate);

  return isWithinInterval(dateToCheck, {
    start: startOfQuarterDate,
    end: endOfQuarterDate,
  });
};
