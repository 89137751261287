import PerformanceCardBar from "./PerformanceCardBar";

const PerformanceCard = ({ value, volume, valueTarget, volumeTarget }) => {
  return (
    <div className="h-64 bg-white w-full border p-4 rounded-xl">
      <div className="h-full flex flex-col space-y-4 p-2 rounded-md">
        <h1 className="uppercase text-sm font-semibold text-gray-700">
          Performance
        </h1>
        <div className="flex flex-col space-y-4">
          <PerformanceCardBar
            label="volume"
            value={volume}
            target={volumeTarget}
          />
          <PerformanceCardBar
            label="value"
            value={value}
            target={valueTarget}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceCard;
