import { Route, Routes } from "react-router-dom";

import Users from "pages/Settings/Users/Users";
import AddUser from "pages/Settings/Users/Forms/AddUser";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Users />}>
        <Route path={"add"} element={<AddUser />} />
        <Route path={":userId/edit"} element={<AddUser />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
