import { useMutation } from "react-query";
import Form from "../../components/Form";
import Input from "../../components/formControl/input";
import Modal from "../../components/Modal";
import { createCompany } from "../../api";

function AddCompany({ stages = [], onClose, onSave = () => {} }) {
  const { isLoading, error, mutate } = useMutation(createCompany, {
    onSuccess: (data) => {
      // console.log("Company created: ", data);
      onSave(data);
    },
  });

  async function handleSubmit(data) {
    try {
      mutate(data);
    } catch (error) {
      console.log("New Company error: ", error);
    }
  }

  return (
    <Modal title="Add Company" size="md" open={true} onClose={onClose}>
      <Form
        data={{}}
        withWrapper={false}
        loading={isLoading}
        onSubmit={handleSubmit}
      >
        <div className="space-y-6 mt-5 mb-7">
          <Input autoFocus label="Company Name" name="name" />
        </div>
      </Form>
    </Modal>
  );
}

export default AddCompany;
