import { useQuery } from "react-query";

import {
  getProduct,
  getProductDealsInPhases,
  getProductStats,
} from "api/endpoints/products";

export const dealQueryKeys = {
  dealsInPhases: (filters) => {
    return ["products-in-phases", JSON.stringify(filters)];
  },
  stats: (filters) => {
    return ["stats", JSON.stringify(filters)];
  },
  product: (productId) => {
    return ["product", productId];
  },
};

export const useProductDealsInPhases = (filters, options) => {
  const { productId, quarter, year } = filters;

  const queryOptions = {
    ...options,
    select: (res) => res.data,
    enabled: !!productId && !!quarter && !!year,
  };

  return useQuery(
    dealQueryKeys.dealsInPhases(filters),
    () => getProductDealsInPhases(filters),
    queryOptions,
  );
};

export const useProductStats = (filters, options) => {
  const { productId, quarter, year } = filters;

  const queryOptions = {
    ...options,
    select: (res) => res.data,
    enabled: !!productId && !!quarter && !!year,
  };

  return useQuery(
    dealQueryKeys.stats(filters),
    () => getProductStats(filters),
    queryOptions,
  );
};

export const useProduct = (productId, options) => {
  const queryOptions = {
    ...options,
    select: (res) => res.data,
    enabled: !!productId,
  };

  return useQuery(
    dealQueryKeys.product(productId),
    () => getProduct(productId),
    queryOptions,
  );
};
