export const productTemplates = {
    general: {
        name: "",
        phases: [
            "Doc Engagement", "BB Deal Forum", "Credit Appraisal", "Credit Paper Submission", "NBC", "NBC Credit Submission", "SA Credit Submission", "MCC/BCC", "CORS Ratification", "FL Preparation"
        ],
        times: [
            "21", "23", "21", "23", "27", "23", "31", "23", "21", "12", "21", "11",
        ],
        colors: [
            '#32414E', '#556270', '#6D8EAD', '#8EAEBD', '#AAB7B8', '#BAC5C6', '#CBD2D3', '#DCE3E4', '#EDF0F1', '#F6F8F9'
        ]
    },

    sales: {
        name: "Sales pipeline",
        type: "value",
        description: "Keep track of your incoming opportunities from start to close.",
        stages: [
            "New leads", "Qualified leads", "Quote sent", "Negotiating", "Quote Accepted", "Deal Lost", "Deal Closed"
        ],
        status: [
            2, 2, 2, 2, 2, 2, 2
        ],
        colors: [
            "#0077c2", "#66bb6a", "#ffd54f", "#ff9800", "#e53935", "#bdbdbd", "#9c27b0"
        ]
    },

    project: {
        name: "Project pipeline",
        description: "Manage your project's momentum from start to finish.",
        stages: [
            "Not started",
            "Planning",
            "In progress",
            "In review",
            "Complete",
        ],
        status: [
            0, 0, 0, 0, 0
        ],
        colors: [
            "#795548", "#2196f3", "#ff9800", "#9c27b0", "#4caf50"
        ]
    },
}

export const targetsTemplate = {
    general: {
        counts: [
            "2", "4", "2", "4", "2", "4", "2", "4", "2", "4", "4", "2", "4",
        ],
        amounts: [
            "200,000", "430,000", "200,000", "430,000", "200,000", "430,000", "200,000", "430,000", "200,000", "430,000", "430,000", "200,000", "430,000"
        ],
        targets: ["CASA",
            "Fixed Deposit Account",
            "Call Account",
            "CIT",
            "Term Loan",
            "Overdraft",
            "Revolving Short Term Loans",
            "Trade Loans",
            "Letters of Credit",
            "Invoice Discounting",
            "VAF",
            "POF",
            "BGIs"]
    }
}

export const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];