import { generateFormattedTimestamp } from "pages/Teams/teamsConstantsAndUtils";

const Activity = ({ Icon, date, title, description }) => {
  return (
    <div className="flex items-start relative pb-8">
      <div className="bg-primary/20 w-[1.5px] h-full absolute inset-y-0 left-[0.77rem]"></div>
      <div className="bg-primary/20 p-0.5 rounded-full border border-primary/30">
        <Icon className="h-5 w-5 text-primary-700" />
      </div>
      <div className="ml-3">
        <p className="text-xs text-gray-500">
          {generateFormattedTimestamp(date)}
        </p>
        <h1 className="text-gray-700">{title}</h1>
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="text-sm text-gray-600"
        ></p>
      </div>
    </div>
  );
};

export default Activity;
