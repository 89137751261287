import { Formik, Form as FormikForm } from "formik";
import { useEffect, useState } from "react";
import Button from "./Button";

export default function Form({
  title = "New Entry",
  data = {},
  error = "",
  children,
  onSubmit = () => { },
  loading = false,
  wide = false,
  withWrapper = true,
  showTitle = true,
  showSubmitButton = true,
  buttonText = "SUBMIT",
  fullWidthButton,
  fullWidth = false,
  resetAfterSubmit
}) {
  const [initialValues, setInitialValues] = useState(data);

  useEffect(() => {
    if (data.forceUpdate) setInitialValues(data);
  }, [data]);

  function handleSubmit(data) {
    setInitialValues(data);
    onSubmit(data);
    if (resetAfterSubmit) setInitialValues({})
  }

  const FormContent = () => (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <FormikForm className="space-y-5">
          {error && (
            <div className="py-3 px-4 bg-red-100 text-red-500">{error}</div>
          )}

          {typeof children == "function" ? children(props) : children}

          {showSubmitButton && (
            <div className="flex justify-end">
              <Button
                type="submit"
                className={`-mt-2 py-3.5 text-base px-9 ${fullWidthButton ? "w-full" : ""
                  }`}
                loading={loading}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </FormikForm>
      )}
    </Formik>
  );

  if (!withWrapper) return <FormContent />;

  return (
    <div
      className={`p-8 border rounded shadow-sm mt-3 mx-auto ${wide ? "max-w-3xl" : "max-w-2xl"
        }`}
    >
      {showTitle && (
        <h2 className="text-black text-xl mb-5 font-semibold">{title}</h2>
      )}

      <FormContent />
    </div>
  );
}
