import { Transition } from "@tailwindui/react";
import { BriefcaseIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDeleteNotification, useUpdateNotification } from "./SearchQueries";
import { simplifyTime, slugify } from "../../utils";
import { useNavigate } from "react-router-dom";

const NotificationsDropdown = ({ notifications, refetchNotifications }) => {
  return (
    <Transition
      enter="transition ease-out duration-100"
      enter-from="transform opacity-0 scale-95"
      enter-to="transform opacity-100 scale-100"
      leave="transition ease-out duration-100"
      leave-from="transform opacity-100 scale-100"
      leave-to="transform opacity-0 scale-95"
      show={true}
    >
      <div
        className="origin-top-right absolute right-0 mt-[2.1rem] pb-3 w-[30rem] rounded-lg shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <NotificationHeader />
        <div className="h-96 flex flex-col space-y-1 pb-3 overflow-y-auto">
          {notifications && notifications.length > 0 ? (
            notifications
              ?.sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime(),
              )
              .map((notification) => {
                return (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    refetchNotifications={refetchNotifications}
                  />
                );
              })
          ) : (
            <p className={"h-full center text-sm text-gray-500"}>
              No notifications now!
            </p>
          )}
        </div>
      </div>
    </Transition>
  );
};

const NotificationHeader = () => {
  return (
    <div className={"w-full pb-3 border-b border-gray-200"}>
      <h1 className={"text-gray-700 text-[0.95rem] px-3 pt-2"}>
        Notifications
      </h1>
    </div>
  );
};

const NotificationItem = ({ notification, refetchNotifications }) => {
  const navigate = useNavigate();

  const { id, note, opened, dealId, productName, createdAt } = notification;
  const timeElapsed = new Date().getTime() - new Date(createdAt).getTime();

  const { mutate: mutateNotification } = useUpdateNotification();

  const { mutate: removeNotification } = useDeleteNotification();

  const handleClickNotification = (notificationId) => {
    mutateNotification({ id: notificationId, opened: true });
    navigate(`home/${slugify(productName)}/deals/${dealId}`);
  };

  const handleClickDeleteNotification = (event, notificationId) => {
    event.stopPropagation();
    event.preventDefault();
    removeNotification(notificationId);
    refetchNotifications();
  };

  return (
    <button
      className={`flex items-start justify-between px-2 py-3 w-full group ${
        !opened && "bg-rose-50/40"
      }`}
      onClick={() => handleClickNotification(id)}
    >
      <div className="flex items-center space-x-2">
        {opened === false ? (
          <span className="p-[0.2rem] rounded-full bg-primary-600"></span>
        ) : (
          opened === true && (
            <span className="p-0.5 rounded-full bg-inherit"></span>
          )
        )}
        <span className="bg-primary-200 p-3 rounded-full">
          <BriefcaseIcon className={"h-6 w-6 text-primary-800"} />
        </span>
      </div>
      <div className="flex flex-col text-start text-sm pl-3 pr-2">
        <span className="">{note}</span>
        <span className="pt-1.5 text-gray-500">
          {simplifyTime(timeElapsed)} ago
        </span>
      </div>
      <span
        className="mx-1.5 p-1 hover:bg-rose-100 rounded"
        onClick={(event) => handleClickDeleteNotification(event, id)}
      >
        <TrashIcon
          className={`h-4 w-4 ${
            opened ? "text-white" : "text-rose-50/40"
          } group-hover:text-rose-400`}
        />
      </span>
    </button>
  );
};

export default NotificationsDropdown;
