import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { addTeam, editTeam } from "api";
import { getUsers } from "api/endpoints/users";
import Form from "components/Form";
import Input from "components/formControl/input";
import Select from "components/formControl/Select";
import Modal from "components/Modal";
import { useAuth } from "providers/auth";

const choices = (staffArr) => {
  return staffArr
    ? staffArr?.map((person) => {
        const fullName = person.firstName + " " + person.lastName;
        return { id: person.id, fullName };
      })
    : [];
};

function AddTeamForm({ onSave, team = null }) {
  const { authUser } = useAuth();
  const [staffData, setStaffData] = useState();

  const staffQuery = useQuery("users", getUsers, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (staffQuery.data) setStaffData([...staffQuery.data.data]);
  }, [staffQuery.data]);

  const addMutation = useMutation(addTeam, {
    onSuccess: (data) => {
      onSave();
      // navigate(
      //   `/teams/${data.data.team.id}`,
      //   { replace: true }
      // );
    },
    onError: (err) => {
      onSave();
      console.log("Error adding team: ", err);
    },
  });

  const editMutation = useMutation(editTeam, {
    onSuccess: (data) => {
      onSave();
    },
    onError: (err) => {
      onSave();
      console.log("Error editing team: ", err);
    },
  });

  async function handleSubmit(values) {
    if (team) {
      try {
        editMutation.mutate({ ...team, ...values });
      } catch (err) {
        console.log("Error editing team: ", err);
      }
    } else {
      try {
        addMutation.mutate({ ...values });
      } catch (err) {
        console.log("Error creating team: ", err);
      }
    }
  }

  const managers = staffData?.filter(
    (staff) => staff.role.name === "Sales Manager",
  );
  const admins = staffData?.filter((staff) => staff.role.name === "Admin");

  let name = "",
    leader = null,
    admin = null,
    initialData = {};
  if (team) name = team.name;
  if (managers && team)
    leader = choices(managers).find((staff) => staff.id === team.teamLead);
  if (managers && team)
    admin = choices(admins).find((staff) => staff.id === team.teamAdmin);
  if (leader && admin) {
    initialData = { name, leader, admin };
  } else {
    initialData = { name };
  }

  return (
    <Modal size="md" open={true} onClose={() => onSave()}>
      <Form
        data={initialData}
        withWrapper={false}
        onSubmit={handleSubmit}
        buttonText="SAVE"
      >
        <div className="space-y-6 mt-5 mb-7">
          <Input placeholder="Name..." name="name" label="Name" required />

          <Select
            label="Team Leader"
            placeholder="Choose Team Leader"
            name="leader"
            labelKey="fullName"
            valueKey="id"
            choices={choices(managers)}
          />

          {authUser && authUser.role.name === "Root" ? (
            <Select
              label="Manager"
              placeholder="Choose Manager"
              name="admin"
              labelKey="fullName"
              valueKey="id"
              choices={choices(admins)}
            />
          ) : (
            ""
          )}
        </div>
        {/* <div>
          <Input placeholder="Enter team description.." name="description" label="Description" required />
        </div> */}
        {/* <div className="space-y-6 mt-5 mb-7">
          <Select
            name="metricSelected"
            label="Performance Metric"
            labelKey="metric"
            choices={choices}
          />
        </div> */}
        {/* <div>
          <Input placeholder="Enter a sales target.." name="target" label="Target" type="number" required />
        </div> */}
        {/* <div className="mt-6">
          <div className="fixed top-2 right-3 z-50">
            <Button type="submit" loading={isLoading}>
              Save
            </Button>
          </div>
        </div> */}
      </Form>
    </Modal>
    // <div className="max-w-xl mx-auto">
    //   <Form
    //     data={{ name, description, target }}
    //     showTitle={false}
    //     showSubmitButton={false}
    //     onSubmit={handleSubmit}
    //   >
    //     <div>
    //       <div className="flex justify-center">
    //         <div
    //           className="mb-3 w-10 h-10 flex justify-center items-center rounded-full shadow-sm text-center"
    //         >
    //           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    //             <path d="M211.2 96C211.2 131.3 182.5 160 147.2 160C111.9 160 83.2 131.3 83.2 96C83.2 60.65 111.9 32 147.2 32C182.5 32 211.2 60.65 211.2 96zM32 256C32 220.7 60.65 192 96 192H192C204.2 192 215.7 195.4 225.4 201.4C188.2 216.5 159.8 248.6 149.6 288H64C46.33 288 32 273.7 32 256V256zM415.9 200.6C425.3 195.1 436.3 192 448 192H544C579.3 192 608 220.7 608 256C608 273.7 593.7 288 576 288H493.6C483.2 247.9 453.1 215.4 415.9 200.6zM391.2 226.4C423.3 233.8 449.3 257.3 460.1 288C463.7 298 465.6 308.8 465.6 320C465.6 337.7 451.3 352 433.6 352H209.6C191.9 352 177.6 337.7 177.6 320C177.6 308.8 179.5 298 183.1 288C193.6 258.3 218.3 235.2 249.1 227.1C256.1 225.1 265.1 224 273.6 224H369.6C377 224 384.3 224.8 391.2 226.4zM563.2 96C563.2 131.3 534.5 160 499.2 160C463.9 160 435.2 131.3 435.2 96C435.2 60.65 463.9 32 499.2 32C534.5 32 563.2 60.65 563.2 96zM241.6 112C241.6 67.82 277.4 32 321.6 32C365.8 32 401.6 67.82 401.6 112C401.6 156.2 365.8 192 321.6 192C277.4 192 241.6 156.2 241.6 112zM608 416C625.7 416 640 430.3 640 448C640 465.7 625.7 480 608 480H32C14.33 480 0 465.7 0 448C0 430.3 14.33 416 32 416H608z" />
    //           </svg>
    //         </div>
    //       </div>
    //       {/* <label htmlFor="" className="flex flex-col mb-2">
    //         <span className="text-lg">Name</span>
    //       </label> */}
    //       <Input placeholder="Enter team name.." name="name" label="Name" required />
    //     </div>
    //     <div>
    //       <Input placeholder="Enter team description.." name="description" label="Description" required />
    //     </div>
    //     {/* <div className="space-y-6 mt-5 mb-7">
    //       <Select
    //         name="metricSelected"
    //         label="Performance Metric"
    //         labelKey="metric"
    //         choices={choices}
    //       />
    //     </div> */}
    //     <div>
    //       <Input placeholder="Enter a sales target.." name="target" label="Target" type="number" required />
    //     </div>
    //     <div className="mt-6">
    //       <div className="fixed top-2 right-3 z-50">
    //         <Button type="submit" loading={isLoading}>
    //           Save
    //         </Button>
    //       </div>
    //     </div>
    //   </Form>
    // </div>
  );
}

export default AddTeamForm;
