import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getQuarter } from "date-fns";
import { useProductsDataQuery } from "../../TeamsQueries";
import Members from "./Members";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import DealsProgressionChart from "../Components/DealsProgressionChart";
import PerformanceCard from "../Components/PerformanceCard";
import HighlightsCard from "../Components/HighlightsCard";
import MonthlyDealsChart from "../Components/MonthlyDealsChart";
import { useAuth } from "providers/auth";
import { isDateInQuarter, quarters } from "pages/Teams/teamsConstantsAndUtils";
import DealsDistribution from "../Components/DealsDistribution";
import Badge from "components/badge";
import AddTeamForm from "pages/Teams/OldFiles/AddTeamForm";

const TeamOverview = ({ selectedTeam }) => {
  const navigate = useNavigate();
  const { authUser } = useAuth();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(
    getQuarter(new Date()).toString(),
  );

  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState();
  const [showAll, setShowAll] = useState();
  const [stats, setStats] = useState();
  const [allStats, setAllStats] = useState();

  const [isEditingTeam, setIsEditingTeam] = useState(false);
  const [isDeletingTeam, setIsDeletingTeam] = useState(false);

  useProductsDataQuery(selectedTeam?.id, {
    onSuccess: (data) => {
      setProducts(
        data.filter((product) => {
          const deals = product.phases.reduce((deals, phase) => {
            deals.push(...phase.deals);
            return deals;
          }, []);
          const teamDeals = deals.filter(
            (deal) => deal.teamId == selectedTeam.id,
          );
          return teamDeals.length;
        }),
      );
    },
    enabled: !!selectedTeam,
  });

  useEffect(() => {
    if (selectedTeam && !selectedProduct && products) {
      const filteredProducts = products.map((product) => {
        const { phases } = product;
        const filteredPhases = phases?.map((phase) => {
          const deals = phase.deals
            .filter((deal) => deal.teamId == selectedTeam.id)
            .filter((deal) => {
              // check if deal is in the current quarter
              if (selectedQuarter == "1") {
                return isDateInQuarter(new Date(deal.createdAt), 1, 2023);
              } else if (selectedQuarter == "2") {
                return isDateInQuarter(new Date(deal.createdAt), 2, 2023);
              } else if (selectedQuarter == "3") {
                return isDateInQuarter(new Date(deal.createdAt), 3, 2023);
              } else if (selectedQuarter == "4") {
                return isDateInQuarter(new Date(deal.createdAt), 4, 2023);
              } else {
                return true;
              }
            });
          return { ...phase, deals };
        });
        return { ...product, phases: filteredPhases };
      });

      // part 2
      const dealsDistPerProduct = filteredProducts?.map((product) => {
        const allOpenProductDeals = product.phases.reduce(
          (allOpenProductDeals, phase) => {
            if (
              phase.name !== "Won" &&
              phase.name !== "Lost" &&
              phase.deals.length
            )
              allOpenProductDeals.push(...phase.deals);
            return allOpenProductDeals;
          },
          [],
        );
        const allWonProductDeals = product.phases.reduce(
          (allWonProductDeals, phase) => {
            if (phase.name === "Won" && phase.deals.length)
              allWonProductDeals.push(...phase.deals);
            return allWonProductDeals;
          },
          [],
        );
        return {
          name: product.name,
          openDealsCount: allOpenProductDeals.length,
          openDealsValue: allOpenProductDeals.reduce(
            (totalValue, deal) => totalValue + +deal.value,
            0,
          ),
          wonDealsCount: allWonProductDeals.length,
          wonDealsValue: allWonProductDeals.reduce(
            (totalValue, deal) => totalValue + +deal.value,
            0,
          ),
        };
      });

      const allDeals = filteredProducts?.reduce((allDeals, product) => {
        const allProductDeals = product.phases.reduce(
          (allProductDeals, phase) => {
            if (phase?.deals.length) allProductDeals.push(...phase?.deals);
            return allProductDeals;
          },
          [],
        );
        if (allProductDeals?.length) allDeals.push(...allProductDeals);
        return allDeals;
      }, []);

      const allWonDeals = allDeals.filter((deal) => deal.status == 1);

      const allWonDealsCount = allWonDeals?.length;
      const allDealsCount = allDeals?.length;
      const allWonDealsValue = allWonDeals?.reduce(
        (total, deal) => total + +deal.value,
        0,
      );
      // const allDealsValue = allDeals?.reduce(
      //   (total, deal) => total + +deal.value,
      //   0
      // );
      const totalTime = allWonDeals?.reduce((totalTime, deal) => {
        return deal.dateClosed
          ? totalTime + (new Date(deal.dateClosed) - new Date(deal.createdAt))
          : 0;
      }, 0);

      // highlights
      const winRate = allDealsCount
        ? (allWonDealsCount * 100) / allDealsCount
        : 0;

      const avgDealCycle =
        totalTime && allWonDealsCount ? totalTime / allWonDealsCount : 0;

      const avgDealSize =
        allWonDealsValue && allWonDealsCount
          ? allWonDealsValue / allWonDealsCount
          : 0;

      // performance
      const value = allWonDealsValue;
      const volume = allWonDealsCount;

      const allTargets = selectedTeam?.member?.reduce((allTargets, member) => {
        const memberTargets = member?.targets;
        allTargets.push(...memberTargets);
        return allTargets;
      }, []);
      const valueTarget = allTargets?.reduce(
        (valueTarget, target) => valueTarget + +target.value,
        0,
      );
      const volumeTarget = allTargets?.reduce(
        (volumeTarget, target) => volumeTarget + +target.volume,
        0,
      );

      // data for chart
      const names = dealsDistPerProduct.map((val) => val.name);
      const openDealsCounts = dealsDistPerProduct.map(
        (val) => val.openDealsCount,
      );
      const wonDealsCounts = dealsDistPerProduct.map(
        (val) => val.wonDealsCount,
      );
      const openDealsValues = dealsDistPerProduct.map(
        (val) => val.openDealsValue,
      );
      const wonDealsValues = dealsDistPerProduct.map(
        (val) => val.wonDealsValue,
      );

      const openDealsData = { names, openDealsCounts, openDealsValues };
      const wonDealsData = { names, wonDealsCounts, wonDealsValues };

      setAllStats({
        allDeals,
        openDealsData,
        wonDealsData,
        performance: {
          value,
          valueTarget,
          volume,
          volumeTarget,
          winRate,
          avgDealCycle,
          avgDealSize,
        },
      });
    }
  }, [selectedTeam, selectedProduct, selectedQuarter, products]);

  useEffect(() => {
    if (selectedTeam && selectedProduct) {
      const allTargets = selectedTeam?.member?.reduce((allTargets, member) => {
        const memberTargets = member?.targets;
        allTargets.push(...memberTargets);
        return allTargets;
      }, []);
      const productTargets = allTargets?.filter(
        (target) => target.productId == selectedProduct.id,
      );
      const valueTarget = productTargets?.reduce(
        (valueTarget, target) => valueTarget + +target.value,
        0,
      );
      const volumeTarget = productTargets?.reduce(
        (volumeTarget, target) => volumeTarget + +target.volume,
        0,
      );
      const target = { value: valueTarget, volume: volumeTarget };

      const filteredPhases = selectedProduct.phases?.map((phase) => {
        const deals = phase.deals
          .filter((deal) => deal.teamId == selectedTeam.id)
          .filter((deal) => {
            // check if deal is in the current quarter
            if (selectedQuarter == "1") {
              return isDateInQuarter(new Date(deal.createdAt), 1, 2023);
            } else if (selectedQuarter == "2") {
              return isDateInQuarter(new Date(deal.createdAt), 2, 2023);
            } else if (selectedQuarter == "3") {
              return isDateInQuarter(new Date(deal.createdAt), 3, 2023);
            } else if (selectedQuarter == "4") {
              return isDateInQuarter(new Date(deal.createdAt), 4, 2023);
            } else {
              return true;
            }
          });
        return { ...phase, deals };
      });
      setProductData({ ...selectedProduct, phases: filteredPhases, target });
    }
  }, [selectedTeam, selectedProduct, selectedQuarter]);

  useEffect(() => {
    if (productData) {
      const stagesData = productData.phases
        .filter((phase) => phase.name !== "Won")
        .filter((phase) => phase.name !== "Lost")
        .map((phase) => {
          return {
            name: phase.name,
            order: phase.order,
            count: phase.deals?.length,
          };
        });
      const arrangedStagesData = stagesData.sort((a, b) => a.order - b.order);
      const wonPhase = productData?.phases?.find(
        (phase) => phase.name === "Won",
      );
      const wonDeals = wonPhase?.deals;
      const wonDealsTotalCount = wonDeals?.length;
      const wonDealsTotalValue = wonDeals?.reduce(
        (total, deal) => total + +deal.value,
        0,
      );

      const totalDealsCount = productData?.phases.reduce(
        (total, phase) => total + phase.deals.length,
        0,
      );
      const winRate = totalDealsCount
        ? (wonDealsTotalCount * 100) / totalDealsCount
        : 0;

      const totalTime = wonDeals?.reduce((totalTime, deal) => {
        return deal.dateClosed
          ? totalTime + (new Date(deal.dateClosed) - new Date(deal.createdAt))
          : 0;
      }, 0);

      const value = wonDealsTotalValue;
      const valueTarget = productData.target.value;
      const volume = wonDealsTotalCount;
      const volumeTarget = productData.target.volume;

      const avgDealCycle =
        totalTime && wonDealsTotalCount ? totalTime / wonDealsTotalCount : 0;

      const avgDealSize =
        wonDealsTotalValue && wonDealsTotalCount
          ? wonDealsTotalValue / wonDealsTotalCount
          : 0;

      // spread deals into their respective months
      const allDeals = productData.phases.reduce((allDeals, phase) => {
        allDeals.push(...phase.deals);
        return allDeals;
      }, []);

      let monthlyDealsCount = [],
        monthlyDealsValue = [];

      const getCount = (month) => month.length;
      const getValue = (month) =>
        month.reduce((totalValue, deal) => totalValue + +deal.value, 0);

      if (selectedQuarter == "0") {
        const jan = [],
          feb = [],
          mar = [],
          apr = [],
          may = [],
          jun = [],
          jul = [],
          aug = [],
          sep = [],
          oct = [],
          nov = [],
          dec = [];
        for (const deal of allDeals) {
          if (new Date(deal.createdAt).getMonth() == 0) {
            jan.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 1) {
            feb.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 2) {
            mar.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 3) {
            apr.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 4) {
            may.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 5) {
            jun.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 6) {
            jul.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 7) {
            aug.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 8) {
            sep.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 9) {
            oct.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 10) {
            nov.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 11) {
            dec.push(deal);
          }
        }
        monthlyDealsCount.push(
          getCount(jan),
          getCount(feb),
          getCount(mar),
          getCount(apr),
          getCount(may),
          getCount(jun),
          getCount(jul),
          getCount(aug),
          getCount(sep),
          getCount(oct),
          getCount(nov),
          getCount(dec),
        );
        monthlyDealsValue.push(
          getValue(jan),
          getValue(feb),
          getValue(mar),
          getValue(apr),
          getValue(may),
          getValue(jun),
          getValue(jul),
          getValue(aug),
          getValue(sep),
          getValue(oct),
          getValue(nov),
          getValue(dec),
        );
      } else if (selectedQuarter == "1") {
        const jan = [],
          feb = [],
          mar = [];
        for (const deal of allDeals) {
          if (new Date(deal.createdAt).getMonth() == 0) {
            jan.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 1) {
            feb.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 2) {
            mar.push(deal);
          }
        }
        monthlyDealsCount.push(getCount(jan), getCount(feb), getCount(mar));
        monthlyDealsValue.push(getValue(jan), getValue(feb), getValue(mar));
      } else if (selectedQuarter == "2") {
        const apr = [],
          may = [],
          jun = [];
        for (const deal of allDeals) {
          if (new Date(deal.createdAt).getMonth() == 3) {
            apr.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 4) {
            may.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 5) {
            jun.push(deal);
          }
        }
        monthlyDealsCount.push(getCount(apr), getCount(may), getCount(jun));
        monthlyDealsValue.push(getValue(apr), getValue(may), getValue(jun));
      } else if (selectedQuarter == "3") {
        const jul = [],
          aug = [],
          sep = [];
        for (const deal of allDeals) {
          if (new Date(deal.createdAt).getMonth() == 6) {
            jul.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 7) {
            aug.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 8) {
            sep.push(deal);
          }
        }
        monthlyDealsCount.push(getCount(jul), getCount(aug), getCount(sep));
        monthlyDealsValue.push(getValue(jul), getValue(aug), getValue(sep));
      } else {
        const oct = [],
          nov = [],
          dec = [];
        for (const deal of allDeals) {
          if (new Date(deal.createdAt).getMonth() == 9) {
            oct.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 10) {
            nov.push(deal);
          } else if (new Date(deal.createdAt).getMonth() == 11) {
            dec.push(deal);
          }
        }
        monthlyDealsCount.push(getCount(oct), getCount(nov), getCount(dec));
        monthlyDealsValue.push(getValue(oct), getValue(nov), getValue(dec));
      }

      setStats({
        allDeals,
        monthlyDealsCount,
        monthlyDealsValue,
        stagesData: arrangedStagesData,
        performance: {
          value,
          valueTarget,
          volume,
          volumeTarget,
          winRate,
          avgDealCycle,
          avgDealSize,
        },
      });
    }
  }, [productData]);

  return (
    <div className="relative h-[calc(100%_-_3rem)] mt-14 pr-2 overflow-auto">
      <div className="sticky top-0 z-10 bg-gray-100">
        <div className="flex justify-between border-b-[1px] py-2 mx-3">
          <div className="px-2">
            <div className="flex space-x-3">
              <span className="text-gray-800 font-medium capitalize">
                {selectedTeam?.name.toLowerCase() ?? ""}
              </span>
              <Badge
                type="error"
                text={`${selectedTeam?.member.length ?? 0} ${
                  selectedTeam?.member.length &&
                  selectedTeam?.member.length === 1
                    ? "Member"
                    : "Members"
                }`}
                className={"ring-0 bg-primary/10 text-primary-900"}
              />
            </div>
            <span className="text-xs text-gray-600">
              {selectedTeam?.description ?? ""}
            </span>
          </div>

          {false && isEditingTeam && (
            <AddTeamForm
              team={selectedTeam}
              onSave={() => {
                setIsEditingTeam(false);
                navigate(`/teams`);
                // refetchTeams();
                // refetchTeam();
              }}
              onClose={() => setIsEditingTeam(false)}
            />
          )}

          {/*{false && isDeletingTeam && (*/}
          {/*  <DeleteTeam*/}
          {/*    teamId={selectedTeam.id}*/}
          {/*    onSave={() => {*/}
          {/*      setIsDeletingTeam(false);*/}
          {/*      navigate(`/teams`);*/}
          {/*      navigate(0);*/}
          {/*    }}*/}
          {/*    onClose={() => setIsDeletingTeam(false)}*/}
          {/*  />*/}
          {/*)}*/}

          <div className="flex space-x-2 px-2">
            <button
              className="bg-white shadow h-fit p-2 rounded flex items-center space-x-0.5 cursor-pointer hover:bg-red-100"
              onClick={() => setIsEditingTeam(true)}
            >
              <PencilSquareIcon className="h-4 w-4 text-blue-400" />
              <span className="text-xs uppercase font-medium">Edit</span>
            </button>
            {authUser &&
              (authUser.role.name === "Admin" ||
                authUser.role.name === "Super Admin") && (
                <button
                  className="bg-white shadow h-fit p-2 rounded flex items-center space-x-0.5 cursor-pointer hover:bg-red-100"
                  onClick={() => setIsDeletingTeam(true)}
                >
                  <TrashIcon className="h-4 w-4 text-red-400" />
                  <span className="text-xs uppercase font-medium">Delete</span>
                </button>
              )}
          </div>
        </div>
        <div className="flex items-center mx-3 pt-2 pb-3 px-1">
          {/* <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" /> */}
          <div className="flex divide-x-[1px] divide-black/20 border-solid border-[1px] border-black/20 text-center text-sm rounded-lg cursor-pointer overflow-auto whitespace-nowrap scrollbar-hide snap-x">
            <span
              key={"all"}
              onClick={() => setSelectedProduct(null)}
              className={`${
                selectedProduct == null ? "bg-primary/20" : "hover:bg-primary/5"
              } px-3 py-0.5 snap-center text-sm`}
            >
              All
            </span>
            {products?.map((product) => (
              <span
                key={`product-${product.id}`}
                onClick={() => setSelectedProduct(product)}
                className={`${
                  product.id === selectedProduct?.id
                    ? "bg-primary/20"
                    : "hover:bg-primary/5"
                } px-3 py-0.5 snap-center text-sm`}
              >
                {product.name}
              </span>
            ))}
          </div>
          {/* <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" /> */}
        </div>
        <div className="flex h-8 justify-between items-center border-b-4 mx-3">
          <ul className="flex h-full items-center text-sm cursor-pointer">
            {quarters.map((quarter) => {
              return (
                <li
                  key={`quarter-${quarter.id}`}
                  className={`${
                    quarter.id === selectedQuarter
                      ? "text-primary-600 bg-gray-200"
                      : "hover:text-primary-600/80"
                  } inline h-full px-3 pt-1 rounded-t-lg`}
                  onClick={() => setSelectedQuarter(quarter.id)}
                >
                  {quarter.name}
                </li>
              );
            })}
          </ul>
          <div className="flex space-x-2 px-2">
            <span className="text-xs text-primary">2024</span>
            <ChevronDownIcon className="h-4 w-4 text-primary" />
          </div>
        </div>
      </div>
      <div className="mx-3 pt-1 pb-4 bg-gray-200 px-2">
        <div className="grid grid-cols-3 gap-2 pb-8 border-b">
          <div className="col-span-2 flex flex-col space-y-1">
            {selectedProduct ? (
              <DealsProgressionChart stagesWithCounts={stats?.stagesData} />
            ) : (
              <div className="flex flex-col space-y-2">
                <DealsDistribution
                  label={"open"}
                  dealsData={allStats?.openDealsData}
                />
                <DealsDistribution
                  label={"won"}
                  dealsData={allStats?.wonDealsData}
                />
              </div>
            )}
            {selectedProduct && (
              <MonthlyDealsChart
                quarter={Number(selectedQuarter)}
                monthlyDealsCount={stats?.monthlyDealsCount}
                monthlyDealsValue={stats?.monthlyDealsValue}
              />
            )}
          </div>
          <div className="col-span-1 flex flex-col space-y-4">
            <PerformanceCard
              value={
                selectedProduct
                  ? stats?.performance.value
                  : allStats?.performance.value
              }
              valueTarget={
                selectedProduct
                  ? stats?.performance.valueTarget
                  : allStats?.performance.valueTarget
              }
              volume={
                selectedProduct
                  ? stats?.performance.volume
                  : allStats?.performance.volume
              }
              volumeTarget={
                selectedProduct
                  ? stats?.performance.volumeTarget
                  : allStats?.performance.volumeTarget
              }
            />
            <HighlightsCard
              winRate={
                selectedProduct
                  ? stats?.performance.winRate
                  : allStats?.performance.winRate
              }
              avgDealSize={
                selectedProduct
                  ? stats?.performance.avgDealSize
                  : allStats?.performance.avgDealSize
              }
              avgDealCycle={
                selectedProduct
                  ? stats?.performance.avgDealCycle
                  : allStats?.performance.avgDealCycle
              }
            />
          </div>
        </div>
        <Members
          members={selectedTeam?.member}
          deals={selectedProduct ? stats?.allDeals : allStats?.allDeals}
        />
      </div>
    </div>
  );
};

export default TeamOverview;
