import { useState } from "react";
import { useMemberActivitiesForProductQuery } from "pages/Teams/TeamsQueries";
import Activity from "./Activity";
import { BoltIcon } from "@heroicons/react/24/outline";

const Activities = ({ memberId, productId }) => {
  const [activities, setActivities] = useState([]);

  useMemberActivitiesForProductQuery(memberId, productId, {
    onSuccess: (data) => {
      setActivities(data);
    },
  });

  return (
    <div className="h-[25rem] bg-white rounded-lg shadow-md p-4 overflow-hidden">
      <h1 className="text-sm font-semibold uppercase py-1">Activities</h1>
      <div className="h-[20.6rem] mt-2 mx-1 overflow-auto">
        {activities
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((activity) => (
            <Activity
              Icon={BoltIcon}
              date={activity.createdAt}
              title={activity.type}
              description={activity.action}
            />
          ))}
      </div>
    </div>
  );
};

export default Activities;
