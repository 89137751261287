import { Route, Routes } from "react-router-dom";

import Products from "pages/Products/Products";
import DetailsLayout from "pages/Products/ProductDetails/DetailsLayout";
import Deals from "pages/Products/ProductDetails/Deals/Deals";
import Deal from "pages/Products/ProductDetails/Deals/Deal";
import Analytics from "pages/Products/ProductDetails/Analytics/Analytics";

export default function ProductsRoutes() {
  return (
    <Routes>
      <Route index element={<Products />} />
      <Route path={"analytics"} element={<DetailsLayout />}>
        <Route path={""} element={<Analytics />} />
      </Route>
      <Route path={"deals"} element={<DetailsLayout />}>
        <Route path={""} element={<Deals />}>
          <Route path={":dealId"} element={<Deal />} />
        </Route>
      </Route>
    </Routes>
  );
}
