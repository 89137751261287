import formatDate from "date-fns/format";
import React from "react";

function DealActivityEntry({ activity, lastChild }) {
  const { type, actor, action, createdAt, reference } = activity;
  const date = formatDate(new Date(createdAt), "MMM dd, yyyy hh:mm a");

  return (
    <div className={`flex items-start relative ${!lastChild && "pb-6"}`}>
      <div className="absolute inset-y-0 left-2.5 border-l-[0.1rem]"></div>

      {/*<span*/}
      {/*  className={`flex items-center justify-center flex-shrink-0 rounded-full border-2 border-white w-6 h-6 text-white z-10*/}
      {/*    ${type === "addDeal" && "bg-gray-500"}*/}
      {/*    ${["reorderDeal", "moveDeal"].includes(type) && "bg-indigo-500"}*/}
      {/*    ${type === "comment" && "bg-pink-500"}*/}
      {/*    ${type === "appointment" && "bg-orange-500"}*/}
      {/*    ${type === "dealLost" && "bg-red-600"}*/}
      {/*    ${type === "dealWon" && "bg-green-600"}*/}
      {/*  `}*/}
      {/*>*/}
      {/*  {dealIcon(type)}*/}
      {/*</span>*/}

      <div className="flex items-start space-x-6 ml-[0.4rem]">
        <div className="relative bg-gray-400 p-[0.2rem] mt-1.5 rounded-full border"></div>
        <div className="">
          <p className="text-xs text-gray-500">{date}</p>

          <p className="flex flex-col pl-2">
            <span className="text-gray-800">{type}</span>
            <span
              className={"text-gray-500 -mt-1"}
              dangerouslySetInnerHTML={{ __html: action }}
            ></span>
          </p>
        </div>

        {/*{reference && (*/}
        {/*  <div className="pt-2.5">*/}
        {/*    {type === "comment" && (*/}
        {/*      <ActivityComment reference={reference[0] || reference || {}} />*/}
        {/*    )}*/}
        {/*    {type === "appointment" && (*/}
        {/*      <ActivityAppointment*/}
        {/*        reference={reference[0] || reference || {}}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
}

export default DealActivityEntry;
