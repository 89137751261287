import { Link, Outlet, useLocation } from "react-router-dom";

const SettingsNav = () => {
  const location = useLocation();

  const sections = ["Users", "Roles and Permissions", "Products"];

  const activeSection = sections.find(
    (section) =>
      location.pathname
        .toLowerCase()
        .indexOf(section.toLowerCase().replaceAll(" ", "-")) !== -1,
  );

  return (
    <>
      <div className="bg-white z-10 sticky pt-4 top-0 border-b px-6">
        <div className="flex items-center justify-between">
          <h3 className="text-xl text-gray-700 font-medium mt-1 mb-2">
            Settings
          </h3>
        </div>
        <ul className="flex space-x-6 text-gray-600 -mb-px">
          {sections.map((section) => (
            <li key={section}>
              <Link
                to={`${section.toLowerCase().replaceAll(" ", "-")}`}
                className={`
                        block uppercase tracking-wide py-3 px-2 text-xs font-medium border-b-[1.5px]
                        ${
                          section === activeSection
                            ? "border-current text-primary-5"
                            : "border-transparent"
                        }
                    `}
              >
                {section}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <Outlet />
    </>
  );
};

export default SettingsNav;
