import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "components/form-control";

const ModalHeader = ({ title, onClose: handleClose }) => {
  return (
    <div className={"px-5 py-3 border-b border-gray-300 items-between"}>
      <h1 className={"font-semibold text-gray-800"}>{title}</h1>
      <Button
        type={"button"}
        tabIndex={-1}
        onClick={handleClose}
        className={
          "p-0 bg-inherit h-8 w-8 center -mr-3 rounded-lg hover:bg-primary-100 hover:text-primary text-gray-500"
        }
      >
        <XMarkIcon className={"h-6"} />
      </Button>
    </div>
  );
};

export default ModalHeader;
