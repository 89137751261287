import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({ open, onClose, children, dialogClass = "pt-[10vh]" }) => {
  return (
    <Transition appear show={open}>
      <Dialog
        as={"div"}
        onClose={onClose}
        className={`fixed inset-0 top-0 z-50 p-5 ${dialogClass}`} /* Apply styles to dialog component */
      >
        <Transition.Child
          as={
            React.Fragment
          } /* Replace Fragment component with React.Fragment */
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100 backdrop-blur-[2px]"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed bbg-gradient-to-t from-gray-900/80 to-gray-900/50 inset-0 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          className="mx-auto w-fit relative"
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {children}
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
