import axios from "axios";

import { getValueFromLocalStorage } from "hooks/useLocalStorageState";

const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "https://uzo-api.herokuapp.com/api/v1";
// export const BASE_URL = "http://192.168.1.126:3000/api/v1";
// export const BASE_URL = "http://192.168.1.143:3000/api/v1";
// export const BASE_URL = "http://127.0.0.1:5000/api/v1";

export const get = async (endpoint) => {
  return await request("get", endpoint);
};
export const put = async (endpoint, data) => {
  return await request("put", endpoint, data);
};

export const patch = async (endpoint, data) => {
  return await request("patch", endpoint, data);
};

export const post = async (endpoint, data) => {
  return await request("post", endpoint, data);
};

export const remove = async (endpoint) => {
  return await request("delete", endpoint);
};

export const request = async (type, endpoint, data) => {
  // if (!navigator.onLine)
  //     throw (new Error("Offline!"));

  const token = getValueFromLocalStorage("authToken", null);
  const response = await axios({
    method: type,
    url: BASE_URL + endpoint,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data,
  });
  const responseData = response.data;
  return responseData;
};
