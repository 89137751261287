export default function TeamViewTabs({ activeTab, onSwitch, tabs }) {
  return (
    <div className="relative">
      <div className="w-full absolute bottom-[0.5px] border-b-[1px] border-gray-200"></div>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`relative border-b-2 py-2 px-4 ${
            activeTab == index ? "border-primary" : "border-transparent"
          }`}
          onClick={() => onSwitch(index)}
        >
          <span
            className={`text-sm ${
              activeTab == index ? "text-primary-700" : "text-gray-800"
            }`}
          >
            {tab}
          </span>
        </button>
      ))}
    </div>
  );
}
