import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

const TargetCard = ({ target = {}, onEdit, onDelete }) => {
  return (
    <div className="bg-gray-50 border shadow-sm m-2 space-y-2 p-5 rounded-md">
      <div className="flex justify-between">
        <h1 className="font-medium text-gray-800">{target?.product?.name}</h1>
        <div className="flex ">
          <div
            onClick={() => onEdit(target)}
            className="h-8 w-8 hover:bg-blue-100 text-blue-600 rounded-md center"
          >
            <PencilSquareIcon className="h-5 w-5" />
          </div>
          <div
            onClick={() => onDelete(target)}
            className="h-8 w-8 hover:bg-red-100 text-red-600 rounded-md center"
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
      <div className="flex  divide-x divide-gray-300 text-sm text-gray-600">
        <p className="pr-2 space-x-1">
          <span className="text-gray-500 ">Amount:</span>
          <span className="font-medium text-gray-600">{target?.amount}</span>
        </p>

        <p className=" pl-2 space-x-1">
          <span className="text-gray-500">Count:</span>
          <span className="font-medium text-gray-600">{target?.count}</span>
        </p>
      </div>
    </div>
  );
};

export default TargetCard;
