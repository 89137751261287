import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ActionButton from './Button/ActionButton';
import PropTypes from 'prop-types';

const Modal = ({ size, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const onClose = props.onClose;

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6
                ${size === 'md' && 'sm:max-w-md'}
                ${size === 'lg' && 'sm:max-w-lg'}
                ${size === 'xl' && 'sm:max-w-xl'}
                ${size === '2xl' && 'sm:max-w-2xl'}
              `}
            >
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">

                  <div className="flex items-center justify-between">
                    <Dialog.Title
                      as="h3"
                      className="ml-3 text-lg leading-6 font-semibold text-gray-900"
                    >
                      {props.title}
                    </Dialog.Title>

                    <div className='mr-2'>
                      <ActionButton onClick={onClose}>
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </ActionButton>
                    </div>
                  </div>

                  <div className="mt-2">{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['md', 'lg', 'xl', '2xl']),
};

Modal.defaultProps = {
  size: 'lg',
  onClose: () => { }
};

export default Modal;