import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useRouteModal = (url, callback) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      if (callback) {
        callback();
      }
      if (url) {
        navigate(url);
      } else {
        navigate(-1);
      }
    }, 300);
  }, [callback, navigate]);

  return { open, closeModal };
};

export default useRouteModal;
