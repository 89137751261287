import { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import DealCard from "./DealCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Deals({ deals }) {
  let [categories, setCategories] = useState({
    "Top Deals": [],
    "All Deals": [],
  });

  useEffect(() => {
    const openDeals = deals?.filter((deal) => deal.status == 2);
    const arrangedDeals = openDeals?.sort((a, b) => +b.value - +a.value);
    const topThreeDeals = arrangedDeals?.slice(0, 3);
    setCategories({
      "Top Deals": topThreeDeals,
      "All Deals": openDeals,
    });
  }, [deals]);

  return (
    <div className="bg-white flex flex-col rounded-lg shadow-md h-[25rem] py-4 overflow-hidden">
      <Tab.Group as={Fragment}>
        <Tab.List className="flex mx-4 justify-around space-x-1 rounded bg-gray-100 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "rounded text-sm font-medium text-gray-700 uppercase w-full py-1",
                  "focus:outline-none",
                  selected
                    ? "bg-white shadow"
                    : "text-gray-500 hover:text-gray-500",
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels as={Fragment}>
          {Object.values(categories).map((deals, idx) => (
            <Tab.Panel
              as={"div"}
              key={idx}
              className={classNames(
                "flex flex-col mt-3 mx-1 space-y-2",
                "focus:outline-none",
                "overflow-auto",
                " flex-1 px-4",
              )}
            >
              {deals?.map((deal) => (
                <DealCard deal={deal} />
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
