import {twMerge} from "tailwind-merge";
import SkeletonLoader from "components/SkeletonLoader";

const SelectInputLoader = ({width, textWidth}) => {
    return (
        <div className={twMerge("flex items-center bg-gray-100 rounded-lg h-[2.4rem] px-3", width)}>
            <div className="flex justify-between items-center w-full">
                <SkeletonLoader className={twMerge("h-1 w-8 bg-gray-200", textWidth)}/>
                <SkeletonLoader className={"p-[0.6rem] bg-gray-200 w-2"}/>
            </div>
        </div>
    )
}

export default SelectInputLoader;