import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { AiOutlineLineChart } from "react-icons/ai";
import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";

import {
  dealsSheetColumns,
  getDealsSheetRows,
} from "pages/Products/exportDeals";
import useExportJSON2Excel from "hooks/useExportJSONAsXLSX";
import { shortenLargeNumber } from "utils";

function formatDateStrForExport(dateStr) {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).\d{3}Z$/;
  const matchResult = dateStr.match(regex);
  const [, year, month, day, hour, minute, second] = matchResult;
  return `${hour}:${minute}:${second} ${day}-${month}-${year}`;
}

const ProductCard = ({ product }) => {
  const exportJSON2Excel = useExportJSON2Excel();

  const handleExport = () => {
    const dealsSheetRows = getDealsSheetRows(product.deals);
    const currentTime = formatDateStrForExport(new Date().toISOString());
    return exportJSON2Excel({
      data: dealsSheetRows,
      dataTitle: product.name + " Deals - " + currentTime,
      sheetName: product.name + " Deals",
      fileName: product.name + " Deals - " + currentTime.replace(/:/g, "-"),
      headers: [dealsSheetColumns],
    });
  };

  return (
    <div className="bg-white rounded-md py-4 hover:shadow-lg hover:scale-105 transition-all">
      <div className="border-b-[1px] border-gray-300 pb-4 mx-6">
        <div className={"flex flex-col space-y-1"}>
          <h3 className={"text-[0.825rem] font-semibold"}>{product.name}</h3>
          <span className={"text-[0.825rem] text-gray-500"}>
            {`Last activity ${product.lastActivity} ago`}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-2 mx-6 py-4 gap-6">
        <CardPoint title={"deals"} value={product.dealsCount} />
        <CardPoint
          title={"worth"}
          value={shortenLargeNumber(product.dealsValue)}
        />
        <CardPoint title={"won"} value={product.wonDealsCount} />
        <CardPoint title={"open"} value={product.openDealsCount} />
      </div>
      <div className={"flex flex-col space-y-2 w-full px-6 pt-2"}>
        <div className={"grid grid-cols-2 gap-2"}>
          <CardButton
            onClick={handleExport}
            text={"export"}
            Icon={ArrowDownTrayIcon}
            buttonStyles={
              "bg-white border-[1.5px] border-primary/60 pl-2 pr-3 col-span-1"
            }
            textStyles={"text-primary/80"}
            IconStyles={"text-primary/80"}
          />
          <CardButton
            link={"deals?productId=" + product.id}
            text={"view"}
            Icon={EyeIcon}
            buttonStyles={
              "bg-white border-[1.5px] border-primary/60 pl-2 pr-3 col-span-1"
            }
            textStyles={"text-primary/80"}
            IconStyles={"text-primary/80"}
          />
        </div>
        <CardButton
          link={"analytics?productId=" + product.id}
          text={"view analytics"}
          Icon={AiOutlineLineChart}
          buttonStyles={"w-full bg-primary/70"}
          textStyles={"text-white"}
          IconStyles={"text-white"}
        />
      </div>
    </div>
  );
};

const CardButton = ({
  link,
  text,
  Icon,
  buttonStyles,
  textStyles,
  IconStyles,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <button
      className={twMerge(
        "flex items-center justify-center w-full space-x-1.5 p-1 rounded bg-gray-100",
        buttonStyles,
      )}
      onClick={
        onClick
          ? onClick
          : () => {
              navigate(link);
              navigate(0);
            }
      }
    >
      {Icon && (
        <Icon
          className={twMerge("h-[1rem] w-[1rem] text-primary-600", IconStyles)}
        />
      )}
      <span
        className={twMerge(
          "text-sm font-weight-[500] capitalize text-primary-800",
          textStyles,
        )}
      >
        {text}
      </span>
    </button>
  );
};

const CardPoint = ({ title, value }) => {
  return (
    <div className="flex flex-col col-span-1 w-full">
      <span className="text-sm font-medium">{value}</span>
      <span className="text-gray-500 text-xs uppercase">{title}</span>
    </div>
  );
};

export default ProductCard;
