import BarChart from "components/BarChart";
import { monthNames as monthsArr } from "constants";
import React, { useState } from "react";

const MonthlyDealsChart = ({
  quarter = 0,
  monthlyDealsCount,
  monthlyDealsValue,
  // monthlyDealsCount = [120, 98, 78, 112, 102, 145, 148, 198, 88, 68, 220, 150],
  // monthlyDealsValue = [
  //   320000, 450000, 334000, 294000, 420000, 580000, 473000, 362000, 650000,
  //   540000, 370000, 150000,
  // ],
}) => {
  const [scope, setScope] = useState(0);

  let months = [];
  switch (quarter) {
    case 1:
      months = monthsArr.slice(0, 3);
      break;
    case 2:
      months = monthsArr.slice(3, 6);
      break;
    case 3:
      months = monthsArr.slice(6, 9);
      break;
    case 4:
      months = monthsArr.slice(9, 12);
      break;
    default:
      months = monthsArr;
  }

  return (
    <div className="h-[26rem] flex-column space-y-2 bg-white rounded-xl overflow-hidden border p-4 mb-12">
      <h1 className="center uppercase font-medium text-sm pb-2 pt-1">
        TIME-BASED DEALS DISTRIBUTION (IN VALUE & VOLUME)
      </h1>
      <div className="h-full flex flex-col space-y-2">
        <div className="h-8 flex items-center justify-center">
          <button
            onClick={() => (scope ? setScope(0) : scope)}
            className={`${
              !scope ? "bg-primary/70" : "bg-[#00000014]"
            } text-xs text-gray-700 py-0.5 px-2 mx-1 uppercase`}
          >
            value
          </button>
          <button
            onClick={() => (scope ? scope : setScope(1))}
            className={`${
              scope ? "bg-primary/70" : "bg-[#00000014]"
            } text-xs text-gray-700 py-0.5 px-2 mx-1 uppercase`}
          >
            volume
          </button>
        </div>
        <div className="h-[calc(100%_-_5rem)] w-full overflow-auto">
          <BarChart
            label={scope ? "Count" : "Amount"}
            xData={months}
            yData={scope ? monthlyDealsCount : monthlyDealsValue}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyDealsChart;
