const HighlightsCardBar = ({ Icon, label, value }) => {
  return (
    <div className="flex items-center space-x-2 bg-primary/10 rounded-md text-[0.8rem] p-2">
      <span className="bg-primary/20 p-1 rounded-full">
        <Icon className="h-4 w-4 text-primary-800" />
      </span>
      <span className="uppercase text-gray-500">{label}:</span>
      <span className="text-gray-800">{value}</span>
    </div>
  );
};

export default HighlightsCardBar;
